import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ReactiveComponentModule } from '@ngrx/component';
import { Angulartics2Module } from 'angulartics2';

import { ComponentsModule } from './components';
import { DirectivesModule } from './directives/directives.module';
import { FormsModule as OmgFormsModule } from './forms/forms.module';
import { PipesModule } from './pipes';
import { windowProviders } from './window/window.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    // Custom Modules go below here
    ComponentsModule,
    OmgFormsModule,
    PipesModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    Angulartics2Module,
    ReactiveComponentModule,
    // Custom Modules go below here
    DirectivesModule,
    ComponentsModule,
    OmgFormsModule,
    PipesModule,
  ],
  providers: [...windowProviders],
})
export class SharedModule {}
