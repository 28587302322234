import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Renewal } from '../../shared/renewals.type';

@Component({
  selector: 'omg-renewal-list-item',
  templateUrl: './renewal-list-item.component.html',
  styleUrls: ['./renewal-list-item.component.scss'],
})
export class RenewalListItemComponent {
  @Input() renewal: Renewal;

  constructor(private router: Router, private route: ActivatedRoute) {}

  routeToDetail() {
    this.router.navigate([`../${this.renewal.id}`], { relativeTo: this.route });
  }
}
