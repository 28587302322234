import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { tap } from 'rxjs/operators';

import { GraphQLService } from '@app/core/graphql/graphql.service';
import { Summary } from '@app/features/summaries/shared/summaries.type';

import { ToastMessageService } from '../../shared/components/toast';
import {
  HelpFlowTypeResponse,
  HelpRequestMutationResponse,
} from './help-request.type';

export interface HelpRequestOptions {
  patientId: number;
  linkedNoteId?: number;
  summary?: Partial<Summary>;
  currentUrl?: string;
}

@Injectable({
  providedIn: 'root',
})
export class HelpRequestService {
  private createHelpRequestMutation = `mutation CreateHelpRequest($helpflowType: String!, $linkedNoteId: Int, $patientId: ID!, $currentUrl: String, $details: String) {
          createHelpRequest(input: {
            helpflowType: $helpflowType,
            linkedNoteId: $linkedNoteId,
            patientId: $patientId,
            actionUrl: $currentUrl,
            details: $details
          }) {
            success
            helpRequest {
              id
              createdAt
              description
              slackChannel
            }
            errors
          }
        }`;

  private getHelpFlowTypesQuery = `query HelpFlowTypes {
        helpflowTypes {
          id
          displayName
        }
      }`;

  constructor(
    private graphQL: GraphQLService,
    private toastService: ToastMessageService,
  ) {}

  getHelpFlowTypes() {
    return this.graphQL.query<HelpFlowTypeResponse>(this.getHelpFlowTypesQuery);
  }

  getHelp(options: HelpRequestOptions, helpflowType: string, details: string) {
    const { currentUrl, linkedNoteId, patientId } = options;

    return this.graphQL
      .query<{
        createHelpRequest: HelpRequestMutationResponse;
      }>(this.createHelpRequestMutation, {
        helpflowType,
        linkedNoteId,
        patientId,
        currentUrl,
        details,
      })
      .pipe(
        tap(helpRequestResponse => {
          this.successToast(
            helpRequestResponse.createHelpRequest.helpRequest.slackChannel,
          );
        }),
      );
  }

  private successToast(channelName) {
    this.toastService.add({
      severity: 'success',
      summary: 'Your request has been assigned',
      detail: `View your request in the <b>${channelName}</b> channel.`,
    });
  }
}
