import { ComponentType, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injector, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

import { PopoverConfig } from './popover-config';

export interface PopoverCloseEvent<T = any> {
  type: 'backdropClick' | 'close';
  data: T;
}

export class PopoverRef<T = any> {
  private _isOpen = false;
  private afterClosedSubject = new Subject<PopoverCloseEvent<T>>();
  private unsubscribe = new Subject();
  private scrollHandler: any;
  private clickAwayHandler: any;

  hoveredOver: boolean;
  afterClosed = this.afterClosedSubject.asObservable();

  constructor(
    public overlay: OverlayRef,
    public config: PopoverConfig,
    private zone: NgZone,
  ) {}

  get isOpen() {
    return this._isOpen;
  }

  dispose() {
    this._isOpen = false;
    window.removeEventListener('scroll', this.scrollHandler, true);
    document.removeEventListener('click', this.clickAwayHandler, true);
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.overlay.dispose();
  }

  close(data?: T) {
    this.closeOverlay('close', data);
  }

  updatePosition() {
    this.overlay.updatePosition();
  }

  attach(component: ComponentType<{}>, injector: Injector) {
    this.overlay.attach(new ComponentPortal(component, null, injector));
    this.onAttached();
  }

  private onAttached() {
    this._isOpen = true;
    this.setupListeners();
  }

  private setupListeners() {
    // Close the overlay when backdrop is clicked or handle when no backdrop
    if (this.overlay.getConfig().hasBackdrop) {
      this.overlay.backdropClick().subscribe(() => {
        this.closeOverlay('backdropClick', null);
      });
    } else {
      this.clickAwayHandler = this.handleClickAway.bind(this);
      this.zone.runOutsideAngular(() =>
        document.addEventListener('click', this.clickAwayHandler, true),
      );
    }

    // Listen for all clicks on the document
    this.scrollHandler = this.handleScroll.bind(this);
    this.zone.runOutsideAngular(() =>
      window.addEventListener('scroll', this.scrollHandler, true),
    );
  }

  private handleScroll() {
    this.updatePosition();
  }

  private handleClickAway(event: MouseEvent) {
    const element = event.target as HTMLElement;
    if (element && !element.closest(`#${this.overlay.overlayElement.id}`)) {
      this.closeOverlay('backdropClick', null);
    }
  }

  private closeOverlay(type: PopoverCloseEvent['type'], data?: T) {
    this.afterClosedSubject.next({ type, data });
    this.afterClosedSubject.complete();
    this.dispose();
  }
}
