<div om-layout="vertical"
     om-layout-gutter-large>
  <div class="picker">
    <omg-button-group *ngIf="options"
                      [options]="options"
                      [buttonGroupControl]="quickDateSelector">
    </omg-button-group>
  </div>

  <div om-layout
       om-layout-align="start center"
       om-layout-gutter-large>
    <omg-date-picker [formControl]="manualDateSelector">
    </omg-date-picker>
    <span *ngIf="helperText"
          class="caption emphasized">
      {{helperText}}
    </span>
  </div>
</div>
<span class="om-messages"
      *ngIf="handleErrors && date.errors?.pastDate">Date must be a future date</span>
<span class="om-messages"
      *ngIf="handleErrors && date.invalid && date.touched && !date.errors?.pastDate">Cannot be blank</span>
