import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { FormModel } from '@app/shared';

import { CommentsService } from '../../shared/comments.service';
import { Comment, Commentable } from '../../shared/comments.type';

@Component({
  selector: 'omg-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent implements OnInit {
  @Input() commentable: Commentable;
  @Output() commentAdded = new EventEmitter<void>();
  @Output() commentRemoved = new EventEmitter<void>();

  get maxCommentLength() {
    return 500;
  }

  snippetsFeatureEnabled$ = this.featureFlagSelectors.featureEnabled(
    FeatureFlagNames.newSnippets,
  );
  comments: Observable<Comment[]>;
  commentFormModel: FormModel;

  constructor(
    private commentsService: CommentsService,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  ngOnInit() {
    this.setupForm();

    this.comments = this.commentsService.get(this.commentable);
  }

  private setupForm() {
    this.commentFormModel = new FormModel(
      new FormGroup({
        commentBody: new FormControl(''),
      }),
      {
        autosave: false,
        saveFunction: (formValues: { commentBody: string }) => {
          const comment = formValues.commentBody.trim();

          if (comment.length <= 0) {
            return of(false);
          }

          return this.saveComment(comment);
        },
        resetOnSave: true,
      },
    );
  }

  onSaveComment() {
    const comment = this.commentFormModel.get('commentBody').value;
    const trimmedComment = comment.trim();
    this.commentFormModel.setValue({ commentBody: trimmedComment });

    if (trimmedComment && trimmedComment.length > 0) {
      this.commentFormModel.save();
    }
  }

  onDeleteComment(commentId: number) {
    this.deleteComment(commentId);
  }

  private saveComment(comment: string): Observable<boolean> {
    this.commentFormModel.form.get('commentBody').disable();

    return this.commentsService.save(this.commentable, comment).pipe(
      take(1),
      tap(() => {
        this.commentFormModel.get('commentBody').enable();
        this.commentAdded.emit();
      }),
    );
  }

  private deleteComment(commentId: number) {
    this.commentsService
      .delete(this.commentable, commentId)
      .pipe(
        take(1),
        tap(() => {
          this.commentRemoved.emit();
        }),
      )
      .subscribe();
  }
}
