<omg-action-bar #actionBar>
  <ng-container left-buttons>
    <div om-layout-gutter>
      <button omgButton
              type="button"
              variant="secondary"
              (click)="approve()"
              [disabled]="disabledApproval"
              data-cy="renewals-approve-action-btn">
        Approve
      </button>
      <button omgButton
              type="button"
              variant="secondary"
              (click)="showConfirmDrawer()"
              [disabled]="disabledDenial"
              data-cy="renewals-deny-action-btn">
        Deny
      </button>
    </div>
  </ng-container>

  <ng-container right-buttons>
    <button omgButton
            class="padding-normal-horizontal"
            variant="link"
            routerLink=".."
            data-cy="renewals-back-action-btn">
      Back To List
    </button>
    <button omgButton
            class="padding-normal-horizontal"
            variant="link"
            *ngIf="showNextRequest"
            [routerLink]="renewalRouterLink"
            data-cy="renewals-next-action-btn">
      Next Request
    </button>
  </ng-container>

  <omg-renewal-item-deny-drawer om-layout-fill
                                (cancel)="cancel()"
                                (deny)="deny($event)"></omg-renewal-item-deny-drawer>
</omg-action-bar>
