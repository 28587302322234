import { LMSParams, LMSParamsMap, XYCoordinate } from '../components/infant-growth-measurements-form/lms-params.type';

const zScoreToPercentileTable = {
  z: [0.09, 0.08, 0.07, 0.06, 0.05, 0.04, 0.03, 0.02, 0.01, 0],
  '-3.4': [ 0.0002, 0.0003, 0.0003, 0.0003, 0.0003, 0.0003, 0.0003, 0.0003, 0.0003, 0.0003 ],
  '-3.3': [ 0.0003, 0.0004, 0.0004, 0.0004, 0.0004, 0.0004, 0.0004, 0.0005, 0.0005, 0.0005 ],
  '-3.2': [ 0.0005, 0.0005, 0.0005, 0.0006, 0.0006, 0.0006, 0.0006, 0.0006, 0.0007, 0.0007 ],
  '-3.1': [ 0.0007, 0.0007, 0.0008, 0.0008, 0.0008, 0.0008, 0.0009, 0.0009, 0.0009, 0.001 ],
  '-3.0': [ 0.001, 0.001, 0.0011, 0.0011, 0.0011, 0.0012, 0.0012, 0.0013, 0.0013, 0.0013 ],
  '-2.9': [ 0.0014, 0.0014, 0.0015, 0.0015, 0.0016, 0.0016, 0.0017, 0.0018, 0.0018, 0.0019 ],
  '-2.8': [ 0.0019, 0.002, 0.0021, 0.0021, 0.0022, 0.0023, 0.0023, 0.0024, 0.0025, 0.0026 ],
  '-2.7': [ 0.0026, 0.0027, 0.0028, 0.0029, 0.003, 0.0031, 0.0032, 0.0033, 0.0034, 0.0035 ],
  '-2.6': [ 0.0036, 0.0037, 0.0038, 0.0039, 0.004, 0.0041, 0.0043, 0.0044, 0.0045, 0.0047 ],
  '-2.5': [ 0.0048, 0.0049, 0.0051, 0.0052, 0.0054, 0.0055, 0.0057, 0.0059, 0.006, 0.0062 ],
  '-2.4': [ 0.0064, 0.0066, 0.0068, 0.0069, 0.0071, 0.0073, 0.0075, 0.0078, 0.008, 0.0082 ],
  '-2.3': [ 0.0084, 0.0087, 0.0089, 0.0091, 0.0094, 0.0096, 0.0099, 0.0102, 0.0104, 0.0107 ],
  '-2.2': [ 0.011, 0.0113, 0.0116, 0.0119, 0.0122, 0.0125, 0.0129, 0.0132, 0.0136, 0.0139 ],
  '-2.1': [ 0.0143, 0.0146, 0.015, 0.0154, 0.0158, 0.0162, 0.0166, 0.017, 0.0174, 0.0179 ],
  '-2.0': [ 0.0183, 0.0188, 0.0192, 0.0197, 0.0202, 0.0207, 0.0212, 0.0217, 0.0222, 0.0228 ],
  '-1.9': [ 0.0233, 0.0239, 0.0244, 0.025, 0.0256, 0.0262, 0.0268, 0.0274, 0.0281, 0.0287 ],
  '-1.8': [ 0.0294, 0.0301, 0.0307, 0.0314, 0.0322, 0.0329, 0.0336, 0.0344, 0.0351, 0.0359 ],
  '-1.7': [ 0.0367, 0.0375, 0.0384, 0.0392, 0.0401, 0.0409, 0.0418, 0.0427, 0.0436, 0.0446 ],
  '-1.6': [ 0.0455, 0.0465, 0.0475, 0.0485, 0.0495, 0.0505, 0.0516, 0.0526, 0.0537, 0.0548 ],
  '-1.5': [ 0.0559, 0.0571, 0.0582, 0.0594, 0.0606, 0.0618, 0.063, 0.0643, 0.0655, 0.0668 ],
  '-1.4': [ 0.0681, 0.0694, 0.0708, 0.0721, 0.0735, 0.0749, 0.0764, 0.0778, 0.0793, 0.0808 ],
  '-1.3': [ 0.0823, 0.0838, 0.0853, 0.0869, 0.0885, 0.0901, 0.0918, 0.0934, 0.0951, 0.0968 ],
  '-1.2': [ 0.0985, 0.1003, 0.102, 0.1038, 0.1056, 0.1075, 0.1093, 0.1112, 0.1131, 0.1151 ],
  '-1.1': [ 0.117, 0.119, 0.121, 0.123, 0.1251, 0.1271, 0.1292, 0.1314, 0.1335, 0.1357 ],
  '-1.0': [ 0.1379, 0.1401, 0.1423, 0.1446, 0.1469, 0.1492, 0.1515, 0.1539, 0.1562, 0.1587 ],
  '-0.9': [ 0.1611, 0.1635, 0.166, 0.1685, 0.1711, 0.1736, 0.1762, 0.1788, 0.1814, 0.1841 ],
  '-0.8': [ 0.1867, 0.1894, 0.1922, 0.1949, 0.1977, 0.2005, 0.2033, 0.2061, 0.209, 0.2119 ],
  '-0.7': [ 0.2148, 0.2177, 0.2206, 0.2236, 0.2266, 0.2296, 0.2327, 0.2358, 0.2389, 0.242 ],
  '-0.6': [ 0.2451, 0.2483, 0.2514, 0.2546, 0.2578, 0.2611, 0.2643, 0.2676, 0.2709, 0.2743 ],
  '-0.5': [ 0.2776, 0.281, 0.2843, 0.2877, 0.2912, 0.2946, 0.2981, 0.3015, 0.305, 0.3085 ],
  '-0.4': [ 0.3121, 0.3156, 0.3192, 0.3228, 0.3264, 0.33, 0.3336, 0.3372, 0.3409, 0.3446 ],
  '-0.3': [ 0.3483, 0.352, 0.3557, 0.3594, 0.3632, 0.3669, 0.3707, 0.3745, 0.3783, 0.3821 ],
  '-0.2': [ 0.3829, 0.3897, 0.3936, 0.3974, 0.4013, 0.4052, 0.409, 0.4129, 0.4168, 0.4207 ],
  '-0.1': [ 0.4247, 0.4286, 0.4325, 0.4364, 0.4404, 0.4443, 0.4483, 0.4522, 0.4562, 0.4602 ],
  '0.0': [ 0.4641, 0.4681, 0.4721, 0.4761, 0.4801, 0.484, 0.488, 0.492, 0.496, 0.5 ],
};

export const calculateZScore = (lmsParams: LMSParams, sample: number): number => {
  const zNumerator = (sample / lmsParams.mu) ** lmsParams.lambda - 1;
  const zDenominator = lmsParams.lambda * lmsParams.sigma;
  return zNumerator / zDenominator;
};

export const convertZScoreToPercentile = (zScore: number): number => {
  let zScoreScaleTwo: number;
  let zScoreScaleOne = -3.4;

  if (isNaN(zScore) || zScore === Infinity) {
    return 0;
  }

  if (zScore === 0) {
    return 50;
  }

  if (zScore > 0) {
    if (zScore > 3.49) {
      return 100;
    }

    zScoreScaleTwo = Math.floor(zScore * 100) / 100;
    zScoreScaleOne = Math.floor(zScoreScaleTwo * 10) / 10;
    zScoreScaleOne = -zScoreScaleOne;
  } else {
    if (zScore < -3.49) {
      return 0;
    }

    zScoreScaleTwo = Math.ceil(zScore * 100) / 100;
    zScoreScaleOne = Math.ceil(zScoreScaleTwo * 10) / 10;
  }

  if (zScoreScaleOne === -0) {
    zScoreScaleOne = 0;
  }

  let zHundredths = Math.abs(
    Math.round((zScoreScaleTwo % zScoreScaleOne) * 10000) / 10000,
  );

  // zHundredths can be NaN when the tenths position is 0, which causes
  // zScoreScaleTwo % zScoreScaleOne to be NaN
  if (isNaN(zHundredths)) {
    zHundredths = Math.abs(zScoreScaleTwo);
  }

  const rowIndex = zScoreScaleOne.toFixed(1);
  const colIndex = zScoreToPercentileTable.z.indexOf(zHundredths);
  let percentile = zScoreToPercentileTable[rowIndex][colIndex];

  if (zScore > 0) {
    percentile = Math.round((1 - percentile) * 10000) / 10000;
  }

  // Show as a percent rather than decimal
  return Math.round(percentile * 100);
};

function linearInterpolation (x, point1: XYCoordinate, point2: XYCoordinate): number {
  const a = (point2.y - point1.y) / (point2.x - point1.x);
  const b = -a * point1.x + point1.y;
  return a * x + b
}

function interpolatedLMSParams(ageInMonths: number, data): LMSParams {
  const nextMonth = Math.ceil(ageInMonths);
  const lastMonth = Math.floor(ageInMonths);
  const nextMonthParams = data[nextMonth];
  const lastMonthParams = data[lastMonth];
  const mu = linearInterpolation(ageInMonths, { x: lastMonth, y: lastMonthParams.mu }, { x: nextMonth, y: nextMonthParams.mu });
  const sigma = linearInterpolation(ageInMonths, { x: lastMonth, y: lastMonthParams.sigma }, { x: nextMonth, y:  nextMonthParams.sigma });
  const lambda = linearInterpolation(ageInMonths, { x: lastMonth, y: lastMonthParams.lambda }, { x: nextMonth, y: nextMonthParams.lambda });

  return { lambda, mu, sigma };
}

function getLMSParams(dataTable: LMSParamsMap, ageInMonths: number): LMSParams {
  if (ageInMonths in dataTable) {
    return dataTable[ageInMonths];
  }
  return interpolatedLMSParams(ageInMonths, dataTable);
}

export const zScoreFromMeasurement = (measurement: number, ageInMonths: number, dataTable: LMSParamsMap) => {
  const params: LMSParams = getLMSParams(dataTable, ageInMonths);
  const zScore = calculateZScore(params, measurement);

  if (isNaN(zScore)) {
    return 0;
  }
  return convertZScoreToPercentile(zScore);
}
