<div class="-immediate-errors"
     om-layout
     om-layout-gutter-large
     [formGroup]="form">
  <label class="-stacked"
         om-flex="15">
    wt
    <label class="plain-text"
           om-layout
           om-layout-align="center center"
           om-layout-gutter-mini>
      <input omgInputNumber
             type="number"
             step="0.1"
             min="2"
             max="999"
             name="wt"
             omgSyncInput
             [control]="form.get('wt')"
             om-layout-fill>
      <span>lb</span>
    </label>
  </label>
  <span class="padding-half-vertical"
        om-flex="15"
        om-layout
        om-layout-align="start end">
    <div *ngIf="(showPercentiles$ | ngrxPush) && !!ordinalPercentiles.wtPct"
         om-layout
         om-layout-gutter-large>
      <span om-flex="15">| </span>
      <span>{{ ordinalPercentiles.wtPct }}</span>
    </div>
  </span>
  <label class="-stacked"
         om-flex="15">
    ht
    <label class="plain-text"
           om-layout
           om-layout-align="center center"
           om-layout-gutter-mini>
      <input omgInputNumber
             type="number"
             min="14"
             max="108"
             step="0.1"
             name="ht"
             omgSyncInput
             [control]="form.get('ht')"
             om-layout-fill>
      <span>in</span>
    </label>
  </label>
  <span class="padding-half-vertical"
        om-flex="15"
        om-layout
        om-layout-align="start end">
    <div *ngIf="(showPercentiles$ | ngrxPush) && !!ordinalPercentiles.htPct"
         om-layout
         om-layout-gutter-large>
      <span om-flex="15">| </span>
      <span>{{ ordinalPercentiles.htPct }}</span>
    </div>
  </span>
  <label class="-stacked"
         om-flex="15">
    BMI
    <input class="om-input -readonly"
           type="number"
           name="BMI"
           [value]="form.get('BMI').value"
           omgSyncInput
           [control]="form.get('BMI')"
           readonly>
  </label>
  <span class="padding-half-vertical"
        om-flex="15"
        om-layout
        om-layout-align="start end">
    <div *ngIf="(showPercentiles$ | ngrxPush) && !!ordinalPercentiles.bmiPct"
         om-layout
         om-layout-gutter-large>
      <span om-flex="15">| </span>
      <span>{{ ordinalPercentiles.bmiPct }}</span>
    </div>
  </span>
</div>
<div *ngIf="form.get('BMI').invalid"
     class="om-messages">
  BMI must be in range 0-99
</div>
