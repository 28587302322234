<omg-card class="workspace-card"
          [loading]="!(letter$ | ngrxPush)">
  <ng-template #loadedRef>
    <div *ngIf="letter$ | ngrxPush as letter">
      <omg-card-title>
        <div omgCollapse
             #noteCollapse="omgCollapseProvider">
          <omg-note-header [note]="letter"
                           [todo]="todo$ | ngrxPush"
                           (close)="closeLetter()"
                           [editable]="true"
                           [form]="letterFormModel.form"
                           [commentsCollapseRef]="noteCollapse">
          </omg-note-header>
        </div>
      </omg-card-title>
      <omg-card-body>
        <omg-letter [letter$]="letter$"
                    [todo$]="todo$"
                    [letterHasIncompleteTodo]="todoIncomplete$ | ngrxPush"
                    [letterFormModel]="letterFormModel"
                    [userInfo]="userInfo$ | ngrxPush"
                    [commentsCollapseRef]="noteCollapse"
                    [loadingPDF]="loadingPDF$ | ngrxPush"></omg-letter>
      </omg-card-body>
      <omg-card-footer>
        <omg-letter-action-bar [loadingPDF]="loadingPDF$ | ngrxPush">
        </omg-letter-action-bar>
      </omg-card-footer>
    </div>
  </ng-template>
  <ng-template #loadingRef>
    <omg-card-title heading="Letter">
      <div class="om-tab-subheading"
           om-layout
           om-flex
           om-layout-align="end center">
        <span class="clickable om-icon icon-close"
              om-flex="5"
              (click)="closeLetter()"
              data-cy="close-loading-letter"></span>
      </div>
    </omg-card-title>
    <omg-feature-loading-spinner></omg-feature-loading-spinner>
  </ng-template>
</omg-card>
