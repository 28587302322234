import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'omg-free-text-regimen-editor',
  templateUrl: './free-text-regimen-editor.component.html',
})
export class FreeTextRegimenEditorComponent implements OnInit {
  @Output() toggle = new EventEmitter<void>();
  @Output() changeEvent = new EventEmitter<any>();
  @Output() validate = new EventEmitter<boolean>();
  @Input() instructionsText: FormControl;
  @Input() maxLength: number;
  @ViewChild('freeTextInstructions', { static: true })
  freeTextInstructionsTextArea: ElementRef;

  constructor() {}

  ngOnInit() {
    this.focusOnFreeTextArea();
    this.validate.emit(true);
  }

  onUseStructuredDataClick() {
    this.toggle.emit();
    this.changeEvent.emit({ type: 'useStructuredDataClick' });
  }

  onInstructionsTextChange(event) {
    this.changeEvent.emit({ event: event, type: 'instructionsTextChange' });
  }

  private focusOnFreeTextArea() {
    this.freeTextInstructionsTextArea.nativeElement.focus();
  }
}
