import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';

import { PatientUpdateData } from '../shared/patient-update.type';
import { Patient } from '../shared/patient.type';
import { PatientState } from './patient.reducer';

export enum PatientActionTypes {
  GetPatient = '[Patient] Get Patient',
  GetPatientSuccess = '[Patient] Get Patient Success',
  GetPatientError = '[Patient] Get Patient Error',
  UpdatePatient = '[Patient] Update Patient',
  UpdatePatientSuccess = '[Patient] Update Patient Success',
  UpdatePatientError = '[Patient] Update Patient Error',
  ResetPatient = '[Patient] Reset Patient',
  QueryPatient = '[Patient] Query Patient',
  QueryPatientSuccess = '[Patient] Query Patient Success',
  QueryPatientError = '[Patient] Query Patient Error',
}
interface GetPatientPayload {
  id: number;
  forceGet?: boolean;
}
interface UpdatePatientPayload {
  id: number;
  data: PatientUpdateData;
}

type PatientErrorPayload = any;

export const GetPatient = createAction(
  PatientActionTypes.GetPatient,
  props<{ payload: GetPatientPayload }>(),
);
export const GetPatientSuccess = createAction(
  PatientActionTypes.GetPatientSuccess,
  (payload: Patient) => ({ payload, error: false }),
);
export const GetPatientError = createAction(
  PatientActionTypes.GetPatientError,
  (payload: PatientErrorPayload) => ({ payload, error: true }),
);

export const UpdatePatient = createAction(
  PatientActionTypes.UpdatePatient,
  props<{ payload: UpdatePatientPayload }>(),
);
export const UpdatePatientSuccess = createAction(
  PatientActionTypes.UpdatePatientSuccess,
  (payload: Patient) => ({ payload, error: false }),
);
export const UpdatePatientError = createAction(
  PatientActionTypes.UpdatePatientError,
  (payload: PatientErrorPayload) => ({ payload, error: true }),
);

export const ResetPatient = createAction(PatientActionTypes.ResetPatient);

export const QueryPatient = createAction(
  PatientActionTypes.QueryPatient,
  props<{ payload: GetPatientPayload }>(),
);
export const QueryPatientSuccess = createAction(
  PatientActionTypes.QueryPatientSuccess,
  (payload: Patient) => ({ payload, error: false }),
);
export const QueryPatientError = createAction(
  PatientActionTypes.QueryPatientError,
  (payload: PatientErrorPayload) => ({ payload, error: true }),
);

@Injectable()
export class PatientActions {
  constructor(private store: Store<PatientState>) {}

  getPatient(id: number, forceGet?: boolean) {
    const payload = { id, forceGet };
    this.store.dispatch(GetPatient({ payload }));
    this.store.dispatch(QueryPatient({ payload }));
  }

  updatePatient(id: number, data: PatientUpdateData) {
    const payload = { id, data };
    this.store.dispatch(UpdatePatient({ payload }));
  }

  resetPatient() {
    this.store.dispatch(ResetPatient());
  }
}
