<div om-layout
     om-layout-align="space-between center"
     class="inline-banner"
     [ngClass]="variant && 'inline-banner-bg-' + variant + ' ' + additionalClasses">
  <div om-layout
       om-layout-align="space-between center">
    <i class="om-icon"
       [ngClass]="primaryIconName"
       *ngIf="primaryIconName"
       data-test="primary-icon"></i>
    <span class="padding-normal-horizontal"
          data-test="message">
      <ng-content></ng-content>
    </span>
    <ng-container *ngIf="infoPopoverContent">
      <button [omgPopoverTriggerFor]="infoContent"
              omgPopoverTriggerOn="hover"
              data-test="info-button">
        <i class="om-icon icon-alert-info"></i>
      </button>
      <omg-popover #infoContent
                   placement="top">
        <div class="padding-normal inline-banner-popover-content">
          <ng-template class="inline-banner-popover-content"
                       [ngTemplateOutlet]="infoPopoverContent.templateRef"></ng-template>
        </div>
      </omg-popover>
    </ng-container>
  </div>
  <ng-container *ngIf="actionContent">
    <ng-template [ngTemplateOutlet]="actionContent.templateRef"></ng-template>
  </ng-container>
</div>
