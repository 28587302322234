import { zScoreFromMeasurement } from '../../../shared/z-score-to-percentile';
import { LMSParamsMap } from '../lms-params.type';

// Set up arrays for length, weight, and head circ LMS params
const maleWeightLMSParams: LMSParamsMap = {
  0: { lambda: 0.3487, mu: 3.3464, sigma: 0.14602 },
  1: { lambda: 0.2297, mu: 4.4709, sigma: 0.13395 },
  2: { lambda: 0.197, mu: 5.5675, sigma: 0.12385 },
  3: { lambda: 0.1738, mu: 6.3762, sigma: 0.11727 },
  4: { lambda: 0.1553, mu: 7.0023, sigma: 0.11316 },
  5: { lambda: 0.1395, mu: 7.5105, sigma: 0.1108 },
  6: { lambda: 0.1257, mu: 7.934, sigma: 0.10958 },
  7: { lambda: 0.1134, mu: 8.297, sigma: 0.10902 },
  8: { lambda: 0.1021, mu: 8.6151, sigma: 0.10882 },
  9: { lambda: 0.0917, mu: 8.9014, sigma: 0.10881 },
  10: { lambda: 0.082, mu: 9.1649, sigma: 0.10891 },
  11: { lambda: 0.073, mu: 9.4122, sigma: 0.10906 },
  12: { lambda: 0.0644, mu: 9.6479, sigma: 0.10925 },
  13: { lambda: 0.0563, mu: 9.8749, sigma: 0.10949 },
  14: { lambda: 0.0487, mu: 10.0953, sigma: 0.10976 },
  15: { lambda: 0.0413, mu: 10.3108, sigma: 0.11007 },
  16: { lambda: 0.0343, mu: 10.5228, sigma: 0.11041 },
  17: { lambda: 0.0275, mu: 10.7319, sigma: 0.11079 },
  18: { lambda: 0.0211, mu: 10.9385, sigma: 0.11119 },
  19: { lambda: 0.0148, mu: 11.143, sigma: 0.11164 },
  20: { lambda: 0.0087, mu: 11.3462, sigma: 0.11211 },
  21: { lambda: 0.0029, mu: 11.5486, sigma: 0.11261 },
  22: { lambda: -0.0028, mu: 11.7504, sigma: 0.11314 },
  23: { lambda: -0.0083, mu: 11.9514, sigma: 0.11369 },
  24: { lambda: -0.0137, mu: 12.1515, sigma: 0.11426 },
};

const femaleWeightLMSParams: LMSParamsMap = {
  0: { lambda: 0.3809, mu: 3.2322, sigma: 0.14171 },
  1: { lambda: 0.1714, mu: 4.1873, sigma: 0.13724 },
  2: { lambda: 0.0962, mu: 5.1282, sigma: 0.13 },
  3: { lambda: 0.0402, mu: 5.8458, sigma: 0.12619 },
  4: { lambda: -0.005, mu: 6.4237, sigma: 0.12402 },
  5: { lambda: -0.043, mu: 6.8985, sigma: 0.12274 },
  6: { lambda: -0.0756, mu: 7.297, sigma: 0.12204 },
  7: { lambda: -0.1039, mu: 7.6422, sigma: 0.12178 },
  8: { lambda: -0.1288, mu: 7.9487, sigma: 0.12181 },
  9: { lambda: -0.1507, mu: 8.2254, sigma: 0.12199 },
  10: { lambda: -0.17, mu: 8.48, sigma: 0.12223 },
  11: { lambda: -0.1872, mu: 8.7192, sigma: 0.12247 },
  12: { lambda: -0.2024, mu: 8.9481, sigma: 0.12268 },
  13: { lambda: -0.2158, mu: 9.1699, sigma: 0.12283 },
  14: { lambda: -0.2278, mu: 9.387, sigma: 0.12294 },
  15: { lambda: -0.2384, mu: 9.6008, sigma: 0.12299 },
  16: { lambda: -0.2478, mu: 9.8124, sigma: 0.12303 },
  17: { lambda: -0.2562, mu: 10.0226, sigma: 0.12306 },
  18: { lambda: -0.2637, mu: 10.2315, sigma: 0.12309 },
  19: { lambda: -0.2703, mu: 10.4393, sigma: 0.12315 },
  20: { lambda: -0.2762, mu: 10.6464, sigma: 0.12323 },
  21: { lambda: -0.2815, mu: 10.8534, sigma: 0.12335 },
  22: { lambda: -0.2862, mu: 11.0608, sigma: 0.1235 },
  23: { lambda: -0.2903, mu: 11.2688, sigma: 0.12369 },
  24: { lambda: -0.2941, mu: 11.4775, sigma: 0.1239 },
};

export const getInfWtPercentile = (
  gender: string,
  ageInMonths: number,
  weight: number,
) => {
  const weightLMSDataTable =
    gender === 'Female' ? femaleWeightLMSParams : maleWeightLMSParams;

  return zScoreFromMeasurement(weight, ageInMonths, weightLMSDataTable);
};
