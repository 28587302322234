import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { get } from 'lodash/fp';

import { FormModel, FormModelOptions } from './form-model';

@Directive({
  exportAs: 'omgForm',
  selector: '[omgForm]',
})
export class FormDirective implements OnInit, OnChanges, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input('omgForm') model: FormModel;
  @Input() autosave: boolean;
  @Input() autosaveDelay: number;
  @Input() resetOnSave: boolean;

  @HostBinding('class.om-form') _formClass = true;

  constructor() {}

  ngOnInit() {
    if (this.model) {
      this.model.init();
    }
  }

  ngOnDestroy() {
    if (this.model) {
      this.model.dispose();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const getCurrentValue = obj => get('currentValue', obj);

    // dipose the old form model
    const newModel = getCurrentValue(changes.model);
    if (newModel && this.model) {
      this.model.dispose();
    }

    const options: FormModelOptions = {
      autosave: getCurrentValue(changes.autosave),
      autosaveDelay: getCurrentValue(changes.autosaveDelay),
      resetOnSave: getCurrentValue(changes.resetOnSave),
    };

    // reconfigure the model
    if (this.model) {
      this.model.configure(options);
      this.model.init();
    }
  }
}
