import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, shareReplay, takeUntil, tap } from 'rxjs/operators';

import { AnalyticsActions } from '@app/core';
import { AnalyticsEvent } from '@app/core/analytics/shared/analytics.type';
import { ProcedureOrder } from '@app/features/procedure-order/shared/procedure-order.type';
import { ProcedureOrderActions } from '@app/features/procedure-order/store/procedure-order.actions';
import { ProcedureOrderSelectors } from '@app/features/procedure-order/store/procedure-order.selectors';

import { ProcedureSuggestion } from '../../shared/procedure-suggestion.type';
import { buildMlProcedureBillingSuggestionEventPayload } from '../../shared/procedure-suggestion.utils';
import { ProcedureSuggestionActions } from '../../store/procedure-suggestion.actions';
import { ProcedureSuggestionProcedureOrderForm } from './procedure-suggestion-procedure-order-form';

@Component({
  selector: 'omg-procedure-suggestion-procedure-order-form',
  templateUrl: './procedure-suggestion-procedure-order-form.component.html',
  styleUrls: ['./procedure-suggestion-procedure-order-form.component.scss'],
})
export class ProcedureSuggestionProcedureOrderFormComponent implements OnInit {
  @Input() procedureOrderId: number;
  @Input() procedureSuggestion: ProcedureSuggestion;
  @Input() patientId: number;

  @Output() dismiss = new EventEmitter();

  form: ProcedureSuggestionProcedureOrderForm;
  procedureOrder$: Observable<ProcedureOrder>;
  procedureOrder: ProcedureOrder;
  private unsubscribe$ = new Subject();

  constructor(
    private procedureOrderActions: ProcedureOrderActions,
    private procedureSuggestionActions: ProcedureSuggestionActions,
    private procedureOrderSelectors: ProcedureOrderSelectors,
    public analyticsActions: AnalyticsActions,
  ) {
    this.form = new ProcedureSuggestionProcedureOrderForm(
      this.procedureOrderActions,
      this.procedureOrderSelectors,
    );
  }

  ngOnInit() {
    this.setUpProcedureOrder();
  }

  private setUpProcedureOrder() {
    this.procedureOrderActions.getById({ id: this.procedureOrderId });
    this.procedureOrder$ = this.procedureOrderSelectors
      .getById(this.procedureOrderId)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(procedureOrder => !!procedureOrder),
        tap(procedureOrder => this.buildForm(procedureOrder)),
        shareReplay(1),
      );
  }

  private buildForm(procedureOrder: ProcedureOrder) {
    this.form.setProcedureOrder(procedureOrder);
  }

  deleteProcedureOrder() {
    this.procedureSuggestionActions.deleteProcedureOrder(
      this.procedureOrderId,
      this.procedureSuggestion.summaryId,
    );
    this.trackDiscardAnalytics();
  }

  saveProcedureOrder() {
    this.procedureSuggestionActions.saveProcedureOrder({
      id: this.procedureOrderId,
      changes: { status: 'sent' },
      patientId: this.patientId,
      summaryId: this.procedureSuggestion.summaryId,
    });
    this.trackSaveAnalytics();
  }

  onDismiss() {
    this.dismiss.emit();
  }

  private trackSaveAnalytics() {
    this.analyticsActions.trackEvent(
      AnalyticsEvent.MlProcedureBillingSuggestionSaved,
      buildMlProcedureBillingSuggestionEventPayload(
        this.procedureSuggestion,
        this.form.controls.get('results').value,
      ),
    );
  }

  private trackDiscardAnalytics() {
    this.analyticsActions.trackEvent(
      AnalyticsEvent.MlProcedureBillingSuggestionChangesDiscarded,
      buildMlProcedureBillingSuggestionEventPayload(this.procedureSuggestion),
    );
  }
}
