import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { MeasurementValidationRules } from '@app/features/summaries/shared/measurement-validation-rules.type';
import { ReferenceDataKeys } from '@app/modules/reference-data/shared/reference-data.type';
import { ReferenceDataSelectors } from '@app/modules/reference-data/store/reference-data.selectors';

@Component({
  selector: 'omg-vitals-measurements-form',
  templateUrl: './vitals-measurements-form.component.html',
  styleUrls: ['./vitals-measurements-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VitalsMeasurementsFormComponent implements OnInit {
  @Input() form: FormGroup;

  measurementValidationRules$: Observable<MeasurementValidationRules>;

  constructor(private referenceDataSelectors: ReferenceDataSelectors) {}

  ngOnInit(): void {
    this.measurementValidationRules$ = this.referenceDataSelectors.get<
      MeasurementValidationRules
    >(ReferenceDataKeys.measurementValidationRules);
  }
}
