import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { SummaryHealthBackground } from './../../shared/summaries.type';

@Component({
  selector: 'omg-linked-health-background',
  templateUrl: './linked-health-background.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedHealthBackgroundComponent {
  @Input() signed = false;
  @Input() healthBackground: SummaryHealthBackground;
  @Output() unlink = new EventEmitter();

  deleteFromNote() {
    this.unlink.emit();
  }
}
