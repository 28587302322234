<div class="action-bar -with-scrollable-sibling"
     om-layout
     om-layout-align="space-between center"
     [formGroup]="formGroup">
  <div om-flex>
    <omg-auto-complete #autoComplete
                       placeholder="Select a document type"
                       [items]="templateItems | ngrxPush"
                       trackByKey="id"
                       omgStopEvent="click"
                       bindLabel="description"
                       formControlName="documentCategory"
                       (search)="onSearchForTemplate($event)"
                       (change)="onSetTemplate($event)"
                       [searchFn]="templateSearchFn"
                       [omgFocusOn]="recategorizeSearchFocusOnKey"
                       dropdownPosition="top"
                       (focusOnRequest)="autoComplete.focus()">
    </omg-auto-complete>
  </div>
  <span om-layout
        om-layout-gutter>
    <button omgButton
            variant="flat"
            (click)="onCancel()">
      Never mind
    </button>
    <button omgButton
            variant="primary"
            [omgFocusOn]="recategorizeApplyFocusOnKey"
            (click)="onRecategorizeDocument()">
      Recategorize
    </button>
  </span>
</div>
