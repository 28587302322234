import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ESCAPE } from '@angular/cdk/keycodes';
import { GlobalPositionStrategy, OverlayRef } from '@angular/cdk/overlay';

import { DialogPosition } from './dialog-config';
import { DialogContainer } from './dialog-container';

// Counter for unique dialog ids.
let uniqueId = 0;

/**
 * Reference to a dialog opened via the DialogService.
 *
 * @note Much of the dialog is based off the material dialog
 * @see https://github.com/angular/material2/blob/master/src/lib/dialog/dialog-ref.ts
 */
export class DialogRef<T, R = any> {
  /** The instance of component opened into the dialog. */
  componentInstance: T;

  /** Whether the user is allowed to close the dialog. */
  disableClose: boolean | undefined = this._containerInstance._config
    .disableClose;

  /** Subject for notifying the user that the dialog has finished closing. */
  private readonly _afterClosed = new Subject<R | undefined>();

  /** Result to be passed to afterClosed. */
  private _result: R | undefined;

  constructor(
    private overlayRef: OverlayRef,
    public _containerInstance?: DialogContainer,
    readonly id: string = `omg-dialog-${uniqueId++}`,
  ) {
    // Pass the id along to the container.
    this._containerInstance._id = id;

    overlayRef.detachments().subscribe(() => {
      this._afterClosed.next(this._result);
      this._afterClosed.complete();
      this.componentInstance = null;
      this.overlayRef.dispose();
    });

    overlayRef
      .keydownEvents()
      .pipe(filter(event => event.keyCode === ESCAPE && !this.disableClose))
      .subscribe(() => this.close());
  }

  /**
   * Close the dialog.
   * @param dialogResult Optional result to return to the dialog opener.
   */
  close(dialogResult?: R) {
    this._result = dialogResult;
    this.overlayRef.dispose();
    this._containerInstance.dispose();
  }

  /**
   * Gets an observable that is notified when the dialog is finished closing.
   */
  afterClosed(): Observable<R | undefined> {
    return this._afterClosed.asObservable();
  }

  /**
   * Updates the dialog's position.
   * @param position New dialog position.
   */
  updatePosition(position?: DialogPosition): this {
    const strategy = this.getPositionStrategy();

    if (position && (position.left || position.right)) {
      position.left
        ? strategy.left(position.left)
        : strategy.right(position.right);
    } else {
      strategy.centerHorizontally();
    }

    if (position && (position.top || position.bottom)) {
      position.top
        ? strategy.top(position.top)
        : strategy.bottom(position.bottom);
    } else {
      strategy.centerVertically();
    }

    this.overlayRef.updatePosition();

    return this;
  }

  /**
   * Updates the dialog's width and height.
   * @param width New width of the dialog.
   * @param height New height of the dialog.
   */
  updateSize(width: string = '', height: string = ''): this {
    this.getPositionStrategy()
      .width(width)
      .height(height);

    this.overlayRef.updatePosition();
    return this;
  }

  /** Fetches the position strategy object from the overlay ref. */
  private getPositionStrategy(): GlobalPositionStrategy {
    return this.overlayRef.getConfig()
      .positionStrategy as GlobalPositionStrategy;
  }
}
