<form [formGroup]="form.controls">
  <div class="padding-normal"
       om-layout-gutter
       om-layout="vertical">
    <div>Requested by {{changeRx.originalMedicationPrescription.pharmacy.name}}</div>
    <omg-change-rx-medication-select [options]="changeRx.rxChangeRequest.rxChangeRequestOptions"
                                     [form]="form">
    </omg-change-rx-medication-select>
    <omg-collapse om-collapse
                  [ngClass]="{'-error':forceEdit}"
                  (expandedChange)="onEditorExpand($event)"
                  #regimenEditCollapseRef="omgCollapse"
                  *ngIf="regimenEditable; else staticInstructions">
      <a om-layout-gutter
         omgCollapseToggle="expand"
         class="om-link -plain-text">{{ changeRx.originalMedicationPrescription.medForDisplay.instructions }}
        <i class="fa fa-pencil"></i>
      </a>
      <i *ngIf="forceEdit"
         omgTooltip
         [tooltipText]="instructionsTooLongMessage"
         tooltipPosition="right"
         class="fa fa-exclamation-circle"></i>
      <omg-expanded>
        <omg-regimen-editor *ngIf="regimenEditCollapseRef.expanded"
                            [medication]="form.controls.get('selectedRxChangeRequestOption')?.value"
                            (collapse)="regimenEditCollapseRef.collapse()"></omg-regimen-editor>
      </omg-expanded>
    </omg-collapse>
    <ng-template #staticInstructions>
      <span>{{ form.controls.get('selectedRxChangeRequestOption').value?.medForDisplay.instructions }}</span>
    </ng-template>
    <div om-layout-gutter
         omgCollapse
         *ngIf="form.controls.get('selectedRxChangeRequestOption').value">
      <omg-cart-item-quantity-and-package-size [form]="form.controls"
                                               *ngIf="changeRx.medicationPrescriptionItem"
                                               [medicationDispensable]="changeRx.originalMedicationPrescription.medicationPrescriptionItem.medicationDispensable"
                                               [focusOnMedicationKey]="changeRx.originalMedicationPrescription.medicationPackageSizeId">
      </omg-cart-item-quantity-and-package-size>
      <label om-layout-gutter-mini
             *ngIf="changeRx.originalMedicationPrescription.dispensableMaxRefills === 0">
        <span>refills</span>
        <span omgTooltip
              tooltipText="Schedule II medications cannot have any refills."
              tooltipPosition="right">
          <input om-flex="10"
                 omgInputText
                 class="-read-only"
                 disabled
                 value="0"
                 type="number"
                 data-cy="new-rx-refills-sched2-input" />
        </span>
      </label>
      <label om-layout-gutter-mini
             *ngIf="changeRx.originalMedicationPrescription.dispensableMaxRefills !== 0">
        <ng-container [ngPlural]="changeRx.originalMedicationPrescription.refills || 0">
          <ng-template ngPluralCase="=1"><span>refill</span></ng-template>
          <ng-template ngPluralCase="other"><span>refills</span></ng-template>
        </ng-container>
        <input omgInputNumber
               omgTrimTrailingZeros
               om-flex="10"
               formControlName="refills"
               type="number"
               data-cy="new-rx-refills-input" />
      </label>
    </div>
  </div>

</form>
