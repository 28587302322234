import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiConfigOptions, ApiService, PatientSelectors } from '@app/core';
import { isTruthy } from '@app/utils';

import {
  EntityApiServiceConfig,
  EntityChangesPayload,
  EntityIdentifierPayload,
  IEntityApiService,
} from './entity-api-type';

export class EntityApiService<T> implements IEntityApiService<T> {
  basePath: string;
  deletePath: string;
  params: any;
  apiConfig: ApiConfigOptions;

  constructor(protected api: ApiService, config: EntityApiServiceConfig) {
    this.basePath = config.basePath;
    this.params = config.params;
    this.apiConfig = config.apiConfig || {};
  }

  getAll(): Observable<T[]> {
    return this.api
      .get<T[]>(this.basePath, this.params, null, true)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  getById({ id }: EntityIdentifierPayload<T>): Observable<T> {
    return this.api
      .get<T>(`${this.basePath}/${id}`, this.params, null, true)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  save(data: Partial<T>): Observable<T> {
    return this.api
      .save<T>(this.basePath, data, {}, true)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  update({ id, changes }: EntityChangesPayload<T>): Observable<T> {
    return this.api
      .update<T>(`${this.basePath}/${id}`, changes, this.apiConfig, true)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error)));
  }

  delete({ id }: EntityIdentifierPayload<T>): Observable<number> {
    return this.api.delete(`${this.basePath}/${id}`).pipe(
      map(() => id),
      catchError((error: HttpErrorResponse) => throwError(error)),
    );
  }
}

export class PatientEntityApiService<T> extends EntityApiService<T> {
  constructor(
    api: ApiService,
    config: EntityApiServiceConfig,
    protected patientSelectors: PatientSelectors,
  ) {
    super(api, config);
    patientSelectors.patientId.pipe(isTruthy()).subscribe(patientId => {
      this.basePath = config.basePath.replace(':patientId', String(patientId));
    });
  }
}
