import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { buildCheckoutMessages } from '@app/modules/rx-cart/shared/rx-cart-utils';

import { RxVerificationForm } from '../../shared/rx-verification-form';
import { RxVerificationFormService } from '../../shared/rx-verification-form.service';
import {
  mapToButtonText,
  readyToVerify,
} from '../../shared/rx-verification-utils';
import {
  RxCartErrors,
  RxCartStatus,
  RxCartTypes,
  RxVerification,
  RxVerified,
} from '../../shared/rx-verification.type';
import { RxVerificationActions } from '../../store/rx-verification.actions';
import { RxVerificationSelectors } from '../../store/rx-verification.selectors';

@Component({
  selector: 'omg-rx-verification',
  templateUrl: './rx-verification.component.html',
  styleUrls: ['./rx-verification.component.scss'],
})
export class RxVerificationComponent implements OnInit, OnDestroy {
  @Input() cartId: number;
  @Input() cartType: RxCartTypes;
  @Input() cartStatus: RxCartStatus;
  @Input() cartErrors: RxCartErrors;

  @Output() complete = new EventEmitter<RxVerified>();
  @Output() cancel = new EventEmitter();

  messages = buildCheckoutMessages();
  verificationState$: Observable<RxVerification>;
  buttonText: String;
  verificationForm: RxVerificationForm;
  enableWarningsDisplay: boolean;

  constructor(
    private rxVerificationActions: RxVerificationActions,
    private rxVerificationSelectors: RxVerificationSelectors,
    private rxVerificationFormService: RxVerificationFormService,
  ) {}

  ngOnDestroy() {
    this.rxVerificationActions.unsubscribeFromNotifications();
    this.verificationForm.unsubscribe();
  }

  ngOnInit() {
    this.rxVerificationActions.initializeState();

    if (this.cartStatus.requireTwoFactorCheckout) {
      this.rxVerificationActions.loadDevices(this.cartType);
    }
    this.verificationForm = this.rxVerificationFormService.buildForm();

    this.verificationState$ = this.rxVerificationSelectors.verificationState.pipe(
      tap(state => (this.buttonText = mapToButtonText(state, this.cartStatus))),
      tap(({ token }) => token && this.onComplete(token)), // verification is complete when token is received
    );
  }

  onSend($event: Event) {
    $event.preventDefault();
    this.enableWarningsDisplay = true;

    if (readyToVerify(this.cartStatus)) {
      this.verifyPassword();
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  private verifyPassword() {
    return this.cartStatus.requireTwoFactorCheckout
      ? this.rxVerificationActions.verify({
          cartId: this.cartId,
          cartType: this.cartType,
          password: this.verificationForm.password,
          device: this.verificationForm.device,
          code: this.verificationForm.code,
        })
      : this.onComplete(); // password will be verified on cart submission
  }

  private onComplete(token?: string) {
    const { password } = this.verificationForm;
    this.complete.emit({ password, token });
  }
}
