<div om-layout
     om-layout-gutter
     *ngIf="isProvider$ | ngrxPush">
  <omg-signed-on-behalf-of om-flex
                           om-layout="vertical"
                           [signedOnBehalfOfId]="prescriberId"
                           [validPrescribers]="sortedValidPrescribers$ | ngrxPush"
                           data-cy="renewal-sign-on-behalf-of">
  </omg-signed-on-behalf-of>
  <omg-dea-license-number om-flex
                          om-layout="vertical"
                          [credentialDropdownItems$]="credentialItems$"
                          [loading$]="credentialsLoading$"
                          [validPrescriber]="renewal.prescriber"
                          [selectedPrescribingCredentialId]="prescribingCredentialId"
                          data-cy="renewal-prescribing-credential-dropdown">
  </omg-dea-license-number>
</div>
