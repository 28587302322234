import {
  AfterContentInit,
  Attribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'omg-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent implements AfterContentInit {
  @Input() key: string = null;
  @Input() heading = '';
  @Input() badgeCount$: Observable<number>;
  @Output() isActiveChange = new EventEmitter();
  @HostBinding('attr.data-cy') dataCyAttr;

  private _isActive = false;
  private _initialized = false;

  @Input()
  get isActive() {
    return this.routerLinkActive
      ? this.routerLinkActive.isActive
      : this._isActive;
  }

  set isActive(value: boolean) {
    if (this._isActive !== value) {
      this._isActive = value;
      this.isActiveChange.emit(value);
    }
  }

  get route() {
    return this.routerLink ? this.routerLink.urlTree.toString() : null;
  }

  constructor(
    @Optional() @Self() public routerLink: RouterLink,
    @Optional() @Self() public routerLinkActive: RouterLinkActive,
    @Attribute('data-cy') private dataCy?,
  ) {
    this.dataCyAttr = dataCy;
  }

  ngAfterContentInit() {
    if (!this._initialized) {
      Promise.resolve().then(() => {
        this.isActiveChange.emit(this.isActive);
      });
      this._initialized = true;
    }
  }
}
