import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import {
  Renewal,
  RenewalCartState,
} from '@app/features/renewals/shared/renewals.type';
import { PendingNewRx } from '@app/modules/rx-cart/shared';

import { RefillText } from '../../shared-rx.type';
import { CheckoutListItemOptions } from './checkout-list-item.type';

@Component({
  selector: 'omg-checkout-list-item',
  templateUrl: './checkout-list-item.component.html',
  styleUrls: ['./checkout-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutListItemComponent implements OnInit {
  @Input() handwrittenConfirmed: FormControl;
  @Input() readyToSign: FormControl;
  @Input() messages: { [key: string]: string };
  @Input() medication: PendingNewRx | Renewal;
  @Input() isRenewal = false;
  @Output() checked = new EventEmitter<CheckoutListItemOptions>();
  itemId: number;
  refillText: RefillText;

  constructor() {}

  ngOnInit() {
    this.refillText = this.isRenewal ? 'Total Fills' : 'Refills';
  }

  onReadyToSign() {
    if (this.readyToSign.disabled) {
      return;
    }
    const readyToSign = this.readyToSign.value;

    this.checked.emit({
      readyToSign,
      pendingSignature: true,
      id: this.medication.id,
    });
  }

  get showMustHandWrite() {
    if (this.isRenewal) {
      return (
        this.medication.mustHandwrite &&
        (<Renewal>this.medication).cartState !== RenewalCartState.denied
      );
    }
    return this.medication.mustHandwrite;
  }

  onHandwrittenConfirmation(confirmed: boolean) {
    this.checked.emit({
      handwrittenConfirmation: confirmed,
      pendingSignature: false,
      id: this.medication.id,
    });
  }
}
