<form *ngIf="visitProcedure$ | ngrxPush as visitProcedure"
      [formGroup]="form.controls"
      om-layout="vertical"
      om-layout-gutter-large
      class="padding-normal-horizontal"
      omgTheme>

  <div om-layout="horizontal"
       om-layout-align="space-between center"
       class="service-ticket-header padding-half-vertical">
    <strong class="service-ticket-title">
      Service Ticket {{visitProcedure.serviceTicket.id}}
    </strong>

    <p class="service-ticket-last-updated">
      Last updated: {{visitProcedure.updatedAt | omgDate: 'withTime2y'}}
    </p>
  </div>

  <div>
    <div om-layout="vertical"
         om-layout-gutter>
      <div>
        <strong>Visit Category</strong>
        <omg-dropdown [options]="dropdownItems$ | ngrxPush"
                      [formControl]="form.controls.get(form.CONTROL_NAME_VISIT_TYPE)"
                      flex="true"
                      data-cy="est-visit-category"></omg-dropdown>
      </div>
      <omg-checkbox [label]="visitProcedure.visitProcedureType.systemName === 'occupational_medicine_workers_comp' ? 'First visit' : 'Established patient'"
                    data-cy="est-checkbox-first-visit"
                    [formControl]="form.controls.get(form.CONTROL_NAME_FIRST_VISIT)"
                    *ngIf="visitProcedure.requiredParams.includes('first_visit')">
      </omg-checkbox>

      <div om-layout
           om-layout-align="start center"
           om-layout-gutter-large
           *ngIf="(featureVirtualVisits | ngrxPush) &&
                   ((phoneMedicalDiscussionVisitTypeActive$ | ngrxPush) ||
                     visitProcedure.requiredParams.includes('virtual'))">
        <omg-checkbox label="Remote Visit w/ Live Audio-Video"
                      data-cy="est-checkbox-remote-visit"
                      [formControl]="form.controls.get(form.CONTROL_NAME_VIRTUAL)"
                      *ngIf="visitProcedure.requiredParams.includes('virtual')">
        </omg-checkbox>
        <button class="om-button -link"
                data-cy="est-phone-discussion-button"
                *ngIf="phoneMedicalDiscussionVisitTypeActive$ | ngrxPush"
                (click)="setVisitType('phone_medical_discussion')">
          Unable to connect via video
        </button>
      </div>
      <omg-checkbox label=">50% of this visit was spent on counseling & care coordination"
                    data-cy="est-checkbox-counseling-threshold"
                    [formControl]="form.controls.get(form.CONTROL_NAME_COUNSELING_THRESHOLD_MET)"
                    *ngIf="visitProcedure.requiredParams.includes('counseling_threshold_met')">
      </omg-checkbox>
      <omg-checkbox label="Code based on time"
                    data-cy="est-checkbox-counseling-threshold"
                    [formControl]="form.controls.get(form.CONTROL_NAME_TIME_BASED)"
                    *ngIf="visitProcedure.requiredParams.includes('time_based')">
      </omg-checkbox>
      <omg-checkbox label="With new findings or exacerbation"
                    data-cy="est-checkbox-new-findings"
                    [formControl]="form.controls.get(form.CONTROL_NAME_NEW_FINDINGS)"
                    *ngIf="visitProcedure.requiredParams.includes('new_findings')"></omg-checkbox>

      <div *ngIf="visitProcedure.requiredParams.includes('total_time')"
           class="total-time"
           om-layout="vertical">
        <strong>Total time spent on {{visitProcedure.timeBasedServiceName}} on
          {{visitProcedure.serviceTicket.appointment.startAt | omgDate: '4y'}}</strong>
        <span>
          <input omgInputText
                 [formControl]="form.controls.get(form.CONTROL_NAME_TIME_BASED_MINUTES)"
                 [ngClass]="{'input-error': fieldInvalid(form.CONTROL_NAME_TIME_BASED_MINUTES)}" />
          minutes
        </span>
      </div>
      <ng-container *ngIf="!form.controls.get(form.CONTROL_NAME_TIME_BASED_MINUTES).dirty">
        <div class="error"
             *ngFor="let error of form.controls.get(form.CONTROL_NAME_TIME_BASED_MINUTES).errors?.serverErrors">
          {{error}}
        </div>
      </ng-container>

      <omg-button-group [label]="getOptionsHeader(visitProcedure)"
                        data-cy="est-visit-procedure"
                        [options]="options$ | ngrxPush"
                        [buttonGroupControl]="form.controls.get(form.CONTROL_NAME_RULE)"
                        groupName="visitProcedure">
      </omg-button-group>
      <ng-container *ngIf="!form.controls.get(form.CONTROL_NAME_RULE).dirty">
        <div class="error"
             *ngFor="let error of form.controls.get(form.CONTROL_NAME_RULE).errors?.serverErrors">
          {{error}}
        </div>
      </ng-container>

      <omg-service-ticket-assessed-problems *ngIf="featureUseAssessedProblems | ngrxPush; else oldProblems"
                                            [visitProcedure]="visitProcedure"
                                            [disabled]="signed"
                                            [primaryProblem]="form.controls.get(form.CONTROL_NAME_PRIMARY_ASSESSED_PROBLEM)"
                                            data-cy="est-problems-assessed">
      </omg-service-ticket-assessed-problems>

      <ng-template #oldProblems>
        <!-- Deprecated in favor of using assessed problems component above -->
        <omg-service-ticket-indications [visitProcedure]="visitProcedure"
                                        [disabled]="signed"
                                        [problemCodeControl]="form.controls.get(form.CONTROL_NAME_PRIMARY_PROBLEM_CODE)">
        </omg-service-ticket-indications>
      </ng-template>

      <omg-service-ticket-procedures [visitProcedure]="visitProcedure"
                                     [errors]="nonFormControlErrors.procedures"
                                     data-cy="est-procedures-performed"></omg-service-ticket-procedures>

      <omg-service-ticket-administrative-comments class="padding-normal-vertical"
                                                  *ngIf="featureShowAdministrativeComments | ngrxPush"
                                                  [control]="form.controls.get(form.CONTROL_NAME_ADMINISTRATIVE_COMMENTS)"
                                                  data-cy="est-admin-comments">
      </omg-service-ticket-administrative-comments>
    </div>
  </div>
</form>
