import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MLNoteRetitleFeedback, Note } from '@app/modules/note/shared/note.type';
import { MLNoteRetitleFeedbackForm } from '@app/modules/note/shared/ml-note-retitle-feedback-form';
import { CollapseDirective } from '@app/shared';
import { first, map } from 'rxjs/operators';
import { NoteActions } from '../../store';
import { AnalyticsActions } from '@app/core';
import { AnalyticsEvent } from '@app/core/analytics/shared/analytics.type';

@Component({
  selector: 'omg-ml-note-retitle-feedback',
  templateUrl: './ml-note-retitle-feedback.component.html',
  styleUrls: ['./ml-note-retitle-feedback.component.scss'],
})
export class MLNoteRetitleFeedbackComponent implements OnInit {

  @Input()
  note$: Observable<Note>;

  @ViewChild('collapseRef', { static: true })
  collapseRef: CollapseDirective;

  form: MLNoteRetitleFeedbackForm;
  terms$: Observable<string>;

  constructor(
    private actions: NoteActions,
    private analytics: AnalyticsActions,
  ) {}

  ngOnInit(): void {
    this.form = new MLNoteRetitleFeedbackForm();
    this.terms$ = this
      .note$
      .pipe(
        map(note => note.mlNoteSuggestion.reasons || []),
        map(terms => terms.join(', ')),
      );
  }

  showForm(): void {
    this.collapseRef.expand();
  }

  dismissBanner(): void {
    this.invokeWithNote(note => {
      this.actions.sendMLNoteRetitleDismissedFeedback(note);
      this.analytics.trackEvent(AnalyticsEvent.MLNoteRetitleFeedbackDismissed, {
        workflow: 'Charting',
        component: note.noteType.name,
        subcomponent: 'ml-note-retitle-feedback',
        noteId: note.id,
      });
    });
  }

  submit(): void {
    this.form.controls.markAllAsTouched();
    if (this.form.controls.valid) {
      this.invokeWithNote(note => {
        const feedback: MLNoteRetitleFeedback = {
          noteId: note.id,
          mlSuggestionId: note.mlNoteSuggestion.id,
          specialty: this.form.controls.get('specialty').value,
          otherSpecialty: this.form.controls.get('otherSpecialty').value,
          otherFeedback: this.form.controls.get('otherFeedback').value,
        };
        this.actions.sendMLNoteRetitleFormFeedback(feedback);
        this.analytics.trackEvent(AnalyticsEvent.MLNoteRetitleFeedbackSubmitted, {
          workflow: 'Charting',
          component: note.noteType.name,
          subcomponent: 'ml-note-retitle-feedback',
          noteId: feedback.noteId,
          inputString: (feedback.specialty === 'other') ? feedback.otherSpecialty : feedback.specialty,
        });
      });
    }
  }

  cancel(): void {
    this.collapseRef.collapse();
  }

  private invokeWithNote(fn: (note: Note) => void): void {
    this
      .note$
      .pipe(
        first(note => note ? true : false),
      )
      .subscribe(note => fn(note));
  }
}
