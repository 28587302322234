import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'omg-workspace',
  templateUrl: './workspace.component.html',
})
export class WorkspaceComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
