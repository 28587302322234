<div *ngIf="profile$ | ngrxPush as profile"
     class="padding-normal-vertical"
     om-layout
     om-layout-align="start"
     om-layout-gutter>
  <omg-profile-image-bubble [url]="profile.profileImageUrl"
                            [initials]="profile.initials"
                            [colorCombo]="profile.id">
  </omg-profile-image-bubble>
  <div om-layout="vertical"
       om-layout-align="start"
       om-layout-gutter
       om-flex="100">
    <div om-layout
         om-layout-align="space-between center"
         om-layout-gutter>
      <span>
        <b>From:</b> {{(primaryIdentity$ | ngrxPush).name}}
      </span>
      <div om-layout
           om-layout-align="end center"
           om-layout-gutter-large>
        <button omgButton
                variant="link"
                (click)="startNotify()"
                *ngIf="!notified"
                tabindex="-1">
          Notify
        </button>
        <button omgButton
                variant="link"
                (click)="startAssign()"
                *ngIf="!assigned"
                tabindex="-1">
          Assign Replies
        </button>
      </div>
    </div>
    <!-- Assign Replies -->
    <label om-layout
           om-layout-gutter
           om-layout-align="start center"
           class="no-margin"
           *ngIf="assigned">
      <b>Assign Replies to:</b>
      <omg-auto-complete #assignAssigneeInput
                         placeholder=""
                         [items]="assigneesItems$ | ngrxPush"
                         (search)="searchForAssignees($event)"
                         [searchFn]="filterTaskAssignees"
                         [(ngModel)]="inProgressMessage.replyTo"
                         (change)="setReplyTo($event)"
                         [omgFocusOn]="assignFocusOnKey"
                         (focusOnRequest)="assignAssigneeInput.focus()"
                         omgStopEvent
                         trackByKey="identifier"
                         bindLabel="label"
                         om-flex
                         clearable="true">
      </omg-auto-complete>
    </label>

    <!-- Notify -->
    <label om-layout
           om-layout-gutter
           om-layout-align="start center"
           *ngIf="notified">
      <b>Notify:</b>
      <omg-auto-complete #notifyAssigneeInput
                         placeholder=""
                         [items]="notifyItems$ | ngrxPush"
                         (search)="searchForNotify($event)"
                         [searchFn]="filterTaskAssignees"
                         [omgFocusOn]="notifyFocusOnKey"
                         [(ngModel)]="inProgressMessage.notify"
                         (focusOnRequest)="notifyAssigneeInput.focus()"
                         (change)="setNotificationRecipient($event)"
                         omgStopEvent
                         trackByKey="identifier"
                         bindLabel="label"
                         om-flex
                         clearable="true">
      </omg-auto-complete>
    </label>
  </div>
</div>
