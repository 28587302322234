import {
  MedicationPrescriptionItemTemplate,
  MedicationRegimen,
} from '@app/modules/medications/shared/medications.type';
import { camelCase, flow, isEqual, unionWith } from '@app/utils';

import { Renewal, RenewalCartResponse } from './renewals.type';

export const mergeUniquePackageOptions = (cartItem: Renewal) => {
  const { packageOptions } = cartItem;

  const availableOptions = unionWith(isEqual, packageOptions.availableOptions, [
    packageOptions.defaultOption.matchedOption,
  ]);
  const fullOptions = {
    ...packageOptions,
    availableOptions,
  };

  const renewal = {
    ...cartItem,
    packageOptions: fullOptions,
  };
  return renewal;
};

const mapDates = (fullRenewal: Renewal): Renewal => {
  const { earliestFillDate: earliestFillDateString, ...renewal } = fullRenewal;
  const earliestFillDate = earliestFillDateString
    ? new Date(earliestFillDateString)
    : null;
  return { ...renewal, earliestFillDate };
};

const mapFrequencyIntervalData = (
  itemTemplate: MedicationPrescriptionItemTemplate,
): Partial<MedicationRegimen> => {
  const { doseLow, doseHigh } = itemTemplate;
  const frequencyInterval = itemTemplate.medicationFrequencyInterval || {
    id: null,
  };
  const frequencyIntervalData = {
    frequencyIntervalId: frequencyInterval.id,
    dose: null,
  };

  if (doseHigh) {
    frequencyIntervalData.dose = `${parseFloat(doseLow)}-${parseFloat(
      doseHigh,
    )}`;
  } else if (doseLow) {
    frequencyIntervalData.dose = parseFloat(doseLow);
  }

  return frequencyIntervalData;
};

const mapInstructionsText = (
  itemTemplate: MedicationPrescriptionItemTemplate,
): Partial<MedicationRegimen> => {
  return {
    instructionsText: itemTemplate && itemTemplate.instructionsText,

    useInstructionsText: !!(itemTemplate && itemTemplate.instructionsText),
  };
};

const mapItemTemplate = (
  itemTemplate: MedicationPrescriptionItemTemplate,
): Partial<MedicationRegimen> => {
  const { durationDays } = itemTemplate || { durationDays: null };
  const { medicationDispensableId: dispensableId } = itemTemplate;

  return {
    ...mapFrequencyIntervalData(itemTemplate),
    ...mapInstructionsText(itemTemplate),
    durationDays,
    dispensableId,
  };
};

const mapRegimenData = (renewal: Renewal): Partial<Renewal> => {
  const {
    medicationPrescriptionTemplate,
    isCustom: isCustomRegimen,
  } = renewal.medicationRegimen;
  const {
    id: prnId,
    desc: prnDescription,
  } = medicationPrescriptionTemplate.medicationPrn || { id: null, desc: null };

  return {
    ...renewal,
    medicationRegimen: {
      ...renewal.medicationRegimen,
      description: prnDescription,
      prnId,
      prnDescription,
      isCustomRegimen,
      ...mapItemTemplate(
        medicationPrescriptionTemplate.medicationPrescriptionItemTemplate,
      ),
    },
  };
};

const mapRouteData = (renewal: Renewal): Partial<Renewal> => {
  return {
    ...renewal,
    medicationRouteId: renewal.medicationRoute.id,
  };
};

export const mapRenewalCartItem = (renewal: Renewal): Renewal =>
  <Renewal>(
    flow(
      mapDates,
      mergeUniquePackageOptions,
      mapRegimenData,
      mapRouteData,
    )(renewal)
  );

export const mapRenewalsToRenewalEntities = (
  data: RenewalCartResponse,
): RenewalCartResponse => {
  const { cartItems: items } = data;

  const cartItems = items.map(mapRenewalCartItem);
  return { ...data, cartItems };
};
