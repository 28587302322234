import { HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  concat,
  InMemoryCache,
} from '@apollo/client/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { CookieService } from '@app/core/auth/shared/cookie.service';
import { ConfigService } from '@app/core/config';

export function createApollo(
  httpLink: HttpLink,
  cookieService: CookieService,
  configService: ConfigService,
): ApolloClientOptions<any> {
  const uri = `${configService.environment.oauth2.providerUrl}/api/graphql`;
  const http = httpLink.create({ uri });

  const authMiddleware = new ApolloLink((operation, forward) => {
    const token = cookieService.get();

    if (token) {
      const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${token}`);
      operation.setContext({
        headers: headers,
      });
    }

    return forward(operation);
  });

  return {
    link: concat(authMiddleware, http),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, CookieService, ConfigService],
    },
  ],
})
export class GraphQLModule {}
