import { getChildHeightPercentile } from '@app/features/summaries/components/infant-growth-measurements-form/percentile-calculator/child-height-percentile';
import { getChildWeightPercentile } from '@app/features/summaries/components/infant-growth-measurements-form/percentile-calculator/child-weight-percentile';
import {
  bmiField,
  bmiPctField,
  headField,
  headPctField,
  htField,
  htPctField,
  infWtField,
  wtField,
  wtPctField,
} from '@app/modules/growth-charts/growth-charts/growth-charts.type';

import { getChildBMIPercentile } from './child-body-mass-index-percentile';
import { getHeadCircPercentile } from './infant-head-circumference-percentile';
import { getInfLenPercentile } from './infant-length-percentile';
import { getInfWtPercentile } from './infant-weight-percentile';

export interface GrowthFieldConfig {
  percentileFieldName: string;
  percentileFn: Function;
  // non-editable meaning it does not need to be updated on change
  nonEditableField?: boolean;
}

export interface GrowthFieldToConfigMapping {
  [inputFieldName: string]: GrowthFieldConfig;
}

export const infantFieldToConfigMapping: GrowthFieldToConfigMapping = {
  [infWtField]: {
    percentileFieldName: wtPctField,
    percentileFn: getInfWtPercentile,
  },
  [htField]: {
    percentileFieldName: htPctField,
    percentileFn: getInfLenPercentile,
  },
  [headField]: {
    percentileFieldName: headPctField,
    percentileFn: getHeadCircPercentile,
  },
};

// NOTE: BMI & bmi_pct is handled separately since it's a calculated value
export const childFieldToConfigMapping: GrowthFieldToConfigMapping = {
  [wtField]: {
    percentileFieldName: wtPctField,
    percentileFn: getChildWeightPercentile,
  },
  [htField]: {
    percentileFieldName: htPctField,
    percentileFn: getChildHeightPercentile,
  },
  [bmiField]: {
    percentileFieldName: bmiPctField,
    percentileFn: getChildBMIPercentile,
    nonEditableField: true,
  },
};
