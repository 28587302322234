import {
  VitalsData,
  VitalsDataMeasurement,
} from '@app/modules/vitals-data/shared/vitals-data.type';

export const wtField = 'wt';
export const infWtField = `inf_wt`;
export const wtPctField = `${wtField}_pct`;
export const htField = 'ht';
export const htPctField = `${htField}_pct`;
export const headField = 'head';
export const headPctField = `${headField}_pct`;
export const bmiField = 'BMI';
export const bmiPctField = 'bmi_pct';

export type GrowthVitalsMeasurementTypesKeys =
  | 'ht'
  | 'inf_wt'
  | 'head'
  | 'BMI'
  | 'wt';

export type PercentileDataSource =
  | 'infant'
  | 'child'
  | 'downsyndrome'
  | 'preterm';

export type PercentileMeasurementType =
  | 'length'
  | 'weight'
  | 'headCirc'
  | 'stature'
  | 'bmi';

export interface AgeRangeInMonths {
  min: number;
  max: number;
}

export const GrowthVitalsMeasurementTitles = {
  ht: 'Height',
  inf_wt: 'Weight',
  head: 'Head Circ',
  BMI: 'BMI',
  wt: 'Weight',
  head_pct: 'Infant Head Circ %ile',
  ht_pct: 'Height %ile',
  wt_pct: 'Weight %ile',
  bmi_pct: 'BMI %ile',
};

export interface PatientVitalDataPoint extends Chart.ChartPoint {
  vital: VitalsData;
  selectedMeasurement: VitalsDataMeasurement;
}

export const ageRange0to2: AgeRangeInMonths = { min: 0, max: 24 };
export const ageRange2to18: AgeRangeInMonths = { min: 24, max: 240 };

export const growthMeasurementTypesDisplayOrder = [
  wtField,
  infWtField,
  wtPctField,
  htField,
  htPctField,
  headField,
  headPctField,
  bmiField,
  bmiPctField,
];

export type GrowthChartTypes =
  | 'infantWeight'
  | 'infantLength'
  | 'infantHeadCircumference'
  | 'childWeight'
  | 'childHeight'
  | 'childBMI';

export type PercentileDatum = { month: number } & {
  [key in PercentileMeasurementType]?: number;
};

export type PercentileDataset = {
  [sourceKey in PercentileDataSource]?: {
    [measurementTypeKey in PercentileMeasurementType]?: {
      [genderKey in 'male' | 'female']?: {
        // Percentile labels (i.e. 2nd, 50th, 95th)
        [key: string]: PercentileDatum[];
      };
    };
  };
};
export interface VitalsDataRow {
  label: string;
  abbreviation: string;
  vitalMeasurements: {
    metadata: string;
    vital: VitalsDataMeasurement;
  }[];
}

export interface VitalsDataColumn {
  hasData: boolean;
  measurementTypes: VitalsDataRow[];
  headers?: string[];
  rowLabels?: VitalsDataRowLabel[];
}

export interface VitalsDataRowLabel {
  collectedAt: string;
  ageAtCollection: string;
}

export interface GrowthChartConfig {
  vitalMeasurementKey: GrowthVitalsMeasurementTypesKeys;
  percentileDataConfig: {
    source: PercentileDataSource;
    measurementType: PercentileMeasurementType;
  };
  ageRangeInMonths: AgeRangeInMonths;
  gender: string;
  chartConfig: Partial<Chart.ChartConfiguration>;
  percentileDataSets?: Chart.ChartDataSets[];
  patientDataSet?: Chart.ChartPoint[];
  chartType: GrowthChartTypes;
}
