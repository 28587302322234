import { ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, take, takeUntil, tap } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core';
import { Renewal } from '@app/features/renewals/shared/renewals.type';
import { RenewalActions, RenewalSelectors } from '@app/features/renewals/store';
import {
  MedicationDispensable,
  MedicationRegimen,
  MedicationRoute,
  newEntityId,
} from '@app/modules/medications/shared';
import {
  MedicationActions,
  MedicationSelectors,
} from '@app/modules/medications/store';
import { ReferenceDataKeys } from '@app/modules/reference-data/shared/reference-data.type';
import { ReferenceDataSelectors } from '@app/modules/reference-data/store/reference-data.selectors';
import { DropdownItem, FormModel } from '@app/shared';
import { head, identity, isTruthy, pickBy } from '@app/utils';
import { DynamicFormGroup } from '@app/utils/forms/base';

import { FrequencyInterval } from '../medications/shared/frequency-interval.type';
import { Prn } from '../medications/shared/prn.type';
import { StructuredRegimenForm } from './structured-regimen-form';
import { mapRegimensToDropdownItems } from './utils';
import { fullPrnOptions } from './utils/prn-utils';
import {
  buildRegimenTextDescription,
  calculateFreeTextMaxLength,
  findDispensableById,
  findRegimenById,
  getDefaultDispensable,
  getRegimenDefaults,
  maxInstructionsTextLengthFeatureSelector,
  RegimenFormControlNames,
  RegimenFormData,
  setRegimenFormValidator,
} from './utils/regimen-utils';

export interface RegimenFormValidatorOptions {
  defaultsEnabled?: boolean;
  durationEnabled?: boolean;
}
const structuredRegimenFormControl = 'structuredRegimen';

export class RegimenForm extends DynamicFormGroup {
  standardRegimens: MedicationRegimen[];
  standardRegimenOptions: DropdownItem[];
  model: FormModel;
  regimens: MedicationRegimen[];
  route$: Observable<any>;
  editingDuration: boolean;
  dispensables: MedicationDispensable[];
  frequencyIntervals: FrequencyInterval[];
  prns: Prn[];
  defaultFormValue: Partial<RegimenFormData>;
  baselineFreeTextLengthMax: number;

  get customizationEnabled() {
    return this.standardRegimens && this.standardRegimens.length !== 0;
  }

  get isCustomRegimen(): boolean {
    return !!this.controls.get('isCustomRegimen').value;
  }

  get defaultDispensable(): MedicationDispensable {
    return getDefaultDispensable(this.dispensables);
  }

  get dispensable(): MedicationDispensable {
    const dispensable =
      findDispensableById(
        this.controls.get('dispensableId').value,
        this.dispensables,
      ) ||
      this.defaultDispensable ||
      <MedicationDispensable>{ id: null };
    this.structuredRegimenForm.dispensable = dispensable;
    return dispensable;
  }

  get regimenTextDescription(): string {
    return buildRegimenTextDescription(
      this.dispensables,
      this.frequencyIntervals,
      this.prns,
      this.controls.value,
      this.controls.valid,
    );
  }

  constructor(
    private actions: RenewalActions,
    private selectors: RenewalSelectors,
    private referenceDataSelectors: ReferenceDataSelectors,
    private medicationActions: MedicationActions,
    private medicationSelectors: MedicationSelectors,
    private featureFlagSelectors: FeatureFlagSelectors,
    private route: MedicationRoute,
    private regimen: MedicationRegimen,
    private unsubscribe$: Subject<void>,
    private structuredRegimenForm: StructuredRegimenForm,
  ) {
    super();
    this.setRoute(this.route);
    this.addControls();
    this.addStructuredRegimenFormControl();
    this.setDefaultFormValue();
    this.buildFormModel();

    this.setupReferenceData();
    this.listenToChanges();
  }

  update({ id, className }: Partial<Renewal>): Observable<Renewal> {
    const { medicationRegimenId, ...rest } = this.controls.value;
    const medicationRegimen = { id: medicationRegimenId, ...rest };
    const changes = {
      medicationRegimenId,
      className,
      medicationRegimen,
    };
    this.actions.update({ id, changes });
    return this.selectors.getById(id);
  }

  getDefaultRegimen(): MedicationRegimen {
    return head(this.standardRegimens);
  }

  editCustomRegimen() {
    this.setDefaultsForCustomRegimen();
    this.resetEditingOptions();
    this.controls.markAsDirty();
    this.controls.updateValueAndValidity();
  }

  cancelCustomRegimen() {
    this.resetValidators();
    this.controls.patchValue(
      {
        useInstructionsText: false,
        isCustomRegimen: false,
        instructionsText: null,
        medicationRegimenId: this.standardRegimens[0].id,
      },
      { emitEvent: false },
    );
    this.controls.markAsDirty();
    this.controls.updateValueAndValidity();
  }

  toggleInstructionsText() {
    const useInstructionsText = !this.controls.get('useInstructionsText').value;
    this.controls.patchValue({ useInstructionsText });
  }

  resetFreeTextControls() {
    this.controls.patchValue({
      instructionsText: null,
    });
    this.setInstructionsTextValidators(false);
  }

  setInstructionsTextValidators = (
    enabled: boolean = !!this.controls.get('useInstructionsText').value,
  ) => {
    const control = this.controls.get('instructionsText');
    setRegimenFormValidator({
      control,
      enabled,
      validators: [
        Validators.required,
        Validators.maxLength(this.getRemainingCharacterCount()),
      ],
    });
    if (enabled) {
      control.enable();
    } else {
      control.disable();
    }
  };

  setPrnValidators = (
    enabled: boolean = this.isCustomRegimen &&
      !!this.controls.get('usePrn').value,
  ) => {
    setRegimenFormValidator({
      control: this.controls.get('prnId'),
      enabled,
    });
  };

  getRemainingCharacterCount(): number {
    return calculateFreeTextMaxLength(
      this.prns,
      this.controls.value,
      this.baselineFreeTextLengthMax,
    );
  }

  private onPrnIdChange = (prnId: number) => {
    const prn = this.prns.find(i => i.id === prnId);
    const prnDescription = prn && prn.desc;
    this.controls.patchValue({ prnDescription }, { emitEvent: false });
  };

  private updateRegimenTextDescription = () => {
    if (this.isCustomRegimen) {
      this.controls
        .get('regimenTextDescription')
        .setValue(this.regimenTextDescription, { emitEvent: false });
    }
  };

  private setupReferenceData() {
    this.referenceDataSelectors
      .get(ReferenceDataKeys.prnOptions)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((prnOptions: Prn[]) => {
          this.prns = fullPrnOptions(
            prnOptions,
            this.controls.get('prnId').value,
            this.controls.get('prnDescription').value,
          );
        }),
      )
      .subscribe();

    this.referenceDataSelectors
      .get(ReferenceDataKeys.medicationFrequencyIntervals)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(
          (intervals: FrequencyInterval[]) =>
            (this.frequencyIntervals = intervals),
        ),
      )
      .subscribe();

    this.setMaxFreeTextLength();
  }

  private setMaxFreeTextLength() {
    maxInstructionsTextLengthFeatureSelector(this.featureFlagSelectors)
      .pipe(take(1))
      .subscribe(length => (this.baselineFreeTextLengthMax = length));
  }

  private listenToChanges() {
    this.controls
      .get('medicationRegimenId')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(this.setRegimenDefaults);
    this.controls.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(this.updateRegimenTextDescription);
    this.structuredRegimenForm.controls.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(300),
        filter(() => this.structuredRegimenForm.controls.valid),
      )
      .subscribe(this.mapStructuredRegimen);
    this.controls
      .get('prnId')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        filter(value => value !== newEntityId),
      )
      .subscribe(this.onPrnIdChange);
    this.controls
      .get('usePrn')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(this.setPrnValidators);
    this.controls
      .get('useInstructionsText')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(this.onUseInstructionsTextChange);
  }

  private mapStructuredRegimen = () => {
    this.controls.patchValue(this.structuredRegimenForm.controls.value);
  };

  private onUseInstructionsTextChange = (useInstructionsText: boolean) => {
    if (useInstructionsText) {
      this.setInstructionsTextValidators(useInstructionsText);
      this.resetStructuredRegimenForm();
    } else {
      this.enableStructuredRegimenForm();
      this.resetFreeTextControls();
    }
  };

  private enableStructuredRegimenForm() {
    this.controls.get(structuredRegimenFormControl).enable();
    this.structuredRegimenForm.resetControls(true);
  }

  private resetStructuredRegimenForm() {
    this.controls.get(structuredRegimenFormControl).disable();
    this.structuredRegimenForm.resetControls(false);
  }

  private setRegimenDefaults = () => {
    const regimenId = this.controls.get('medicationRegimenId').value;
    const regimen = findRegimenById(regimenId, this.regimens);
    const formValue = getRegimenDefaults(regimen, this.defaultDispensable);
    this.controls.patchValue(formValue, { emitEvent: false });
  };

  private resetValidators(): void {
    this.resetStructuredRegimenForm();
    this.resetFreeTextControls();
    this.setPrnValidators(false);
    this.controls.patchValue(this.defaultFormValue, { emitEvent: false });
    this.controls.updateValueAndValidity();
  }

  private setDefaultsForCustomRegimen(): void {
    this.controls.patchValue(
      {
        isCustomRegimen: true,
        ...pickBy(identity, this.defaultFormValue),
      },
      { emitEvent: false },
    );
    this.setDispensableData(this.dispensables);
    this.controls.updateValueAndValidity();
  }

  private resetEditingOptions(): void {
    this.controls.patchValue({ useInstructionsText: false });
    this.editingDuration = false;
  }

  private setRoute(route: MedicationRoute) {
    this.medicationActions.loadMedication(route.id);

    this.route$ = this.medicationSelectors.medication(route.id).pipe(
      isTruthy(),
      take(1),
      tap(({ regimens, dispensables }: MedicationRoute) => {
        this.setRegimenData(regimens);
        this.setDispensableData(dispensables);
      }),
    );
  }

  private buildFormModel() {
    this.model = new FormModel(this.controls, {
      saveFunction: () => this.update(this.value),
      autosave: false,
    });
  }

  private setDefaultFormValue() {
    this.defaultFormValue = this.value;
  }

  private addStructuredRegimenFormControl() {
    this.controls.addControl(
      structuredRegimenFormControl,
      this.structuredRegimenForm.controls,
    );
    if (!!this.controls.get('useInstructionsText').value) {
      this.controls.get(structuredRegimenFormControl).disable();
    }
  }

  private addControls() {
    const excludedControlNames = [
      'regimenTextDescription',
      'medicationRegimenId',
      'usePrn',
    ];
    const controlNames = Object.keys(RegimenFormControlNames).filter(
      name => !excludedControlNames.includes(name),
    );
    const controls: {
      name: string;
      validators?: ValidatorFn[];
    }[] = controlNames.map(name => ({ name }));

    controls.forEach(control =>
      this.addControl({
        name: control.name,
        defaultValue: this.regimen[control.name],
        validators: control.validators,
      }),
    );
    this.addControl({
      name: 'medicationRegimenId',
      defaultValue: this.regimen.id,
    });
    this.addControl({
      name: 'regimenTextDescription',
      defaultValue: this.regimenTextDescription,
    });
    this.addControl({ name: 'usePrn', defaultValue: !!this.regimen.prnId });
  }

  private setRegimenData(regimens: MedicationRegimen[]) {
    this.regimens = !!regimens ? regimens : [];
    this.standardRegimens = this.regimens.filter(regimen => !regimen.isCustom);

    this.standardRegimenOptions = mapRegimensToDropdownItems(
      this.standardRegimens,
    );
  }

  private setDispensableData = (dispensables: MedicationDispensable[]) => {
    this.dispensables = !!dispensables ? dispensables : [];
    const dispensableId = this.regimen.dispensableId || this.dispensable.id;
    this.controls.patchValue({ dispensableId });
  };
}
