<omg-section-title>
  <div om-layout
       om-layout-fill
       om-layout-align="space-between center">
    <span>Health Background</span>
    <span class="om-icon clickable fa fa-trash"
          (click)="deleteFromNote()"
          *ngIf="!signed"
          omgTooltip
          tooltipText="Delete from Note"
          tooltipPosition="left"></span>
  </div>
</omg-section-title>
<ul class="padding-normal"
    om-layout="vertical"
    om-layout-gutter>
  <li class="om-animate">
    <b>Important Events & Surgeries</b>
    <p>{{ healthBackground.illnessesAndSurgeries.notes }}</p>
    <b>Family Data</b>
    <p>{{ healthBackground.familyData.notes }}</p>
    <b>Social Data</b>
    <p>{{ healthBackground.socialData.notes }}</p>
  </li>
</ul>
