import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'omg-checkout-issued-on-and-notify-patient',
  templateUrl: './checkout-issued-on-and-notify-patient.component.html',
})
export class CheckoutIssuedOnAndNotifyPatientComponent implements OnInit {
  @Input() notifyPatient?: FormControl;
  @Input() disabled: boolean;

  today = new Date();

  constructor() {}

  ngOnInit() {
    if (!!this.notifyPatient && this.disabled) {
      this.notifyPatient.setValue(false);
      this.notifyPatient.disable();
    }
  }
}
