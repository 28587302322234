<div *ngIf="note">
  <form [omgForm]="noteFormModel"
        [formGroup]="noteFormModel.form">

    <omg-card-title>
      <div omgCollapse
           #noteCollapse="omgCollapseProvider"
           [expanded]="note.totalComments > 0">
        <omg-note-header [note]="note$ | ngrxPush"
                         [todo]="todo$ | ngrxPush"
                         [editable]="noteHasEditableTitle$ | ngrxPush"
                         (close)="onCloseNote()"
                         [form]="noteFormModel.form"
                         [commentsCollapseRef]="noteCollapse"></omg-note-header>
      </div>
    </omg-card-title>

    <omg-card-body>
      <div omgScrollingContainer
           #scrollableContainer>
        <!-- Comments -->
        <omg-expanded [collapseProvider]="noteCollapse">
          <omg-comments [commentable]="commentable"
                        (commentAdded)="onCommentUpdate('add')"
                        (commentRemoved)="onCommentUpdate('remove')"></omg-comments>
        </omg-expanded>

        <!-- Task Assignment -->
        <omg-task-assignment *ngIf="noteHasIncompleteTodo"
                             [todo]="todo$">
        </omg-task-assignment>

        <omg-ml-note-retitle-feedback *ngIf="note.mlNoteSuggestion && !note.mlNoteSuggestion.mlFeedback"
                                      [note$]="note$">
        </omg-ml-note-retitle-feedback>

        <!-- Note body -->
        <div class="padding-normal"
             *ngIf="showRichTextNoteBodyEditor">
          <omg-rich-text-editor *ngIf="!hasSlashInsertionFeature"
                                #editor
                                [omgFocusOn]="noteOnFocusKey"
                                (focusOnRequest)="editor.focus()"
                                formControlName="body"
                                placeholderText="Type message here..."
                                [useMarkdownStyling]="!noteTypeIsAnyMiscType"></omg-rich-text-editor>

          <omg-chart-text-box *ngIf="hasSlashInsertionFeature"
                              templateType="text"
                              [scrollingContainer]="scrollableContainer"
                              [control]="noteFormModel.form.get('body')"
                              quillFormat="html"
                              placeholderText="Type message here..."
                              [insertionEventProps]="{
                                  component: note.noteType.name,
                                  subcomponent: 'Note Body'
                                }">
          </omg-chart-text-box>
        </div>


        <div *ngIf="showSimpleNoteBodyEditor"
             class="padding-normal">
          <label>Note Body
            <textarea omgTextarea
                      omgSnippets
                      [insertionEventProps]="{
                        component: note.noteType.name,
                        subcomponent: 'Note Body'
                      }"
                      autosize
                      rows="3"
                      formControlName="body"
                      om-layout-fill
                      (click)="$event.target.focus()"></textarea>
          </label>
        </div>

        <div class="padding-normal note-body"
             omgTheme
             *ngIf="showReadOnlyNoteBody && !showSimpleNoteBodyEditor"
             [ngClass]="getClassForNoteBody(note.noteType.name)"
             [innerHTML]="note.body">
        </div>

        <div class="padding-normal"
             *ngFor="let attachment of noteFormModel.get('attachments').value">
          <omg-file-link [url]="attachment.viewingPath"
                         [text]="attachment.name"
                         [deletable]="isAttachable"
                         (delete)="onDeleteAttachment(attachment)"></omg-file-link>
        </div>

        <!-- Documents -->
        <div class="padding-normal"
             *ngFor="let doc of noteFormModel.get('documents').value; index as index"
             om-layout="vertical"
             om-layout-gutter>
          <div om-layout-gutter>
            <omg-file-link *ngIf="!doc.forLab"
                           [key]="doc.awsKey"
                           [encode]="false"
                           [bucket]="doc.awsBucket"
                           text="Download File"></omg-file-link>

            <button *ngIf="showRecordProcedureButton$(doc.id) | ngrxPush"
                    (click)="toggleProcedureCaptureForm(doc.id)"
                    omgButton
                    variant="secondary">{{ getProcedureCaptureButtonText$(doc.id) | ngrxPush }}</button>
          </div>

          <omg-historical-procedure-capture *ngIf="canRecordHistoricalProcedure"
                                            [documentId]="doc.id"
                                            [attemptedNoteSubmit]="isConfirmingProcedure">
          </omg-historical-procedure-capture>

          <omg-lab-result *ngIf="doc.forLab"
                          [awsKey]="doc.awsKey"
                          [awsBucket]="doc.awsBucket"
                          [labDataId]="doc.parentId"
                          [patientId]="patient?.id"></omg-lab-result>

          <omg-pdf-viewer class="workspace-column"
                          *ngIf="onIsPdfDocument(doc)"
                          [key]="doc.pdfKey"
                          [bucket]="doc.pdfBucket"></omg-pdf-viewer>
        </div>

        <omg-transmission-details *ngIf="note.faxes.length > 0"
                                  [history]="noteFormInfo.transmissionDetails"></omg-transmission-details>

        <omg-form-state when="saveError">
          <ng-template let-change>
            <div class="om-messages"
                 *ngFor="let msg of change.error">
              {{ msg }}
            </div>
          </ng-template>
        </omg-form-state>
        <div class="padding-normal om-messages -warning"
             *ngIf="attachError">{{attachError}}</div>
      </div>
    </omg-card-body>
    <omg-card-footer>
      <omg-collapse #noteActionBarCollapse="omgCollapse">
        <omg-expanded *ngIf="isDeleting">
          <div class="action-bar -warning"
               om-layout
               om-layout-align="space-between center">
            <b>Permanently delete this note?</b>
            <span om-layout
                  om-layout-gutter
                  om-layout-align="space-between center">
              <button omgButton
                      variant="flat"
                      (click)="onToggleDeleting()">
                Never mind
              </button>
              <button type="button"
                      omgButton
                      variant="primary"
                      (click)="onDelete()">
                Delete Note
              </button>
            </span>
          </div>
        </omg-expanded>

        <omg-expanded *ngIf="isConfirmingProcedure">
          <div class="action-bar"
               om-layout
               om-layout-align="space-between center">
            <b>Review without saving procedure?</b>
            <span om-layout
                  om-layout-gutter
                  om-layout-align="space-between center">
              <button omgButton
                      variant="flat"
                      (click)="onToggleProcedureConfirm()">
                Cancel
              </button>
              <button type="button"
                      omgButton
                      variant="primary"
                      (click)="doFinishNote()">
                Review
              </button>
            </span>
          </div>
        </omg-expanded>

        <omg-expanded *ngIf="isConfirmingSignature">
          <div class="action-bar -warning"
               om-layout
               om-layout-align="space-between center"
               data-cy="note-confirm-signature">
            <b>Are you sure?</b>
            <span om-layout
                  om-layout-gutter
                  om-layout-align="space-between center">
              <button omgButton
                      variant="flat"
                      (click)="onToggleConfirm()">
                Never mind
              </button>
              <button type="button"
                      omgButton
                      variant="primary"
                      (click)="doFinishNote()">
                Sign Note
              </button>
            </span>
          </div>
        </omg-expanded>

        <omg-expanded *ngIf="isRedacting">
          <div class="action-bar -warning"
               om-layout
               om-layout-align="space-between center">
            <b>Permanently redact this note?</b>
            <span om-layout
                  om-layout-gutter
                  om-layout-align="space-between center">
              <button omgButton
                      variant="flat"
                      (click)="onToggleRedact()">
                Never mind
              </button>
              <button type="button"
                      omgButton
                      variant="primary"
                      (click)="onRedactNote()">
                Redact
              </button>
            </span>
          </div>
        </omg-expanded>

        <omg-template-insertion-action-bar *ngIf="showTemplateInsertionBar()"
                                           [collapseRef]="noteActionBarCollapse"
                                           [index]="noteTemplatesIndex"
                                           (apply)="onApplyNoteTemplate($event)">
        </omg-template-insertion-action-bar>


        <omg-expanded *ngIf="isRecategorizing">
          <omg-recategorize-document-action-bar [index]="recategorizeSearchIndex"
                                                (cancel)="onCancelRecategorize()"
                                                (apply)="onRecategorizeDocument($event)">
          </omg-recategorize-document-action-bar>
        </omg-expanded>

        <!-- Default action bar -->
        <omg-collapsed>
          <div class="action-bar"
               [hidden]="hasTodoError | ngrxPush"
               om-layout>
            <div om-layout
                 om-layout-align="start center"
                 om-layout-gutter
                 om-layout-fill>
              <omg-form-state>
                <ng-template let-change>
                  <div om-layout
                       om-layout-gutter>
                    <button *ngIf="noteHasIncompleteTodo"
                            omgButton
                            variant="primary"
                            [ngClass]="{ '-processing': change.state === 'saving' }"
                            type="submit"
                            [disabled]="change.state === 'saving' || formUploading"
                            (click)="onFinishNote()">{{ change.state === 'saving' ? 'Saving...' : finishLabel }}</button>
                    <button omgButton
                            *ngIf="(showHelpOut$ | ngrxPush)"
                            variant="link"
                            (click)="openVMTHelpModal()"
                            [disabled]="change.state === 'saving'"
                            angulartics2On="click"
                            angularticsAction="Create HelpOut Request"
                            data-cy="create-vmt-help-request-link">Create HelpOut Request</button>
                  </div>
                  <ng-container *ngIf="!noteHasIncompleteTodo">
                    <ng-container *ngTemplateOutlet="secondaryActionsButton">
                    </ng-container>
                  </ng-container>
                </ng-template>
              </omg-form-state>
            </div>
            <div om-layout
                 om-layout-align="end center"
                 om-layout-gutter-large
                 om-layout-fill>
              <ng-container *ngIf="noteHasIncompleteTodo">
                <ng-container *ngTemplateOutlet="secondaryActionsButton">
                </ng-container>
              </ng-container>
              <omg-file-upload-button *ngIf="isAttachable"
                                      om-layout
                                      om-layout-align="space-between center"
                                      (fileChosen)="onAddAttachments($event)">
              </omg-file-upload-button>
              <omg-template-insertion-button *ngIf="showTemplateInsertionButton && !hasSlashInsertionFeature"
                                             om-layout
                                             om-layout-align="space-between center"
                                             [actionBarCollapseRef]="noteActionBarCollapse"
                                             [analyticsEventProperties]="insertTemplateEventProperties">
              </omg-template-insertion-button>
            </div>
            <ng-template #secondaryActionsButton>
              <omg-split-button *ngIf="secondaryActionButtonParams"
                                [items]="secondaryActionButtonParams.items"
                                [label]="(inProgress$ | ngrxPush) ? secondaryActionButtonParams.inProgressLabel : secondaryActionButtonParams.label"
                                [disabled]="(inProgress$ | ngrxPush)"
                                (labelClick)="secondaryActionButtonParams.command()"></omg-split-button>
            </ng-template>
            <div class="action-bar -error"
                 *ngIf="noteHasIncompleteTodo"
                 [hidden]="!(hasTodoError | ngrxPush)">
              {{todoError | ngrxPush}}
            </div>
          </div>
        </omg-collapsed>
      </omg-collapse>
    </omg-card-footer>
  </form>
</div>

<omg-printable-note *ngIf="patient && note"
                    [hasDocuments]="hasDocuments"
                    [patient]="patient"
                    [note]="note">
</omg-printable-note>
