import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { buffer, debounceTime, filter, map } from 'rxjs/operators';

import { AnalyticsActions } from '@app/core';

@Directive({
  selector: '[omgTrackScroll]',
})
export class TrackScrollDirective implements OnInit, OnDestroy {
  private prevScrollPosition = 0;
  private distanceScrolled = new Subject<number>();

  private subscriptions: Subscription[] = [];

  @Input() trackScrollComponent = '';

  constructor(
    private element: ElementRef,
    private analyticsActions: AnalyticsActions,
  ) {}

  ngOnInit(): void {
    // Subscribe to scroll events occurring on any element beneath this node in the DOM
    const scrollEvents = fromEvent(this.element.nativeElement, 'scroll', {
      capture: true,
      // Improves scroll event performance, but we can't call event.preventDefault()
      passive: true,
    });
    this.subscriptions.push(
      scrollEvents.subscribe((event: Event) => {
        const currentScrollPosition = (event.srcElement as HTMLElement)
          .scrollTop;
        const scrollDistance = Math.abs(
          this.prevScrollPosition - currentScrollPosition,
        );
        this.prevScrollPosition = currentScrollPosition;

        this.distanceScrolled.next(scrollDistance);
      }),
    );

    this.subscriptions.push(
      this.distanceScrolled
        .pipe(
          // Batch the scroll events together until no scrolls occur for 1s
          buffer(this.distanceScrolled.pipe(debounceTime(1000))),
          // Total the distances from all batched scroll events
          map(distances => distances.reduce((a, b) => a + b, 0)),
          filter(distance => distance > 0),
        )
        .subscribe(distanceScrolled => {
          this.analyticsActions.trackEvent('Column Scrolled', {
            workflow: 'Charting',
            component: this.trackScrollComponent,
            activeCharting: true,
            pixelDistance: distanceScrolled,
          });
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
