import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { AnalyticsService } from './../shared/analytics.service';

import { AnalyticsEvent, TrackEventProperties } from '../shared/analytics.type';

export const trackEvent = '[Analytics] Track Event';

export class TrackEvent implements Action {
  readonly type = trackEvent;

  constructor(public payload: TrackEventPayload) {}
}

interface TrackEventPayload {
  action: string;
  properties?: Partial<TrackEventProperties>;
}

export type AnalyticsAction = TrackEvent;

@Injectable()
export class AnalyticsActions {
  constructor(private service: AnalyticsService, private store: Store<any>) {}

  trackEvent(
    action: AnalyticsEvent | string,
    properties?: Partial<TrackEventProperties>,
  ) {
    if (this.service.initialized) {
      this.store.dispatch(new TrackEvent({ action, properties }));
    }
  }
}
