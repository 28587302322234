import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { ApiService, patientRoute, PatientSelectors } from '@app/core';
import { catchHttpError422 } from '@app/utils';

import {
  mapEntityToRxCartUpdateData,
  mapEntityToRxCartUpdateErrorResponse,
  mapErxParamsToRequestData,
  mapRxCartResponseToEntity,
} from './rx-cart-api-mappers';
import { RxCartResponse } from './rx-cart-api.type';
import { ErxParams, RxCart } from './rx-cart.type';

interface PasswordResponse {
  password_verified: boolean;
  pusher_channel: string;
}

@Injectable()
export class RxCartApiService {
  constructor(
    private api: ApiService,
    private patientSelectors: PatientSelectors,
  ) {}

  get(patientId: number): Observable<RxCart> {
    return this.api
      .get<RxCartResponse>(patientRoute(patientId, '/rx_carts/current_new'))
      .pipe(map(mapRxCartResponseToEntity));
  }

  update(patientId: number, cartId: number, cart: RxCart): Observable<RxCart> {
    return this.api
      .update<RxCartResponse>(
        patientRoute(patientId, `/rx_carts/${cartId}/update_new`),
        mapEntityToRxCartUpdateData(cart),
      )
      .pipe(
        map(mapRxCartResponseToEntity),
        catchHttpError422(error =>
          throwError(mapEntityToRxCartUpdateErrorResponse(error.error)),
        ),
      );
  }

  refresh(patientId: number, cartId: number): Observable<RxCart> {
    return this.api
      .get<RxCartResponse>(patientRoute(patientId, `/rx_carts/current_new`), {
        id: cartId,
      })
      .pipe(map(mapRxCartResponseToEntity));
  }

  erx(params: ErxParams) {
    return this.patientSelectors.patientId.pipe(
      mergeMap(patientId =>
        this.api.save(
          patientRoute(patientId, '/rx_checkout/pending_new_rxes'),
          mapErxParamsToRequestData(params),
          { params: { ignore401: true } },
        ),
      ),
    );
  }
}
