import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, take, takeUntil } from 'rxjs/operators';

import { FeatureFlagSelectors, PatientSelectors } from '@app/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { getChildGrowthOrdinalPercentiles } from '@app/features/summaries/shared/growth-percentiles-utils';
import {
  bmiPctField,
  htPctField,
  wtPctField,
} from '@app/modules/growth-charts/growth-charts/growth-charts.type';

import { childFieldToConfigMapping } from '../../components/infant-growth-measurements-form/percentile-calculator';
import { ChildGrowthOrdinalPercentiles } from '../../shared/child-growth-percentiles.type';
import { ChildPercentilesComponent } from '../../shared/child-percentiles.component';
import { getBmi } from '../../shared/summaries-utils';
import { getChildBMIPercentile } from '../infant-growth-measurements-form/percentile-calculator/child-body-mass-index-percentile';

@Component({
  selector: 'omg-bmi-measurements-form',
  templateUrl: './bmi-measurements-form.component.html',
  styleUrls: ['./bmi-measurements-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BmiMeasurementsFormComponent extends ChildPercentilesComponent
  implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  gender: string;
  age: number;
  ordinalPercentiles: ChildGrowthOrdinalPercentiles;
  showPercentiles$: Observable<boolean>;
  fieldToConfigMapping = childFieldToConfigMapping;
  unsubscribe: Subject<void> = new Subject();

  constructor(
    public cdr: ChangeDetectorRef,
    public patient: PatientSelectors,
    public featureFlagSelectors: FeatureFlagSelectors,
  ) {
    super(cdr, patient);
  }

  ngOnInit() {
    this.showPercentiles$ = this.getShowPercentiles();

    this.showPercentiles$.subscribe(showPercentiles => {
      if (showPercentiles) {
        this.setupChildPercentiles();
      }
    });

    combineLatest([
      this.form.get('wt').valueChanges.pipe(startWith(this.form.value.wt)),
      this.form.get('ht').valueChanges.pipe(startWith(this.form.value.ht)),
      this.showPercentiles$,
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([wt, ht, showPercentiles]) => {
        const bmi = this.calcBmi(wt, ht);

        this.form.get('BMI').setValue(bmi, {
          emitEvent: false,
          emitModelToViewChange: true,
        });

        if (showPercentiles) {
          this.updatePercentileFormField(
            bmiPctField,
            bmi,
            getChildBMIPercentile,
          );
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private calcBmi(weight: number, height: number): number | null {
    const bmi = Number(getBmi(weight, height).toFixed(1));
    // Checks for if BMI value is non-zero and non-infinite
    return bmi > 0 && Number.isFinite(bmi) ? bmi : null;
  }

  private getShowPercentiles(): Observable<boolean> {
    return combineLatest([
      this.featureFlagSelectors.featureEnabled(
        FeatureFlagNames.kidsAndFamilyTwoToEighteenGrowthPercentiles,
      ),
      this.patient.isMinor,
    ]).pipe(
      takeUntil(this.unsubscribe),
      map(
        ([childGrowthPercentilesEnabled, isMinor]) =>
          childGrowthPercentilesEnabled && isMinor,
      ),
    );
  }

  private setupChildPercentiles() {
    // CDC: Age is listed at the half month point for the entire month; f
    // or example, 1.5 months represents 1.0-1.99 months or 1.0 month up
    // to but not including 2.0 months of age.
    this.patient.ageInHalfMonths.pipe(take(1)).subscribe(ageInHalfMonths => {
      this.age = ageInHalfMonths;
    });

    this.patient.gender.pipe(take(1)).subscribe(gender => {
      this.gender = gender;
    });

    this.listenToFormChanges();

    this.ordinalPercentiles = getChildGrowthOrdinalPercentiles(
      this.form.value[wtPctField],
      this.form.value[htPctField],
      this.form.value[bmiPctField],
    );
  }
}
