import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { SummaryMeasurementsByAbbreviation } from '../../shared/summaries.type';

@Component({
  selector: 'omg-summary-growth-chart',
  templateUrl: './summary-growth-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryGrowthChartComponent implements OnInit {
  @Input() measurements: SummaryMeasurementsByAbbreviation;
  @Input() forInfant: boolean;
  weight: number;
  height: number;
  headCircumference: number;
  BMI: number;

  ngOnInit(): void {
    this.weight = this.measurements['wt_pct'].value;
    this.height = this.measurements['ht_pct'].value;
    this.headCircumference = this.measurements['head_pct']
      ? this.measurements['head_pct'].value
      : null;
    this.BMI = this.measurements['bmi_pct']
      ? this.measurements['bmi_pct'].value
      : null;
  }
}
