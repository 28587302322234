import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'omg-summary-measurements-form',
  templateUrl: './summary-measurements-form.component.html',
})
export class SummaryMeasurementsFormComponent {
  @Input() showInfantVitals: boolean;
  @Input() summaryMeasurementsForm: FormGroup;
}
