import * as uuidv4 from 'uuid/v4';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { isEqual } from './lodash-fp';

/**
 * A typed function to get a key value by property field name
 *
 * @param obj The object to get the prop from
 * @param propName The property/field name
 */
export const getProperty = <T, K extends keyof T>(obj: T, propName: K): T[K] =>
  obj[propName];

/**
 * Maps an array to object keys / values
 *
 * @param selectKey The key selector
 * @param items The array of items
 */
export const mapToObject = <T>(
  selectKey: (i: T) => string | number,
  items: T[],
) => items.reduce((accum, i) => ({ ...accum, [`${selectKey(i)}`]: i }), {});

/**
 * Adds or updates an item in an array
 *
 * @param predicate A function that returns true if the item matches
 * @param list The list to insert or update
 * @param item The item to add or update
 */
export const upsert = <T>(
  predicate: (item: T) => boolean,
  list: T[],
  item: T,
): T[] => {
  const found = list.find(predicate);
  if (found) {
    return [...list.filter(i => !predicate(i)), ...[item]];
  } else {
    return [...list, ...[item]];
  }
};

/**
 * Generates a UUID
 */
export const uuid = (): string => uuidv4();

/**
 * Returns keys of difference between two objects
 */
export const getObjectDiff = <T>(obj1: T, obj2: T) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
};

function inputIsNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

/**
 * Can be used instead of filter(Boolean) to maintain type but similarly filter null or undefined values
 */
export function isNotNullOrUndefined<T>() {
  return (source$: Observable<null | undefined | T>) =>
    source$.pipe(filter(inputIsNotNullOrUndefined));
}

function inputIsTruthy<T>(input: null | undefined | T): input is T {
  return !!input;
}

/**
 * Can be used instead of filter(Boolean) to maintain type
 */
export function isTruthy<T>() {
  return (source$: Observable<null | undefined | T>) =>
    source$.pipe(filter(inputIsTruthy));
}

/**
 * Ascending and Decsending sort order methods for objects with a `createAt` property.
 */
export const acsendingCreatedAtOrder = (a, b) =>
  a.createdAt > b.createdAt ? 1 : -1;
export const decsendingCreatedAtOrder = (a, b) =>
  a.createdAt < b.createdAt ? 1 : -1;

export const arrayLengthIsEqual = (prev: [], curr: []) =>
  prev.length === curr.length;

/**
 * Checks all values of an array for truthiness
 */
export const arrayBooleanCheck = (array: any[]): boolean =>
  array.every(Boolean);

/**
 * Ordinalizes a number 0-100
 */
export const ordinalizePercentile = (n: number): string | null => {
  if (typeof n === 'number' && n >= 0 && n <= 100) {
    const s = ['th', 'st', 'nd', 'rd'];
    return n + (s[(n - 20) % 10] || s[n] || s[0]);
  }
  return null;
};

export const formatSuggestionConfidence = (confidence: number) =>
  `${(confidence * 100).toFixed(0)}% confidence`;
