<div class="-immediate-errors"
     om-layout
     om-layout-gutter-large
     [formGroup]="form"
     *ngIf="(measurementValidationRules$ | ngrxPush) as validationRules">
  <label class="-stacked"
         om-flex="15">
    sys
    <input omgInputNumber
           type="number"
           [min]="validationRules.systolicPressure.min"
           [max]="validationRules.systolicPressure.max"
           step="1"
           name="sys"
           omgSyncInput
           [control]="form.get('sys')"
           [ngClass]="{ 'show-error': !form.get('sys').value && form.get('dia').value }" />
  </label>
  <label class="-stacked"
         om-flex="15">
    dia
    <input omgInputNumber
           type="number"
           [min]="validationRules.diastolicPressure.min"
           [max]="validationRules.diastolicPressure.max"
           step="1"
           name="dia"
           omgSyncInput
           [control]="form.get('dia')"
           [ngClass]="{ 'show-error': !form.get('dia').value && form.get('sys').value }" />
  </label>
  <label class="-stacked"
         om-flex="15">
    HR
    <input omgInputNumber
           type="number"
           [min]="validationRules.heartRate.min"
           [max]="validationRules.heartRate.max"
           step="1"
           name="HR"
           omgSyncInput
           [control]="form.get('HR')" />
  </label>
  <label class="-stacked"
         om-flex="15">
    RR
    <input omgInputNumber
           type="number"
           [min]="validationRules.respiratoryRate.min"
           [max]="validationRules.respiratoryRate.max"
           step="1"
           name="RR"
           omgSyncInput
           [control]="form.get('RR')" />
  </label>
  <label class="-stacked"
         om-flex="15">
    temp
    <label om-layout
           om-layout-align="start center"
           om-layout-gutter-mini>
      <input omgInputNumber
             type="number"
             step="0.1"
             [min]="validationRules.temperature.min"
             [max]="validationRules.temperature.max"
             name="temp"
             omgSyncInput
             [control]="form.get('temp')"
             om-layout-fill />
      <span>°F</span>
    </label>
  </label>
</div>
