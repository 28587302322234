import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { CollapseDirective } from '@app/shared';
import { TemplateInsertionService } from '../../shared/template-insertion.service';
import { Template } from './../../shared/template-insertion.type';

@Component({
  selector: 'omg-template-insertion-action-bar',
  templateUrl: './template-insertion-action-bar.component.html',
  styleUrls: ['./template-insertion-action-bar.component.scss'],
})
export class TemplateInsertionActionBarComponent implements OnInit {
  @Input() collapseRef: CollapseDirective;
  @Input() index: string;

  @Output() apply = new EventEmitter<{ template: Template; done: any }>();

  formGroup: FormGroup;
  template: Template;
  templateInsertionFocusOnKey = 'templateInsertion-apply';
  templateItems: Observable<Template[]>;

  constructor(private templateInsertionService: TemplateInsertionService) {}

  ngOnInit() {
    this.formGroup = new FormGroup({
      messageTemplate: new FormControl(''),
    });
  }

  onApplyTemplate() {
    this.apply.emit({
      template: this.template,
      done: this.appliedDone.bind(this),
    });
  }

  onCancel() {
    this.template = null;
    this.templateItems = of(null);
    this.formGroup.get('messageTemplate').patchValue(null);
    this.collapseRef.collapse();
  }

  onSetTemplate(template: Template) {
    this.template = template;
    this.onApplyTemplate();
  }

  searchForTemplate(text: string) {
    if (text.length > 1) {
      this.templateItems = this.templateInsertionService.searchForTemplate(
        text,
        this.index,
      );
    }
  }

  templateSearchFn(term: string, item: any) {
    // return true so that all elasticsearch autocomplete results are displayed
    return true;
  }

  private appliedDone() {
    this.template = null;
    this.templateItems = of(null);
    this.collapseRef.collapse();
  }
}
