<div class="data-table">
  <omg-section-title [altBackgroundColor]="true">
    <!-- Infant Growth Measurements -->
    <span om-flex="15"
          *ngIf="showInfantVitals">wt</span>
    <span om-flex="15"
          *ngIf="showInfantVitals">ht</span>
    <span om-flex="15"
          *ngIf="showInfantVitals">head</span>

    <span om-flex="10">sys</span>
    <span om-flex="10">dia</span>
    <span om-flex="10">HR</span>
    <span om-flex="10">RR</span>
    <span om-flex="15">temp</span>

    <!-- Adult Measurements -->
    <span om-flex="15"
          *ngIf="!showInfantVitals">wt</span>
    <span om-flex="15"
          *ngIf="!showInfantVitals">ht</span>
    <span om-flex="15"
          *ngIf="!showInfantVitals">BMI</span>
  </omg-section-title>
  <div class="row">
    <!-- Infant Growth Measurements -->
    <span om-flex="15"
          *ngIf="showInfantVitals">
      {{ measurements.inf_wt.value }} {{ measurements.inf_wt.value ? 'kg' : '' }}
    </span>
    <span om-flex="15"
          *ngIf="showInfantVitals">
      {{ measurements.ht.value }} {{ measurements.ht.value ? 'in': '' }}
    </span>
    <span om-flex="15"
          *ngIf="showInfantVitals">
      {{ measurements.head.value }} {{ measurements.head.value ? 'cm': '' }}
    </span>

    <span om-flex="10">{{ measurements.sys.value }}</span>
    <span om-flex="10">{{ measurements.dia.value }}</span>
    <span om-flex="10">{{ measurements.HR.value }}</span>
    <span om-flex="10">{{ measurements.RR.value }}</span>
    <span om-flex="15">{{ measurements.temp.value }} {{ measurements.temp.value ? '°F' : '' }}</span>

    <!-- Adult BMI Measurements -->
    <span om-flex="15"
          *ngIf="!showInfantVitals">
      {{ measurements.wt.value }} {{ measurements.wt.value ? 'lb' : '' }}
    </span>
    <span om-flex="15"
          *ngIf="!showInfantVitals">
      {{ measurements.ht.value }} {{ measurements.ht.value ? 'in': '' }}
    </span>
    <span om-flex="15"
          *ngIf="!showInfantVitals">
      {{ bmi | number: '1.0-1' }}
    </span>
  </div>
</div>
