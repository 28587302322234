import { findKey } from 'lodash';
import { of, pipe, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { GraphQLResponse } from '@app/core/graphql/graphql.service';

interface GraphQLPartialDataResponse {
  success?: boolean;
  errors?: Record<string, any>;
}

export interface ConnectionType<T> {
  nodes: T[];
}

export const mapGraphQLResponse = <T>() =>
  pipe(
    mergeMap((response: GraphQLResponse<T>) => {
      if (response.data) {
        const failedQueryKey = findKey(
          response.data,
          (o: GraphQLPartialDataResponse) =>
            o.success === false ||
            (Array.isArray(o.errors) ? o.errors.length : !!o.errors),
        );
        return failedQueryKey
          ? throwError(response.data[failedQueryKey].errors)
          : of(response.data);
      } else if (response.errors) {
        return throwError(response.errors);
      }

      return of(undefined);
    }),
  );
