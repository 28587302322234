<div class="omg-dialog-container"
     style="width: 560px;"
     data-cy="help-request-modal"
     omgTheme>
  <div omgDialogTitle>
    <h5 class="help-request-confirmation-title">Create HelpOut Request</h5>
    <button omgButton
            variant="none"
            class="om-icon icon-close clickable"
            (click)="close()"></button>
  </div>
  <div omgDialogContent>
    <form [formGroup]="formGroup">
      <div class="padding-normal"
           om-layout="vertical"
           om-layout-gutter>
        <label class="plain-text">What kind of help request is this?
          <omg-dropdown formControlName="helpFlowType"
                        [options]="helpFlowTypes$ | ngrxPush"
                        [required]="true"
                        data-cy="helpflow-type-dropdown"
                        [flex]="true">
          </omg-dropdown>
        </label>
        <p>Your request below will be auto-routed through Slack to find an available VMT provider to assist with this
          patient. <a href="https://sites.google.com/onemedical.com/1life-remote-workflow/remote-care/helpout"
             target="_blank"
             data-cy="help-out-wiki-link">Learn More</a></p>

        <ng-container *ngIf="isLinkedToNote; else notNoteRequest">
          <div class="help-request-preview padding-normal"
               om-layout="vertical"
               om-layout-gutter>
            <ng-container *ngIf="hasRequiredNoteDetails; else missingNoteDetails">
              <p om-layout="vertical"
                 om-layout-gutter><strong>Note Details</strong>
                Chart: {{ requestOptions.basicPatientDetails }}
                <span>Subject: {{requestOptions.summary.subject}}</span>
                <span *ngIf="!!requestOptions.summary.subjective"
                      [innerHTML]="requestOptions.summary.subjective"></span>
              </p>
            </ng-container>
          </div>
        </ng-container>

        <ng-template #notNoteRequest>
          <span class="caption">{{ formGroup.get('details').value | omgRemainingCharacters:maxDetailsLength }}
            characters remaining</span>

          <label class="plain-text">Add some details about this request
            <textarea omgTextarea
                      autosize
                      rows="3"
                      om-layout-fill
                      formControlName="details"
                      [maxlength]="maxDetailsLength"></textarea>
          </label>

          <div>
            Be sure to include:
            <ul>
              <li>The name of the caller (if not the patient), their callback number and who they represent (e.g. Aetna,
                Imaging facility)</li>
              <li>What needs to happen (e.g. “Sign this order” or “report stat lab results”)</li>
            </ul>
          </div>
        </ng-template>
      </div>
    </form>
  </div>
  <div omgDialogActions>
    <button omgButton
            variant="primary"
            data-cy="submit-help-request-button"
            (click)="getVMTHelp()">
      Request Help</button>
  </div>
</div>
<ng-template #missingNoteDetails>
  <p>Please provide details in the Note Title and Subject fields of the note to generate a HelpOut.</p>
</ng-template>
