import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import {
  first,
  map,
  pluck,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import { PatientSelectors, ProfileSelectors } from '@app/core';
import { NotesGuard } from '@app/features/notes/shared/notes.guard';
import { SummariesActions } from '@app/features/summaries/store/summaries.actions';
import {
  buildOfficeContactNumbers,
  buildUserSignature,
} from '@app/modules/note/shared/letter-mapper';
import { UserInfo } from '@app/modules/note/shared/letter.type';
import { Note } from '@app/modules/note/shared/note.type';
import { NoteActions, NoteSelectors } from '@app/modules/note/store';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { TodoSelectors } from '@app/modules/todo/store/todo.selectors';
import { FormModel, FormModelOptions } from '@app/shared';
import { fullAddressDisplay, isTruthy } from '@app/utils';

@Component({
  selector: 'omg-letter-container',
  templateUrl: './letter-container.component.html',
  styleUrls: ['./letter-container.component.scss'],
})
export class LetterContainerComponent implements OnInit {

  letter$: Observable<Note>;
  todo$: Observable<Todo>;
  todoIncomplete$: Observable<Boolean>;
  userInfo$: Observable<UserInfo>;
  loadingPDF$: Observable<Boolean>;
  letterFormModel: FormModel;

  constructor(
    private noteSelectors: NoteSelectors,
    private todoSelectors: TodoSelectors,
    private noteActions: NoteActions,
    private route: ActivatedRoute,
    private patientSelectors: PatientSelectors,
    private profileSelectors: ProfileSelectors,
    private summariesActions: SummariesActions,
    private notesGuard: NotesGuard,
  ) {}

  ngOnInit(): void {
    this.notesGuard
      .canActivate(this.route.snapshot)
      .pipe(
        isTruthy(),
      )
      .subscribe(() => {
        this.initObservables();
        this.initForm();
      });
  }

  closeLetter(): void {
    this.summariesActions.closeWorkspaceItem();
  }

  private initObservables(): void {
    this.letter$ = this
      .route
      .paramMap
      .pipe(
        map((params: ParamMap) => params.get('id')),
        map(Number.parseInt),
        switchMap(id => this.noteSelectors.noteById(id)),
        isTruthy(),
      );
    this.todo$ = this
      .letter$
      .pipe(
        switchMap((letter: Note) => this.todoSelectors.todoById(letter.todoId)),
      );
    this.todoIncomplete$ = this
      .todo$
      .pipe(
        pluck('state'),
        map(state => state && state !== 'finished')
      );
    this.userInfo$ = combineLatest([this.patientSelectors.office, this.profileSelectors.profileInfo])
      .pipe(
        map(([office, profile]) => ({
          officeAddress: fullAddressDisplay(office && office.address, ' ·'),
          officeContactNumbers: buildOfficeContactNumbers(office),
          userSignatureInfo: buildUserSignature(profile),
        })),
      );
    this.loadingPDF$ = this.noteSelectors.loadingPDF;
  }

  private initForm(): void {
    const group = new FormGroup({
      id: new FormControl(),
      subject: new FormControl(),
      body: new FormControl(),
    });
    const options: FormModelOptions = {
      autosaveDelay: 200,
      saveFunction: this.autosave.bind(this),
    };
    this.letterFormModel = new FormModel(group, options);

    // patch the form with the letter
    // letter$ is derived from the store/state which is updated every time the user
    // types into the form (due to autosave continuously updating the letter in the
    // backend and in the store/state). we only want the first emitted value
    // because we only want to patch the form once upon initialization
    this
      .letter$
      .pipe(
        take(1),
      )
      .subscribe(letter => {
        this.letterFormModel.patchValue(letter);
      });
  }

  private autosave(letter: Note): Observable<Note> {
    // when triggered, dispatch an action to persist the note in the backend.
    // return an observable that emits a value once the success | error action arrives.
    // on update success, the returned observable emits the updated note.
    // on update failure, the returned observable throws the error.
    this.noteActions.updateNote(letter);
    return this
      .noteSelectors
      .isPending
      .pipe(
        first(pending => !pending),
        withLatestFrom(this.noteSelectors.error),
        switchMap(([_, error]: [boolean, Error]) => {
          return error ? throwError(error) : of(letter);
        })
      );
  }
}
