<strong class="padding-half-vertical service-ticket-procedures-title"
        omgTheme>Procedures &amp; Services</strong>

<ul om-layout="vertical"
    om-layout-gutter>
  <li *ngFor="let procedure of visitProcedure.serviceTicket.proceduresAndServices"
      class="om-animate"
      om-layout
      om-layout-align="space-between start">
    <p om-flex="80">
      {{ procedure.title }}
      <span *ngIf="procedure.indications"> &mdash; {{ procedure.indications.join(', ') }}</span>
    </p>

    <ul om-layout="vertical"
        om-layout-align="start end"
        om-flex="20">
      <li class="cpt"
          *ngFor="let cpt of formatCptCodes(procedure.cpts)">{{ cpt }}</li>
    </ul>
  </li>
  <li *ngIf="!visitProcedure.serviceTicket.proceduresAndServices.length">
    None recorded
  </li>
  <li class="error"
      *ngFor="let error of errors?.serverErrors">
    {{error}}
  </li>
</ul>
