import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Todo } from '@app/modules/todo/shared/todo.type';
import { CollapseProviderDirective } from '@app/shared/components/collapse';

import { Note } from '../../shared/note.type';

@Component({
  selector: 'omg-note-header',
  templateUrl: './note-header.component.html',
  styleUrls: ['./note-header.component.scss'],
})
export class NoteHeaderComponent {
  @Input() editable: boolean;
  @Input() note: Note;
  @Input() todo: Todo;
  @Input() form: FormGroup;
  @Input() commentsCollapseRef: CollapseProviderDirective;

  @Output() close = new EventEmitter<void>();

  constructor() {}

  onClose() {
    this.close.emit();
  }
}
