<div class="padding-normal-horizontal">
  <span [omgPopoverTriggerFor]="detailsPopover"
        omgPopoverTriggerOn="click"
        *ngIf="renewal.originalRx; else noPopover">
    <button type="button"
            class="om-button -link">
      Requested by {{ renewal.createdByLabel }} - {{ renewal.createdAt | omgDateAgo}}
    </button>
    <omg-popover #detailsPopover
                 placement="right"
                 width="430"
                 title="Original Prescription"
                 data-cy="renewal-info-popover">
      <omg-renewal-item-original-rx [originalRx]="renewal.originalRx"
                                    [requestedByPatient]="requestedByPatient">
      </omg-renewal-item-original-rx>
    </omg-popover>
  </span>
</div>


<ng-template #noPopover>
  <div>
    Requested by {{ renewal.createdByLabel }} - {{ renewal.createdAt | omgDateAgo}}
  </div>
</ng-template>
