import * as LDsdk from 'launchdarkly-js-client-sdk';

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { ConfigService, Profile, ProfileSelectors } from '@app/core';

@Injectable()
export class LaunchDarklyService {
  private readonly ldclient$: BehaviorSubject<LDsdk.LDClient>;

  constructor(
    private configService: ConfigService,
    private profileSelectors: ProfileSelectors,
  ) {
    this.ldclient$ = new BehaviorSubject<any>(null);
  }

  init(): void {
    const config = this.configService.environment.launchdarkly;
    if (config && config.clientSideId) {
      this.profileSelectors.profile
        .pipe(first(profile => (profile ? true : false)))
        .subscribe(profile => {
          this.initLaunchDarkly(profile);
        });
    } else {
      throw new Error(
        'LaunchDarkly client side id is required to initialize the LaunchDarkly sdk. Make sure the environment config contains `launchdarkly.clientSideId`',
      );
    }
  }

  /**
   * Returns an observable that resolves to the feature flag variation value for the current user.
   * The returned observable emits exactly one value so it's unnecessary to
   * call `take(1)` nor `unsubscribe()`.
   *
   * In the client-side JavaScript SDKs, the underlying `ldclient.variation()` method is always a fast,
   * synchronous operation because all of the feature flag values for the current user
   * have already been loaded into memory.
   *
   * @param flag the name of the feature flag
   * @param defaultValue default value emitted by the observable if the feature flag is not defined in launch darkly
   * @returns observable of the feature flag value, or if undefined, the default value
   */
  variation<T>(flag: string, defaultValue: T): Observable<T> {
    return this.ldclient$.pipe(
      first(ldclient => (ldclient ? true : false)),
      map(ldclient => ldclient.variation(flag, defaultValue)),
    );
  }

  private initLaunchDarkly(profile: Profile): void {
    // initializing the launchdarkly sdk is an asynchronous operation.
    // it makes an api call to launchdarkly cloud and once its ready, it emits
    // the 'ready' event indicating methods can be invoked on it.
    // https://docs.launchdarkly.com/sdk/client-side/javascript#code-sample
    const clientSideId = this.configService.environment.launchdarkly
      .clientSideId;
    const user = this.buildUser(profile);
    const ldclient = LDsdk.initialize(clientSideId, user);
    ldclient.on('ready', () => this.ldclient$.next(ldclient));
  }

  private buildUser(profile: Profile): LDsdk.LDUser {
    const email = profile.identities.find(identity => identity.primary).email;
    const user: LDsdk.LDUser = {
      key: profile.id.toString(),
      firstName: profile.firstName,
      lastName: profile.lastName,
      email,
      anonymous: false,
      custom: {
        roles: profile.roles,
      },
    };
    return user;
  }
}
