import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  Directive,
  ElementRef,
  Host,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

// Popular library for autosizing textreas
// https://github.com/jackmoore/autosize
import * as autosize from 'autosize';

/**
 * Directive to automatically resize a textarea to fit its content.
 *
 * @note
 * The `om-input` css class needs to be applied in CSS for the
 * correct line-height to be calculated.
 */
@Directive({
  selector: 'textarea[omgTextareaAutosize], textarea[autosize]',
  exportAs: 'omgTextareaAutosize',
})
export class TextareaAutosizeDirective
  implements
    OnInit,
    OnChanges,
    AfterContentInit,
    AfterViewInit,
    AfterContentChecked,
    OnDestroy {
  // tslint:disable-next-line no-input-rename
  @Input('autosize') autosizeEnabled: boolean;

  private textarea: HTMLTextAreaElement;
  private unsubscribe = new Subject();
  private isAutosizeEnabled = true;
  private isAutosizeInitialized = false;
  private isVisible = false;

  constructor(
    private element: ElementRef,
    private zone: NgZone,
    @Optional() @Host() private control: NgControl,
  ) {
    this.textarea = this.element.nativeElement;
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this.dispose();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.autosizeEnabled &&
      changes.autosizeEnabled.previousValue !==
        changes.autosizeEnabled.currentValue
    ) {
      this.onAutosizeEnabledChange(changes.autosizeEnabled);
    }
  }

  ngAfterContentInit() {
    this.update();
  }

  ngAfterContentChecked() {
    // NOTE: This life-cycle hook is called often so this needs to be inexpensive
    this.checkForVisibleChange();
  }

  ngAfterViewInit() {
    this.update();
  }

  update() {
    if (this.isAutosizeEnabled) {
      this.zone.runOutsideAngular(() => {
        autosize.update(this.textarea);
      });
    }
  }

  private initialize() {
    if (this.isAutosizeEnabled && !this.isAutosizeInitialized) {
      this.listenUntilControlValueSet();

      this.zone.runOutsideAngular(() => {
        autosize(this.textarea);
      });

      this.isAutosizeInitialized = true;
    }
  }

  private dispose() {
    // Dettach the autosize from the textarea
    if (this.isAutosizeInitialized) {
      autosize.destroy(this.textarea);
    }
  }

  private listenUntilControlValueSet() {
    // If this textarea is bound to form then we listen for the first
    // value change and then resize then complete the subscription
    if (this.control) {
      this.control.valueChanges
        .pipe(
          first(),
          takeUntil(this.unsubscribe),
        )
        .subscribe(value => {
          this.update();
        });
    }
  }

  private checkForVisibleChange() {
    // NOTE: These conditions should only trigger when an element is hidden
    // or shown via an *ngIf
    if (!this.isVisible && this.textarea.offsetParent !== null) {
      this.isVisible = true;
      this.update();
    } else if (this.isVisible && this.textarea.offsetParent === null) {
      this.isVisible = false;
    }
  }

  private onAutosizeEnabledChange(autosizeEnabledChange: SimpleChange) {
    const autosizeValue = autosizeEnabledChange.currentValue;

    if (typeof autosizeValue === 'boolean') {
      this.isAutosizeEnabled = autosizeValue;
    } else if (autosizeValue === '' || autosizeValue === null) {
      this.isAutosizeEnabled = true;
    }

    if (this.isAutosizeEnabled) {
      this.initialize();
    } else {
      this.dispose();
    }
  }
}
