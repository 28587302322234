import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ConfigService } from '@app/core/config';
import { windowToken } from '@app/shared/window/window.service';
import { snakeCase } from '@app/utils';

import { CookieService } from './cookie.service';

export interface AccessTokenResponse {
  access_token: string;
  created_at: number;
  scope: string;
  token_type: string;
}

interface AccessTokenRequestParams {
  code: string;
  clientId: string;
  grantType: 'authorization_code';
  redirectUri: string;
  codeVerifier: string;
}

interface AuthCodeRequestParams {
  clientId: string;
  redirectUri: string;
  responseType: 'code';
  codeChallenge: string;
  codeChallengeMethod: 'plain';
}

export const defaultPath = '/schedule';

@Injectable()
export class AuthService {
  private baseUrl = this.config.environment.oauth2.providerUrl;
  private oauth2 = this.config.environment.oauth2;
  private readonly codeVerifierStorageKey = 'auth:verifier';

  constructor(
    private cookie: CookieService,
    private http: HttpClient,
    private config: ConfigService,
    @Inject(windowToken) private window: Window,
    private router: Router,
  ) {}

  get isAuthenticated() {
    return this.cookie.isPresent();
  }

  extractTokenFromUrl(url: string) {
    // e.g.: localhost:4000/#/access_token=dcb13231123&token_type=Bearer
    if (url && url.length > 0) {
      const matches = url.match(/(?<=(access_token=))(.*?)&/);
      const token = (matches && matches.length >= 3 && matches[2]) || '';
      return token;
    }
    return '';
  }

  private setAuthToken(token: string) {
    const secure = !!this.config.environment.production;
    this.cookie.set(token, { secure });
  }

  setAuthTokenFromUrl(url: string) {
    const token = this.extractTokenFromUrl(url);
    this.setAuthToken(token);
  }

  login() {
    this.window.location.href = this.buildLoginUrl();
  }

  buildLoginUrl() {
    const codeChallenge = this.generateCodeChallenge();
    this.window.localStorage.setItem(
      this.codeVerifierStorageKey,
      codeChallenge,
    );

    const url = `${this.baseUrl}/oauth/authorize?`;
    const paramsObject: AuthCodeRequestParams = {
      clientId: this.oauth2.clientId,
      redirectUri: this.window.location.origin,
      responseType: 'code',
      codeChallenge: codeChallenge,
      codeChallengeMethod: 'plain',
    };
    const params = new HttpParams({ fromObject: snakeCase(paramsObject) });
    return url + params.toString();
  }

  logout() {
    const token = this.cookie.get();
    this.cookie.delete();
    return this.http.post(
      `${this.baseUrl}/oauth/revoke`,
      { token },
      { headers: { Authorization: `Bearer: ${token}` } },
    );
  }

  verifyAccessToken(code: string): Observable<string | boolean> {
    const codeVerifier = this.window.localStorage.getItem(
      this.codeVerifierStorageKey,
    );
    this.window.localStorage.removeItem(this.codeVerifierStorageKey);

    const accessTokenRequestParams: AccessTokenRequestParams = {
      code,
      clientId: this.oauth2.clientId,
      grantType: 'authorization_code',
      redirectUri: this.window.location.origin,
      codeVerifier: codeVerifier,
    };
    return this.http
      .post<AccessTokenResponse>(
        `${this.baseUrl}/oauth/token`,
        snakeCase(accessTokenRequestParams),
      )
      .pipe(
        map(response => response.access_token),
        tap(token => this.setAuthToken(<string>token)),
      );
  }

  getRedirectPath() {
    return this.window.sessionStorage.getItem('path') || defaultPath;
  }

  // Copied from https://github.com/onemedical/templates-ui/blob/a6741d7ca71bdf4df82794b41f0867b117dae9c8/src/app/core/auth/shared/auth.service.ts#L90-L112
  private generateCodeChallenge(): string {
    const randomBytes = this.window.crypto.getRandomValues(new Uint8Array(64));
    const randomString = String.fromCharCode(...randomBytes);

    return this.window.btoa(randomString).replace(/[=+/]/g, char => {
      switch (char) {
        case '=':
          return '';
        case '+':
          return '-';
        case '/':
          return '_';
      }
    });
  }
}
