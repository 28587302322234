import { NgModule } from '@angular/core';

import { AuthGuard } from './shared/auth.guard';
import { AuthService } from './shared/auth.service';
import { CookieService } from './shared/cookie.service';
import { LoginGuard } from './shared/login-guard';
import { LogoutGuard } from './shared/logout.guard';

@NgModule({
  providers: [CookieService, AuthService, LoginGuard, LogoutGuard, AuthGuard],
})
export class AuthModule {
  constructor() {}
}
