import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ProfileSelectors } from '@app/core';
import { Prescriber } from '@app/modules/shared-rx/prescriber-credential.type';
import { DropdownItem } from '@app/shared';

import { Renewal } from '../../shared/renewals.type';
import { RenewalSelectors } from '../../store';

@Component({
  selector: 'omg-renewal-item-sign-and-dea',
  templateUrl: './renewal-item-sign-and-dea.component.html',
  styleUrls: ['./renewal-item-sign-and-dea.component.css'],
})
export class RenewalItemSignAndDeaComponent implements OnInit {
  @Input() renewal: Renewal;
  @Input() prescriberId: FormControl;
  @Input() prescribingCredentialId: FormControl;

  sortedValidPrescribers$: Observable<Prescriber[]>;
  isProvider$: Observable<boolean>;
  credentialsLoading$: Observable<boolean>;
  credentialItems$: Observable<DropdownItem[]>;

  constructor(
    private renewalSelectors: RenewalSelectors,
    private profileSelectors: ProfileSelectors,
  ) {}

  ngOnInit() {
    this.isProvider$ = this.profileSelectors.hasRole('provider').pipe(take(1));

    this.credentialsLoading$ = this.renewalSelectors.prescriberCredentialsLoading;
    this.credentialItems$ = this.renewalSelectors.credentialDropdownItemsFor(
      this.renewal.id,
    );
    this.sortedValidPrescribers$ = this.renewalSelectors.sortedValidPrescribers(
      this.renewal.id,
    );
  }
}
