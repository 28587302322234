import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector, select, Store } from '@ngrx/store';

import { Note, NoteFax, NoteFormInfo } from '../shared/note.type';
import { adapter, NoteState, noteStatePath } from './note.reducer';

const { selectIds, selectEntities, selectAll } = adapter.getSelectors();

export const selectNoteState = createFeatureSelector<NoteState>(noteStatePath);

export const selectNoteIds = createSelector(
  selectNoteState,
  selectIds,
);

export const selectNoteEntities = createSelector(
  selectNoteState,
  selectEntities,
);

export const selectAllNotes = createSelector(
  selectNoteState,
  selectAll,
);

export const selectNoteById = createSelector(
  selectNoteState,
  (state: NoteState, { id }) => state.entities[id],
);

export const selectIsPending = createSelector(
  selectNoteState,
  (state: NoteState) => state.pending,
);

export const selectError = createSelector(
  selectNoteState,
  (state: NoteState) => state.error,
);

export const selectIsComposeMessagePending = createSelector(
  selectNoteState,
  (state: NoteState) => state.composeMessagePending,
);

export const selectTransmissionDetails = createSelector(
  selectNoteById,
  (note: Note) =>
    note &&
    note.faxes &&
    note.faxes.map(fax => ({
      ...fax,
      transmissionType: 'fax',
      transmissionTypeDisplay: 'fax',
    })),
);

export const selectNoteFormInfo = createSelector(
  selectNoteById,
  selectTransmissionDetails,
  (note: Note, transmissionDetails: NoteFax[]): NoteFormInfo => ({
    transmissionDetails,
    hasAttachedDocuments:
      !!note && !!note.documents && note.documents.length > 0,
    canComposeMessage: !!note && !!note.documents && note.documents.length > 0,
    isLetter:
      !!note && note.noteType && note.noteType.name === 'Official Letter',
    isBlank: (!!note && !note.body) || (!!note && note.body === '<br>'),
  }),
);

export const selectCurrentNoteId = createSelector(
  selectNoteState,
  (state: NoteState) => state.currentNoteId,
);

export const selectCurrentNote = createSelector(
  selectNoteEntities,
  selectCurrentNoteId,
  (entities, id) => entities[id],
);

export const selectLoadingPDF = createSelector(
  selectNoteState,
  state => state.loadingPDF,
);

@Injectable()
export class NoteSelectors {
  constructor(private store: Store<NoteState>) { }

  get noteIds() {
    return this.store.pipe(select(selectNoteIds));
  }

  get noteEntities() {
    return this.store.pipe(select(selectNoteEntities));
  }

  get notes() {
    return this.store.pipe(select(selectAllNotes));
  }

  get currentNoteId() {
    return this.store.pipe(select(selectCurrentNoteId));
  }

  get currentNote() {
    return this.store.pipe(select(selectCurrentNote));
  }

  noteById(id: number) {
    return this.store.pipe(select(selectNoteById, { id }));
  }

  transmissionDetails(id: number) {
    return this.store.pipe(select(selectTransmissionDetails, { id }));
  }

  noteFormInfo(id: number) {
    return this.store.pipe(select(selectNoteFormInfo, { id }));
  }

  get isPending() {
    return this.store.pipe(select(selectIsPending));
  }

  get error() {
    return this.store.pipe(select(selectError));
  }

  get isComposeMessagePending() {
    return this.store.pipe(select(selectIsComposeMessagePending));
  }

  get loadingPDF() {
    return this.store.pipe(select(selectLoadingPDF));
  }
}
