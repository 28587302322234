import { Component, Input, OnInit } from '@angular/core';

import { Patient } from '@app/core';
import { RxCartMeasurement } from '@app/modules/shared-rx/shared-rx.type';

@Component({
  selector: 'omg-checkout-patient-info',
  templateUrl: './checkout-patient-info.component.html',
})
export class CheckoutPatientInfoComponent implements OnInit {
  @Input() patient: Patient;
  @Input() rxCartMeasurements: RxCartMeasurement[];

  constructor() {}

  ngOnInit() {}
}
