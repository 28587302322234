import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import {
  VisitProcedure,
  VisitProcedureAssessedProblem,
} from '../../shared/visit-procedure.type';

@Component({
  selector: 'omg-service-ticket-assessed-problems',
  templateUrl: './service-ticket-assessed-problems.component.html',
  styleUrls: ['./service-ticket-assessed-problems.component.scss'],
})
export class ServiceTicketAssessedProblemsComponent {
  @Input() visitProcedure: VisitProcedure;
  @Input() primaryProblem: FormControl;
  @Input() disabled = false;

  get canSelectPrimaryProblem(): boolean {
    return (
      this.visitProcedure.requiredParams.includes('chief_complaint') &&
      !this.disabled
    );
  }

  get noProblemsCanBePrimary(): boolean {
    return (
      this.visitProcedure.assessedProblems.length > 0 &&
      this.visitProcedure.assessedProblems.every(
        problem => problem.problemCode.cannotBePrimaryProblemCode === true,
      )
    );
  }

  get noProblems(): boolean {
    return (
      !this.visitProcedure.assessedProblems ||
      !this.visitProcedure.assessedProblems.length
    );
  }

  setPrimaryProblem(problem: VisitProcedureAssessedProblem) {
    if (
      !this.canSelectPrimaryProblem ||
      problem.problemCode.cannotBePrimaryProblemCode
    ) {
      return;
    }

    // Immediately update UI
    this.visitProcedure = {
      ...this.visitProcedure,
      primaryAssessedProblemHistoryId: problem.id,
    };

    this.primaryProblem.setValue(problem.id);
    this.primaryProblem.markAsDirty();
  }
}
