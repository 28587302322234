import { Injectable } from '@angular/core';
import { combineLatest, Observable, zip } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { ApiService } from '@app/core';
import { NotificationsService } from '@app/core/notifications/shared/notifications.service';
import { S3Pointer } from '@app/modules/aws/shared/aws-session.type';
import { createAttachmentKey } from '@app/modules/aws/shared/s3-utils';
import { S3Service } from '@app/modules/aws/shared/s3.service';
import { TodoApiService } from '@app/modules/todo/shared/todo-api.service';

import {
  mapNoteResponseToEntity,
  mapToNoteSaveRequest,
} from './note-api-mappers';
import { NoteResponse, TemplateResponse } from './note-api.type';
import { NoteFaxData } from './note-update.type';
import { getAttachmentTitle, getAwsBucket, getAwsKey } from './note-utils';
import { Note } from './note.type';
import { FilingTemplate } from './recategorize-document.type';

const adminPath = '/v2/admin';

type NoteSubRoute = 'notes' | 'patients';

type NoteSuffix =
  | 'notes'
  | 'fax'
  | 'recategorize'
  | 'clone_letter'
  | 'unfile'
  | 'redact'
  | 'generate_letter';

export const noteRoute = (
  subRoute: NoteSubRoute,
  id: number,
  suffix?: NoteSuffix,
) => {
  let resultRoute = adminPath;

  if (subRoute) {
    resultRoute = `${resultRoute}/${subRoute}`;
  }
  if (id) {
    resultRoute = `${resultRoute}/${id}`;
  }
  if (suffix) {
    resultRoute = `${resultRoute}/${suffix}`;
  }

  return resultRoute;
};

@Injectable()
export class NoteApiService {
  constructor(
    private api: ApiService,
    private todoService: TodoApiService,
    private s3Service: S3Service,
    private notificationsService: NotificationsService,
  ) {}

  save(patientId: number, noteTypeId: number) {
    return this.api
      .save<NoteResponse>(
        noteRoute('patients', patientId, 'notes'),
        mapToNoteSaveRequest(noteTypeId),
      )
      .pipe(map(mapNoteResponseToEntity));
  }

  get(noteId: number) {
    return this.api
      .get<NoteResponse>(noteRoute('notes', noteId))
      .pipe(map(mapNoteResponseToEntity));
  }

  getLetterTemplate(letterId: number, patientId: number) {
    return this.api.get<TemplateResponse>(
      `${adminPath}/letter_templates/${letterId}`,
      {
        patient_id: patientId,
      },
    );
  }

  getNoteWithTodo(noteId: number) {
    return combineLatest(
      this.get(noteId),
      this.todoService.getNoteTodo(noteId),
    );
  }

  update(data: Note) {
    return this.api
      .update<NoteResponse>(noteRoute('notes', data.id), data)
      .pipe(map(mapNoteResponseToEntity));
  }

  delete(noteId: number) {
    return this.api.delete<void>(noteRoute('notes', noteId));
  }

  fax(noteId: number, data: NoteFaxData) {
    return this.api.update<void>(noteRoute('notes', noteId, 'fax'), data);
  }

  attachNoteDocumentsToPost(patientId: number, note: Note) {
    const copiedDocs = note.documents.map(doc => {
      const awsKey = getAwsKey(doc);
      const awsBucket = getAwsBucket(doc);
      const attachmentTitle = getAttachmentTitle(note, doc);
      const attachmentKey = createAttachmentKey(patientId, attachmentTitle);

      return this.s3Service.copy(awsKey, awsBucket, attachmentKey).pipe(
        take(1),
        map(s3Obj => Object.assign({}, s3Obj, { title: attachmentTitle })),
      );
    });

    return zip(...copiedDocs).pipe(
      map(allDocs =>
        allDocs.map(
          s3Obj =>
            ({
              bucket: s3Obj.sourceBucket,
              key: s3Obj.destinationKey,
              title: s3Obj.title,
            } as S3Pointer),
        ),
      ),
    );
  }

  recategorize(noteId: number, template: FilingTemplate) {
    return this.api
      .update<NoteResponse>(noteRoute('notes', noteId, 'recategorize'), {
        filing_template: template,
      })
      .pipe(map(mapNoteResponseToEntity));
  }

  unfile(noteId: number) {
    return this.api.update<void>(noteRoute('notes', noteId, 'unfile'), null);
  }

  cloneLetter(patientId: number, noteId: number) {
    return this.api
      .save<NoteResponse>(noteRoute('notes', noteId, 'clone_letter'), {
        patient_id: patientId,
      })
      .pipe(map(mapNoteResponseToEntity));
  }

  redact(noteId: number) {
    return this.api
      .update<NoteResponse>(noteRoute('notes', noteId, 'redact'), null)
      .pipe(map(mapNoteResponseToEntity));
  }

  generateLetter(letterId: number, patientId: number) {
    return this.api
      .update(noteRoute('notes', letterId, 'generate_letter'), {
        patient_id: patientId,
      })
      .pipe(switchMap(() => this.listenForLetterGenerationEvents(letterId)));
  }

  private listenForLetterGenerationEvents(letterId: number) {
    const pusherChannel = `note-${letterId}`;
    const successEvent = 'generate_letter_done';
    const errorEvent = 'generate_letter_error';

    return Observable.create(obs => {
      const notificationsConnection = this.notificationsService.subscribe(
        pusherChannel,
      );
      notificationsConnection.bind(successEvent, () => {
        obs.next();
        obs.complete();
      });
      notificationsConnection.bind(errorEvent, () => obs.error());
      return () => {
        notificationsConnection.unbind(successEvent);
        notificationsConnection.unbind(errorEvent);
      };
    });
  }
}
