import { Component, Input, OnInit } from '@angular/core';

import { ChangeRxForm } from '../../shared/change-rx-form';

@Component({
  selector: 'omg-change-rx-medication-change-status',
  templateUrl: './change-rx-medication-change-status.component.html',
  styleUrls: ['./change-rx-medication-change-status.component.scss'],
})
export class ChangeRxMedicationChangeStatusComponent {
  @Input() form: ChangeRxForm;
}
