import { Injectable } from '@angular/core';
import * as Pusher from 'pusher-js';

import { ConfigService } from '../../config';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private socket: Pusher.Pusher;

  constructor(private config: ConfigService) {
    this.socket = new Pusher(this.config.environment.pusher.pusher_key, {
      encrypted: this.config.environment.pusher.pusher_encrypted,
    });
  }

  subscribe(channelName: string) {
    if (!this.alreadySubscribed(channelName)) {
      return this.socket.subscribe(channelName);
    }
    return this.socket.channel(channelName);
  }

  private alreadySubscribed(channelName: string): boolean {
    return this.socket
      .allChannels()
      .some(channel => channel.name === channelName);
  }
}
