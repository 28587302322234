import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { HistoricalProcedureCaptureForm } from '../../shared/historical-procedure-capture-form';
import {
  ProcedureCaptureFormValue,
  procedureTypeOptions,
} from '../../shared/historical-procedure-capture.type';
import { HistoricalProcedureCaptureActions } from '../../store/historical-procedure-capture.actions';

@Component({
  selector: 'omg-procedure-capture-edit',
  templateUrl: './procedure-capture-edit.component.html',
  styleUrls: ['./procedure-capture-edit.component.scss'],
})
export class ProcedureCaptureEditComponent implements OnChanges {
  @Input() documentId: number;
  @Input() form: HistoricalProcedureCaptureForm;
  @Input() isCaptureGenerated = false;
  @Input() isCaptureVerified = false;
  @Input() isCaptureLoading = true;
  @Output() collapseForm = new EventEmitter();

  currentDate = new Date();
  procedureTypeOptions = procedureTypeOptions;
  formAttemptedSubmit = false;

  constructor(
    private historicalProcedureCaptureActions: HistoricalProcedureCaptureActions,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isCaptureGenerated) {
      changes.isCaptureGenerated.currentValue
        ? this.form.controls.enable()
        : this.form.controls.disable();
    }
  }

  get mlSuggestionText(): string {
    if (this.isCaptureLoading) {
      return 'Loading...';
    }

    if (!this.isCaptureGenerated) {
      return "1Life is scanning the document to extract data. You'll be able to enter procedure data once it's complete in a few minutes.";
    }

    if (!this.isCaptureVerified) {
      return 'Please review scanned data';
    }
  }

  saveHistoricalProcedureCapture(formValue: ProcedureCaptureFormValue): void {
    if (this.form.controls.valid && formValue.procedureFhirId) {
      this.historicalProcedureCaptureActions.verifyProcedure(
        formValue,
        this.documentId,
      );
    } else {
      this.formAttemptedSubmit = true;
      this.form.controls.markAllAsTouched();
    }
  }

  controlHasError(controlName: string): boolean {
    const control = this.form.controls.get(controlName);
    return control.invalid && this.formAttemptedSubmit;
  }
}
