import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'omg-service-ticket-administrative-comments',
  templateUrl: './service-ticket-administrative-comments.component.html',
})
export class ServiceTicketAdministrativeCommentsComponent {
  constructor() {}

  @Input() control: FormControl;
}
