import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AnalyticsActions } from '@app/core';
import { AnalyticsEvent } from '@app/core/analytics/shared/analytics.type';

import { ProcedureSuggestion } from '../../shared/procedure-suggestion.type';
import { buildMlProcedureBillingSuggestionEventPayload } from '../../shared/procedure-suggestion.utils';
import { ProcedureSuggestionActions } from '../../store/procedure-suggestion.actions';

@Component({
  selector: 'omg-procedure-suggestion-feedback',
  templateUrl: './procedure-suggestion-feedback.component.html',
  styleUrls: ['./procedure-suggestion-feedback.component.scss'],
})
export class ProcedureSuggestionFeedbackComponent {
  @Input() procedureDescription: String;
  @Input() feedbackCategories: [String];
  @Input() procedureSuggestion: ProcedureSuggestion;

  @Output() closeFeedback = new EventEmitter();

  selectedFeedbackCategory: string;
  feedbackText: string;

  constructor(
    public procedureSuggestionActions: ProcedureSuggestionActions,
    public analyticsActions: AnalyticsActions,
  ) {}

  sendFeedback() {
    this.procedureSuggestionActions.sendFeedback(
      this.procedureSuggestion.id,
      this.selectedFeedbackCategory,
      this.feedbackText,
    );
    this.trackFeedbackSent();
    this.closeFeedback.emit();
  }

  skipFeedback() {
    this.trackFeedbackSkipped();
    this.closeFeedback.emit();
  }

  private trackFeedbackSent() {
    this.analyticsActions.trackEvent(
      AnalyticsEvent.MlProcedureBillingSuggestionFeedbackSent,
      buildMlProcedureBillingSuggestionEventPayload(
        this.procedureSuggestion,
        this.feedbackText,
        this.selectedFeedbackCategory,
      ),
    );
  }

  private trackFeedbackSkipped() {
    this.analyticsActions.trackEvent(
      AnalyticsEvent.MlProcedureBillingSuggestionFeedbackSkipped,
      buildMlProcedureBillingSuggestionEventPayload(this.procedureSuggestion),
    );
  }
}
