import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { AnalyticsActions, ProfileSelectors } from '@app/core';
import { AnalyticsEvent } from '@app/core/analytics/shared/analytics.type';
import { Renewal } from '@app/features/renewals/shared/renewals.type';
import * as eventUtils from '@app/modules/shared-rx/utils/regimen-form-event-utils';
import { AutoCompleteComponent } from '@app/shared';

import { MedicationSelectors } from './../../../medications/store/medication.selectors';
import { RegimenForm } from '../../regimen-form';
import { RegimenFormService } from '../../regimen-form.service';
import { StructuredRegimenForm } from '../../structured-regimen-form';

@Component({
  selector: 'omg-regimen-editor',
  templateUrl: './regimen-editor.component.html',
})
export class RegimenEditorComponent implements OnInit, OnDestroy {
  @Input() medication: Renewal;
  @Output() collapse = new EventEmitter<void>();
  @ViewChild('durationDaysInput')
  durationDaysInput: ElementRef;
  @ViewChild('prnDropdown')
  prnDropdown: AutoCompleteComponent;
  form: RegimenForm;
  isProvider: any;
  loadingRoute$: Observable<boolean>;

  private unsubscribe$ = new Subject<void>();
  structuredRegimenForm: StructuredRegimenForm;

  constructor(
    private formService: RegimenFormService,
    private medicationSelectors: MedicationSelectors,
    private profileSelectors: ProfileSelectors,
    private analyticsActions: AnalyticsActions,
  ) {}

  get updateDisabled(): boolean {
    const parentFormInvalid =
      this.form.controls.pristine || this.form.controls.invalid;
    const structuredFormInvalid =
      this.form.controls.get('structuredRegimen').enabled &&
      this.structuredRegimenForm.controls.invalid;

    const autoSaving = this.form.model.saving;
    return (
      parentFormInvalid ||
      structuredFormInvalid ||
      autoSaving ||
      !this.isProvider
    );
  }

  ngOnInit() {
    this.structuredRegimenForm = this.formService.buildStructuredRegimenForm(
      this.medication.medicationRegimen,
    );
    this.form = this.formService.buildForm(
      this.medication,
      this.unsubscribe$,
      this.structuredRegimenForm,
    );
    this.setupSelectors();
    this.setFormListeners();
    this.trackFullPayloadEvent(AnalyticsEvent.RegimenEditorOpened);
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onUpdateRegimen() {
    this.trackFullPayloadEvent(AnalyticsEvent.RegimenUpdated);
    this.form
      .update({
        id: this.medication.id,
        className: this.medication.className,
      })
      .pipe(take(1))
      .subscribe(() => {
        this.trackFullPayloadEvent(AnalyticsEvent.RegimenStateAfterUpdate);
      });
    this.collapse.emit();
  }

  onCancelRegimen() {
    this.trackFullPayloadEvent(AnalyticsEvent.RegimenEditorCanceled);
    this.collapse.emit();
  }

  toggleCustomRegimenEditing() {
    const editRegimen = !this.form.controls.get('isCustomRegimen').value;
    if (editRegimen) {
      this.trackEvent(
        AnalyticsEvent.CustomizeRegimenSelected,
        eventUtils.customRegimenFormEventPayload(this.form),
      );
      this.form.editCustomRegimen();
    } else {
      this.trackEvent(
        AnalyticsEvent.CustomRegimenCanceled,
        eventUtils.customRegimenFormEventPayload(this.form),
      );
      this.form.cancelCustomRegimen();
    }
  }

  toggleStructuredRegimenEditing() {
    this.form.toggleInstructionsText();
  }

  trackDispensedAsClick() {
    this.trackEvent(
      AnalyticsEvent.DispensedAsClicked,
      eventUtils.dispensableRegimenFormEventPayload(this.form),
    );
  }

  trackDispensedAsChange(event) {
    const { id, description } = event;
    this.trackEvent(
      AnalyticsEvent.DispensedAsSelected,
      eventUtils.dispensableRegimenFormEventPayload(this.form, id, description),
    );
  }

  trackFreeTextChangeEvents(change) {
    if (change.type === 'instructionsTextChange') {
      this.trackEvent(
        AnalyticsEvent.FreeTextInstructionsAdded,
        eventUtils.instructionsTextRegimenFormEventPayload(
          change.event.target.value,
        ),
      );
    } else if (change.type === 'useStructuredDataClick') {
      this.trackEvent(
        AnalyticsEvent.UseStructuredDataSelected,
        eventUtils.useStructuredDataRegimenFormEventPayload(this.form),
      );
    }
  }

  trackPrnChangeEvents(change) {
    if (change.type === 'prnIdChange') {
      const { id, desc } = change.event;
      this.trackEvent(
        AnalyticsEvent.PRNReasonSelected,
        eventUtils.prnRegimenFormEventPayload(this.form, id, desc),
      );
    } else if (change.type === 'usePrnClick') {
      this.trackEvent(
        AnalyticsEvent.PRNClicked,
        eventUtils.prnRegimenFormEventPayload(this.form),
      );
    }
  }

  trackStructuredDataChangeEvents(change) {
    if (change.type === 'doseChange') {
      this.trackEvent(
        AnalyticsEvent.QuantityChanged,
        eventUtils.doseRegimenFormEventPayload(change.event.target.value),
      );
    } else if (change.type === 'durationDaysChange') {
      this.trackEvent(
        AnalyticsEvent.DurationAdded,
        eventUtils.durationDaysRegimenFormEventPayload(
          change.event.target.value,
        ),
      );
    } else if (change.type === 'specifyDurationClick') {
      this.trackEvent(
        AnalyticsEvent.SpecifyDurationSelected,
        eventUtils.durationDaysRegimenFormEventPayload(
          this.form.controls.get('durationDays').value,
        ),
      );
    } else if (change.type === 'frequencyIntervalIdChange') {
      const { id, textShort } = change.event;
      this.trackEvent(
        AnalyticsEvent.FrequencyChanged,
        eventUtils.frequencyIntervalRegimenFormEventPayload(
          this.form,
          id,
          textShort,
        ),
      );
    } else if (change.type === 'useFreeTextClick') {
      this.trackEvent(
        AnalyticsEvent.UseFreeTextSelected,
        eventUtils.useStructuredDataRegimenFormEventPayload(this.form),
      );
    }
  }

  trackFullPayloadEvent(analyticsEvent: AnalyticsEvent) {
    const payload = eventUtils.fullRegimenFormEventPayload(this.form);
    this.trackEvent(analyticsEvent, payload);
  }

  trackEvent(analyticsEvent: AnalyticsEvent, payload: Object = {}) {
    this.analyticsActions.trackEvent(analyticsEvent, {
      ...eventUtils.defaultRegimenFormEventPayload(this.form, this.medication),
      ...payload,
    });
  }

  setFormListeners() {
    this.form.controls
      .get('medicationRegimenId')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.trackEvent(AnalyticsEvent.ExistingRegimenSelected);
      });
  }

  private setupSelectors() {
    this.loadingRoute$ = this.medicationSelectors
      .medicationMetadata(this.medication.medicationRoute.id)
      .pipe(map(m => m.pending));
    this.profileSelectors
      .hasRole('provider')
      .pipe(take(1))
      .subscribe(isProvider => (this.isProvider = isProvider));
  }
}
