import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map, take } from 'rxjs/operators';

import { AuthService } from '@app/core/auth/shared/auth.service';

import { loginPath } from './auth-constants';

@Injectable()
export class LogoutGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate() {
    return this.auth.logout().pipe(
      take(1),
      map(() => this.router.parseUrl(`/${loginPath}`)),
    );
  }
}
