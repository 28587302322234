<div class="padding-normal"
     om-flex
     om-layout="vertical"
     om-layout-gutter>
  <div>
    Rx prescribed by {{medication.cartSource.signedByName}} - {{medication.sentAt | omgDate: '4y'}}
  </div>
  <omg-checkout-list-item-medication-display [medForDisplay]="medication.medForDisplay"
                                             [deaSchedule]="medication.dispensableDeaCode"
                                             [earliestFillDate]="medication.earliestFillDate"
                                             refillText="Refills">
  </omg-checkout-list-item-medication-display>
  <div *ngIf="medication.dispenseAsWritten">Do Not Substitute</div>
  <div *ngIf="!medication.dispenseAsWritten">Substitutions Allowed</div>
  <div class="-wrapped-text"
       *ngIf="medication.notesToPharmacist">
    Note to pharmacist: {{ medication.notesToPharmacist }}
  </div>
  <omg-rx-item-pharmacy [pharmacy]="medication.pharmacy"></omg-rx-item-pharmacy>
  <!-- Error messages -->
  <ng-container *ngIf="(errors$ | ngrxPush) as errors">
    <div class="om-autosave-message om-autosave-error-message">
      <div class="om-messages"
           *ngFor="let error of errors">
        {{ error.join('') }}
      </div>
    </div>
  </ng-container>
</div>
