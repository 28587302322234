import { FocusTrap, FocusTrapFactory } from '@angular/cdk/a11y';
import {
  BasePortalOutlet,
  CdkPortalOutlet,
  ComponentPortal,
  TemplatePortal,
} from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  Inject,
  Optional,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { DialogConfig } from './dialog-config';

const throwContentAlreadyAttached = () => {
  throw Error(
    'Attempting to attach dialog content after content is already attached',
  );
};

// tslint:disable component-class-suffix
// tslint:disable use-host-property-decorator

/**
 * Internal component that wraps user-provided dialog content.
 *
 * @note Much of the dialog is based off the material dialog
 * @see https://github.com/angular/material2/blob/master/src/lib/dialog/dialog-container.ts
 */
@Component({
  selector: 'omg-dialog-container',
  template: ` <ng-template cdkPortalOutlet></ng-template> `,
  encapsulation: ViewEncapsulation.None,
  /**
   * Currently disabled because template references in checkout-dialog were not being updated
   * properly, but they are when changeDetection is disabled. Ideally, the component should be
   * refactored to use observable patterns instead of setting local component variables.
   */
  // changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'om-dialog-container',
    tabindex: '-1',
    'aria-modal': 'true',
    '[attr.id]': '_id',
    '[attr.role]': '_config.role',
    '[attr.aria-labelledby]': '_config.ariaLabel ? null : _ariaLabelledBy',
    '[attr.aria-label]': '_config.ariaLabel',
    '[attr.aria-describedby]': '_config.ariaDescribedBy || null',
  },
})
export class DialogContainer extends BasePortalOutlet {
  /** The portal outlet inside of this container into which the dialog content will be loaded. */
  @ViewChild(CdkPortalOutlet, { static: true })
  private portalOutlet: CdkPortalOutlet;

  /** The class that traps and manages focus within the dialog. */
  private focusTrap: FocusTrap;

  /** Element that was focused before the dialog was opened. Save this to restore upon close. */
  private elementFocusedBeforeDialogWasOpened: HTMLElement | null = null;

  /** ID of the element that should be considered as the dialog's label. */
  _ariaLabelledBy: string | null = null;

  /** ID for the container DOM element. */
  _id: string;

  constructor(
    private elementRef: ElementRef,
    private focusTrapFactory: FocusTrapFactory,
    @Optional() @Inject(DOCUMENT) private _document: any,
    public _config: DialogConfig,
  ) {
    super();
  }

  dispose() {
    this.restoreFocus();
  }

  /**
   * Attach a ComponentPortal as content to this dialog container.
   * @param portal Portal to be attached as the dialog content.
   */
  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    if (this.portalOutlet.hasAttached()) {
      throwContentAlreadyAttached();
    }

    this.savePreviouslyFocusedElement();
    const viewRef = this.portalOutlet.attachComponentPortal(portal);
    this.trapFocus();
    return viewRef;
  }

  /**
   * Attach a TemplatePortal as content to this dialog container.
   * @param portal Portal to be attached as the dialog content.
   */
  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    if (this.portalOutlet.hasAttached()) {
      throwContentAlreadyAttached();
    }

    this.savePreviouslyFocusedElement();
    const viewRef = this.portalOutlet.attachTemplatePortal(portal);
    this.trapFocus();
    return viewRef;
  }

  /** Moves the focus inside the focus trap. */
  private trapFocus() {
    if (!this.focusTrap) {
      this.focusTrap = this.focusTrapFactory.create(
        this.elementRef.nativeElement,
      );
    }

    // If were to attempt to focus immediately, then the content of the dialog would not yet be
    // ready in instances where change detection has to run first. To deal with this, we simply
    // wait for the microtask queue to be empty.
    if (this._config.autoFocus) {
      this.focusTrap.focusInitialElementWhenReady();
    }
  }

  /** Restores focus to the element that was focused before the dialog opened. */
  private restoreFocus() {
    const toFocus = this.elementFocusedBeforeDialogWasOpened;

    // We need the extra check, because IE can set the `activeElement` to null in some cases.
    if (
      this._config.restoreFocus &&
      toFocus &&
      typeof toFocus.focus === 'function'
    ) {
      toFocus.focus();
    }

    if (this.focusTrap) {
      this.focusTrap.destroy();
    }
  }

  private savePreviouslyFocusedElement() {
    if (this._document) {
      this.elementFocusedBeforeDialogWasOpened = this._document
        .activeElement as HTMLElement;

      // Note that there is no focus method when rendering on the server.
      if (this.elementRef.nativeElement.focus) {
        // Move focus onto the dialog immediately in order to prevent the user from accidentally
        // opening multiple dialogs at the same time. Needs to be async, because the element
        // may not be focusable immediately.
        Promise.resolve().then(() => this.elementRef.nativeElement.focus());
      }
    }
  }
}
