<div [formGroup]="form.controls">
  <div *ngIf="options.length === 1">
    <strong>{{options[0].medForDisplay.name}} {{options[0].medForDisplay.dispensableTextDesc}}</strong>
  </div>
  <ng-select [items]="options"
             formControlName="selectedRxChangeRequestOption"
             appendTo="body"
             *ngIf="options.length > 1">
    <ng-template ng-label-tmp
                 let-item="item">
      <div class="line">{{item.medForDisplay.name}} {{item.medForDisplay.dispensableTextDesc}}</div>
    </ng-template>
    <ng-template ng-option-tmp
                 let-item="item">
      <div class="line">{{item.medForDisplay.name}} {{item.medForDisplay.dispensableTextDesc}}</div>
    </ng-template>
  </ng-select>
</div>
