export enum AnalyticsEvent {
  AddMedicationClicked = 'Add Medication Clicked',
  ClinicalTimelineObjectOpened = 'Clinical Timeline Object Opened',
  ClinicalTimelineObjectClosed = 'Clinical Timeline Object Closed',
  DateFieldEdited = 'Date field edited',
  DatePickerButtonPressed = 'Date Picker Button Pressed',
  DrugInteractionDisplayed = 'Drug Interaction Displayed',
  DrugInteractionOverridden = 'Drug Interaction Overridden',
  DrugInteractionWarningExpanded = 'Drug Interaction Warning Expanded',
  FollowUpOrderDeleted = 'Follow Up Order Deleted',
  FollowUpOrderEdited = 'Follow Up Order Edited',
  FollowUpOrderViewed = 'Follow Up Order Viewed',
  GrowthChartsAvailable = 'Growth Charts Available',
  GrowthChartsOpened = 'Growth Charts Tab Selected',
  HealthMaintenanceSectionAdded = 'Health Maintenance Section Added',
  LabsTableFiltered = 'Labs Table Filtered',
  LabsTableOpened = 'Labs Tab Selected',
  LetterCreated = 'Letter Created',
  MedicationAddedToCart = 'Medication Added to Cart',
  MedicationChangesCancelled = 'Medication Changes Cancelled',
  MedicationChangesSaved = 'Medication Changes Saved',
  MedicationFieldChanged = 'Medication Field Changed',
  MessageSent = 'Message Sent',
  MiscNoteCreated = 'Miscellaneous Note Created',
  MiscNoteFinished = 'Miscellaneous Note Finished',
  MLNoteRetitleFeedbackSubmitted = 'ML Note Retitle Feedback Submitted',
  MLNoteRetitleFeedbackDismissed = 'ML Note Retitle Feedback Dismissed',
  NewFollowUpOrderClicked = 'New Follow Up Order Clicked',
  PageViewed = 'Page Viewed',
  PageExited = 'Page Exited',
  PatientMenuClicked = 'Patient Level Menu Clicked',
  PatientTimelinePostCreated = 'Patient Timeline Post Created',
  PerfumeFCP = 'Perfume.js First Contentful Paint',
  PerfumeFID = 'Perfume.js First Input Delay',
  ProblemAdded = 'Problem added',
  ProblemRemoved = 'Problem removed',
  ProgramSuggestionsDisplayed = 'Program Suggestions Displayed',
  ProgramSuggestionsSummaryShown = 'Program Suggestions Summary Shown',
  ProgramSuggestionsClicked = 'Program Suggestions Clicked',
  ReasonFieldEdited = 'Reason field edited',
  SeriesVaccineToggled = 'Series Vaccine Toggled',
  SessionEnded = 'Session Ended',
  SessionStarted = 'Session Started',
  ShiftReminderBannerShown = 'Shift Reminder Banner Shown',
  ShiftLearnMoreLinkClicked = 'Shift Learn More Link Clicked',
  SummaryAdded = 'Summary Added',
  SummarySigned = 'Summary Signed',
  TabEnded = 'Tab Context Ended',
  TabStarted = 'Tab Context Started',
  TemplateInserted = 'Template Inserted',
  TemplateInsertStarted = 'Template Insert Started',
  TemplateInlineInserted = 'Template Inline Inserted',
  TemplateInlineMenuActivated = 'Template Inline Menu Activated',
  TiffAttachedToMessage = 'Tiff attached to message',
  TimelineFilterSelected = 'Timeline Filter Selected',
  LetterSigned = 'Letter Signed',
  LetterGenerationSuccess = 'Letter Generated',
  LetterGenerationError = 'Letter Generation Failed',
  LetterDraftDeleted = 'Letter Draft Deleted',
  VisitCodeSelected = 'Visit Code Selected',
  ProcedureOrderFieldChanged = 'Order Field Changed',
  MlProcedureBillingSuggestionActivated = 'ML Procedure Billing Suggestion Activated',
  MlProcedureBillingSuggestionRecorded = 'ML Procedure Billing Suggestion Recorded',
  MlProcedureBillingSuggestionDismissed = 'ML Procedure Billing Suggestion Dismissed',
  MlProcedureBillingSuggestionSaved = 'ML Procedure Billing Suggestion Saved',
  MlProcedureBillingSuggestionChangesDiscarded = 'ML Procedure Billing Suggestion Changes Discarded',
  MlProcedureBillingSuggestionFeedbackSent = 'ML Procedure Billing Suggestion Feedback Sent',
  MlProcedureBillingSuggestionFeedbackSkipped = 'ML Procedure Billing Suggestion Feedback Skipped',
  ProblemCardOrderSelected = 'Problem Card Order Selected',
  RegimenEditorOpened = 'Regimen Editor Opened',
  RegimenEditorCanceled = 'Regimen Editor Canceled',
  DispensedAsClicked = 'Dispensed As Clicked',
  DispensedAsSelected = 'Dispensed As Selected',
  FreeTextInstructionsAdded = 'Free Text Instructions Added',
  PRNClicked = 'PRN Clicked',
  PRNReasonSelected = 'PRN Reason Selected',
  UseStructuredDataSelected = 'Use Structured Data Selected',
  UseFreeTextSelected = 'Use Free Text Selected',
  QuantityChanged = 'Quantity Changed',
  FrequencyChanged = 'Frequency Changed',
  SpecifyDurationSelected = 'Specify Duration Selected',
  DurationAdded = 'Duration Added',
  RegimenUpdated = 'Regimen Updated',
  RegimenStateAfterUpdate = 'Regimen State After Update',
  ExistingRegimenSelected = 'Existing Regimen Selected',
  CustomRegimenCanceled = 'Custom Regimen Canceled',
  CustomizeRegimenSelected = 'Customize Regimen Selected',
  OrderModalActivated = 'Order Modal Activated',
  OrderModalActivatedAndCompleted = 'Order Modal Activated and Completed',
  OrderModalNeverMind = 'Order Modal Never Mind Clicked',
  GiveFeedbackClicked = 'Give Feedback Clicked',
  ToolTipClicked = 'Tool Tip Clicked',
  MLFeedbackGiven = 'ML Feedback Given',
  PrintClicked = 'Print Clicked',
}

export interface TrackEventProperties {
  patientId: number;
  application: string;
  type: string;
  quantity: number;
  unit: string;
  sessionFingerprint: number;
  userRole: string;
  workflow: string;
  component: string;
  subcomponent: string;
  subworkflowId: number;
  problemType: string;
  problemCode: string;
  summaryId: number;
  noteId: number;
  noteTypeId: number;
  patientTimelinePostId: number;
  letterId: number;
  templateId: number;
  templateName: string;
  templateType: string;
  medicationRouteId: number;
  isOverridden: boolean;
  interactingObjectId: number;
  interactingObjectType: string;
  severityLevel: number;
  isNew: boolean;
  cosignatureRequested: boolean;
  messageDraftType: string;
  duration: number;
  orderType: string;
  orderId: number;
  vaccineName: string;
  vaccineId: number;
  appointmentId: number;
  filterValue: string;
  filterId: number;
  filterType: string;
  inputString: string;
  orderSubtype: string;
  formType: string;
  isHighPriority: boolean;
  isUrgent: string;
  procedureCodeId: number;
  procedureCodeCpt: string;
  procedureCodeCptDescription: string;
  suggestionFeedback: string;
  suggestionId: number;
  rxSourceId: number;
  rxSourceType: string;
  medicationRegimenId: number;
  medicationRegimenTextDesc: string;
  isCustom: boolean;
  dispensableId: number;
  dispensableTextDesc: string;
  instructionsText: string;
  usePrn: boolean;
  prnId: number;
  prnDesc: string;
  useStructuredData: boolean;
  doseLow: string;
  doseHigh: string;
  frequencyIntervalId: number;
  frequencyIntervalTextShort: string;
  durationDays: number;
  objectId: number;
  problemId: number;
  problemTypeId: number;
  activeCharting: boolean;
  description: string;
  healthGoalId: number;
  healthGoalTypeId: number;
  allergiesId: number;
  medicationsId: number;
  medicationsName: string;
  pixelDistance: number;
  method: string;
  feedbackResponse: string;
  measure: string;
  timeFrameDefault: string;
  timeFrameCurrent: string;
  timeFrameSelected: string;
  programName: string | string[];
  programCount: number;
}

export type AnalyticsApiRequestData = any;
