<div *ngIf="note">
  <div om-layout
       om-layout-align="space-between">
    <label om-layout="vertical">Note to {{recipient}}</label>
    <span *ngIf="!note.disabled && maxLength"
          class="counter">
      {{maxLength - note.value?.length}} Characters Remaining
    </span>
  </div>
  <textarea omgTextarea
            om-layout-fill
            [formControl]="note"
            [placeholder]="placeholder"
            [maxlength]="maxLength"
            recipient="patient"
            (click)="setNoteValueIfBlank(placeholder, autofillPlaceholder)"></textarea>
  <span class="om-messages"
        *ngIf="note.invalid && note.touched">
    Cannot be blank
  </span>
</div>
