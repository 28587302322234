import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { loginPath } from '@app/core/auth/shared/auth-constants';
import { AuthService } from '@app/core/auth/shared/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  // TODO: Find better solution for accessing code param.
  // Ideal solution: Disable `hash` routing strategy and access query param using `route.queryParams`.
  canActivate(route: ActivatedRouteSnapshot) {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (this.auth.isAuthenticated) {
      return true;
    } else if (!this.auth.isAuthenticated && !code) {
      this.auth.login();
      return false;
    }

    return code
      ? this.validateTokenOrRedirectToLogin(code)
      : this.router.parseUrl(loginPath);
  }

  validateTokenOrRedirectToLogin(code) {
    return this.auth.verifyAccessToken(code).pipe(
      map(() => this.router.parseUrl(this.auth.getRedirectPath())),
      catchError(() => of(this.router.parseUrl(loginPath))),
    );
  }
}
