import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import {CancelPrescriber, Prescriber} from '../../prescriber-credential.type';

@Component({
  selector: 'omg-signed-on-behalf-of',
  templateUrl: './signed-on-behalf-of.component.html',
  styleUrls: ['./signed-on-behalf-of.component.css'],
})
export class SignedOnBehalfOfComponent implements OnInit {
  @Input() signedOnBehalfOfId: FormControl;
  @Input() validPrescribers: Prescriber[] | CancelPrescriber[];

  constructor() {}

  ngOnInit() {}
}
