import { Directive } from '@angular/core';

import { BaseInput } from '../base-input/base-input';

@Directive({
  selector: 'input[omgInputNumber][type=number]',
  // tslint:disable-next-line use-host-property-decorator
  host: {
    '[class.om-input]': 'true',
  },
})
export class InputNumberDirective extends BaseInput {}
