import { Injectable, Injector, NgZone } from '@angular/core';

import {
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { PortalInjector } from '@angular/cdk/portal';

import { getOriginOverlayPositions } from '../utils/cdk-overlay-utils';
import { applyConfigDefaults, PopoverConfig } from './popover-config';
import { PopoverPortalComponent } from './popover-portal.component';
import { PopoverRef } from './popover-ref';

@Injectable({
  providedIn: 'root',
})
export class PopoverService {
  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private zone: NgZone,
  ) {}

  open<T>(config: PopoverConfig<T>): PopoverRef<T> {
    config = applyConfigDefaults(config);

    const overlayRef = this.overlay.create(this.getOverlayConfig(config));
    const popoverRef = new PopoverRef<T>(overlayRef, config, this.zone);
    const injector = this.createInjector(popoverRef, this.injector);

    popoverRef.attach(PopoverPortalComponent, injector);

    return popoverRef;
  }

  private getOverlayConfig(config: PopoverConfig) {
    return new OverlayConfig({
      hasBackdrop: false,
      width: config.width || config.minWidth,
      height: config.height,
      minWidth: config.minWidth,
      minHeight: config.minHeight,
      maxWidth: config.maxWidth,
      maxHeight: config.maxHeight,
      backdropClass: '',
      panelClass: '',
      positionStrategy: this.getOverlayPosition(config),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  private getOverlayPosition(config: PopoverConfig): PositionStrategy {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(config.origin)
      .withPositions(this.getPositions(config))
      .withFlexibleDimensions(false)
      .withPush(false);

    return positionStrategy;
  }

  private createInjector(popoverRef: PopoverRef, injector: Injector) {
    const tokens = new WeakMap([[PopoverRef, popoverRef]]);
    return new PortalInjector(injector, tokens);
  }

  private getPositions(config: PopoverConfig): ConnectionPositionPair[] {
    return getOriginOverlayPositions(config.placement);
  }
}
