import {
  DenialReason,
  Task,
} from '@app/features/renewals/shared/renewals.type';
import {
  MedicationRegimen,
  PatientMedicationPrescriptionHistoryItem,
} from '@app/modules/medications/shared';
import { MedForDisplay } from '@app/modules/shared-rx/shared-rx.type';

export interface ChangeRx extends ChangeRxUpdateProperties, ChangeRxProperties {
  id: number;
  createdAt: string;
  updatedAt: string;
  originalMedicationPrescription: PatientMedicationPrescriptionHistoryItem;
  task: Task;
}

export interface NUCCProviderSpecialty {
  id: number;
  code: string;
  displayName: string;
}

export enum ChangeRxTypes {
  P = 'Prior Authorization',
  U = 'Prescriber Authorization',
  D = 'DUE Drug Use Evaluation',
  G = 'Generic Substitution',
  S = 'Script Clarification',
  T = 'Therapeutic Interchange/Substitution',
  OS = 'Out of Stock',
}

export enum PrescriberAuthorizationSubcodes {
  A = 'State license',
  B = 'DEA',
  C = 'DEA',
  D = 'State control substance number',
  E = 'State control substance number',
  F = 'NADEA number',
  G = 'NPI',
  H = 'Prescriber enrollment date in prescription benefit plan',
  I = 'Specialty',
  J = 'REMS enrollment date',
  L = 'Supervising provider information',
  M = 'Certificate to prescribe number',
}

type ChangeRxType = keyof typeof ChangeRxTypes;
type PrescriberAuthorizationSubcode = keyof typeof PrescriberAuthorizationSubcodes;

interface ChangeRxProperties {
  changeRequestType: ChangeRxType;
  rxChangeRequest?: {
    approvedFills?: number;
    approvedQuantity?: number;
    selectedRxChangeRequestOption?: RxChangeRequestOption;
    rxChangeRequestOptions: RxChangeRequestOption[];
  };
  rxChangeRequestPriorAuthorization?: RxChangeRequestPriorAuthorization;
  rxChangeRequestPrescriberAuthorizations?: RxChangeRequestPrescriberAuthorization[];
}

interface ChangeRxUpdateProperties {
  password?: string;
  denialReason?: DenialReason;
  rxChangeRequestPriorAuthorizationAttributes?: {
    id: number;
    submitWithoutApproval: boolean;
    approvedValue: string;
  };
  rxChangeRequestPrescriberAuthorizationsAttributes?: {
    id: number;
    value: any;
  }[];
  // Meds needs to be wired up
  // rxChangeRequestAttributes?: {
  //   id?: string;
  //   cartState?: string;
  //   approvedFills?: string;
  //   approvedMedicationPackageSizeId?: string;
  //   dispenseAsWritten?: string;
  //   notesToPharmacist?: string;
  //   earliestFillDate?: string;
  //   approvedQuantity?: string;
  //   medicationRegimenId?: string;
  //   prescriberId?: string;
  //   prescribingCredentialId?: string;
  //   selectedRxChangeRequestOptionId?: string;
  // };
}

export interface RxChangeRequestOption {
  id: number;
  dispenseAsWritten: boolean;
  earliestFillDate: string;
  fills: number;
  preferred?: boolean;
  quantity: number;
  medForDisplay: MedForDisplay;
  medicationRegimen: MedicationRegimen;
}

interface RxChangeRequestPriorAuthorization {
  id: number;
  submitWithoutApproval: boolean;
  approvedValue?: string;
  pharmacyId: number;
}

interface RxChangeRequestPrescriberAuthorization {
  id: number;
  subcode: PrescriberAuthorizationSubcode;
  value?: string;
  pharmacyId: number;
}
