export enum RxCartTypes {
  newRx = 'pending_new_rxes',
  renewal = 'refill',
}

export enum RxPollingEvents {
  complete = '2fa_push_polling_done',
}

export enum RxVerificationDeviceTypes {
  push = 'push',
  code = 'code',
}

export interface RxVerificationDevice {
  id: string;
  name: string;
  type: RxVerificationDeviceTypes;
}

export interface RxVerificationDevicesResponse {
  devices: RxVerificationDevice[];
}

export interface RxVerifyPayload {
  cartId: number;
  cartType: RxCartTypes;
  password: string;
  device: RxVerificationDevice;
  code?: string;
}

export interface RxVerifyResponse {
  passwordVerified: boolean;
  pusherChannel?: string;
  token?: string;
  errors?: any;
}

export interface RxVerificationStatus {
  sending?: boolean;
  polling?: boolean;
  complete?: boolean;
}

export interface RxVerificationErrors {
  invalidPassword?: boolean;
  invalidCode?: boolean;
  pushFailed?: boolean;
  hasUnknownError?: boolean;
  exceptions?: string[];
}

export interface RxCartStatus {
  allReadyToSignItemsConfirmed: boolean;
  allHandwrittenItemsConfirmed: boolean;
  requireTwoFactorCheckout: boolean;
  isPrint?: boolean;
}

export interface RxCartErrors {
  invalidPassword?: string;
  hasUnknownError?: boolean;
  validationError?: string;
}

export interface RxNotificationChannel extends Pusher.Channel {}

export interface RxVerified {
  password: string;
  token: string;
}

export interface RxVerification {
  id: number;
  manualDeviceSelectionRequired: boolean;
  devices?: RxVerificationDevice[];
  notificationChannel?: RxNotificationChannel;
  passwordVerified: boolean;
  token?: string;
  status: RxVerificationStatus;
  errors?: RxVerificationErrors;
}
