import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AuthService } from '@app/core/auth/shared/auth.service';

@Component({
  selector: 'omg-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  constructor(private auth: AuthService) {}

  login() {
    this.auth.login();
  }
}
