import {
  ChangeDetectorRef,
  Directive,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { get, map } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { ordinalizePercentile } from '@app/utils';

import { PatientSelectors } from '../../../core/patient';
import { camelCase } from '../../../utils/inflections';
import {
  GrowthFieldConfig,
  GrowthFieldToConfigMapping,
} from '../components/infant-growth-measurements-form/percentile-calculator';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class ChildPercentilesComponent implements OnChanges {
  abstract form;
  abstract gender: string;
  abstract age: number;
  abstract ordinalPercentiles;
  abstract showPercentiles$: Observable<boolean>;
  abstract fieldToConfigMapping: GrowthFieldToConfigMapping;
  abstract unsubscribe: Subject<void>;

  cdr: ChangeDetectorRef;
  patient: PatientSelectors;
  protected valueChangeDelay = 750;

  protected constructor(cdr: ChangeDetectorRef, patient: PatientSelectors) {
    this.cdr = cdr;
    this.patient = patient;
  }

  // NOTE: A new form is passed down by the UnsignedSummaryComponent every time new data is
  // loaded. Listeners to form control valueChanges do not carry over, and must be re-registered
  // when the form changes. ordinalPercentile must be manually updated with the newly loaded data
  ngOnChanges(changes: SimpleChanges) {
    if (!changes.form.isFirstChange()) {
      this.listenToFormChanges();

      const { currentValue } = changes.form;
      // Clear or update percentile fields from new form control values (set from new API data)
      map(this.fieldToConfigMapping, (percentileConfig: GrowthFieldConfig) => {
        const { percentileFieldName } = percentileConfig;
        const percentile = get(
          currentValue,
          ['value', percentileFieldName],
          null,
        );

        this.setOrdinalPercentile(
          percentileFieldName,
          parseInt(percentile, 10),
        );
      });
    }
  }

  private setOrdinalPercentile(fieldName: string, value: number) {
    const camelizedField = camelCase(fieldName);

    if (camelizedField in this.ordinalPercentiles) {
      this.ordinalPercentiles[camelizedField] = value
        ? ordinalizePercentile(value)
        : null;
    }
  }

  listenToFormChanges() {
    this.showPercentiles$.subscribe(percentileFlagOn => {
      if (percentileFlagOn) {
        map(
          this.fieldToConfigMapping,
          (percentileConfig: GrowthFieldConfig, inputFormName: string) => {
            // do not set up listeners for non-editable fields
            if (!percentileConfig.nonEditableField) {
              this.setupFormListener(percentileConfig, inputFormName);
            }
          },
        );
      } else {
        map(
          Object.values(this.fieldToConfigMapping),
          ({ percentileFieldName }) =>
            this.form.removeControl(percentileFieldName),
        );
      }
    });
  }

  private setupFormListener(
    percentileConfig: GrowthFieldConfig,
    inputField: string,
  ) {
    const { percentileFieldName, percentileFn } = percentileConfig;

    this.form
      .get(inputField)
      .valueChanges.pipe(
        debounceTime(this.valueChangeDelay),
        takeUntil(this.unsubscribe),
      )
      .subscribe(value =>
        this.updatePercentileFormField(
          percentileFieldName,
          value,
          percentileFn,
        ),
      );
  }

  protected updatePercentileFormField(
    fieldName: string,
    value: number,
    getPercentile: Function,
  ) {
    // Clear percentiles if value is falsy
    const percentile: number = value
      ? getPercentile(this.gender, this.age, value)
      : null;
    this.form.controls[fieldName].setValue(percentile);

    const camelizedField = camelCase(fieldName);
    this.ordinalPercentiles[camelizedField] = ordinalizePercentile(percentile);

    this.cdr.detectChanges();
  }
}
