<div class="data-table">
  <omg-section-title [altBackgroundColor]="true">
    <span om-flex="15">wt</span>
    <span om-flex="15">ht</span>
    <span om-flex="15"
          *ngIf="forInfant">head</span>
    <span *ngIf="!forInfant"
          om-flex="15">bmi</span>
  </omg-section-title>
  <div class="row">
    <span om-flex="15">{{ weight | percentile }}</span>
    <span om-flex="15">{{ height | percentile }}</span>
    <span om-flex="15"
          *ngIf="forInfant">{{ headCircumference | percentile }}</span>
    <span *ngIf="!forInfant"
          om-flex="15">{{ BMI | percentile }}</span>
  </div>
</div>
