import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Comment } from '../shared/comments.type';
import { deleteCommentReducer } from './comments.reducer.utils';
import {
  PostCommentsAction,
  PostCommentsActionTypes,
} from './post-comments.actions';

export const postCommentsStatePath = 'postComments';

export interface PostCommentsState extends EntityState<Comment> {
  error: any;
  pending: boolean;
}

export function selectPostCommentsId(comments: Comment): number {
  return comments.id;
}

export const adapter: EntityAdapter<Comment> = createEntityAdapter<Comment>({
  selectId: selectPostCommentsId,
});

export const postCommentsInitialState: PostCommentsState = adapter.getInitialState(
  {
    error: null,
    pending: false,
  },
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export function postCommentsReducer(
  state = postCommentsInitialState,
  action: PostCommentsAction,
): PostCommentsState {
  switch (action.type) {
    case PostCommentsActionTypes.GET_POST_COMMENTS: {
      return { ...state, pending: true };
    }

    case PostCommentsActionTypes.GET_POST_COMMENTS_SUCCESS: {
      state = { ...state, error: null, pending: false };
      return adapter.addAll(action.payload, state);
    }

    case PostCommentsActionTypes.GET_POST_COMMENTS_ERROR: {
      return { ...state, error: action.payload, pending: false };
    }

    case PostCommentsActionTypes.CREATE_POST_COMMENT: {
      return { ...state, error: null, pending: true };
    }

    case PostCommentsActionTypes.CREATE_POST_COMMENT_SUCCESS: {
      return adapter.addOne(action.payload, {
        ...state,
        error: null,
        pending: false,
      });
    }

    case PostCommentsActionTypes.CREATE_POST_COMMENT_ERROR: {
      return { ...state, error: action.payload, pending: false };
    }

    default: {
      return <any>deleteCommentReducer(state, action, adapter);
    }
  }
}
