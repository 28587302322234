import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';

import { DetectResizeService } from '@app/shared/directives/detect-resize/detect-resize.service';

@Component({
  selector: 'omg-card-title',
  templateUrl: './card-title.component.html',
  styleUrls: ['./card-title.component.scss'],
})
export class CardTitleComponent {
  @Input() heading: string;
  @Input() smallFontSize = false;
  @ViewChild('cardTitle', { static: true }) cardTitle: ElementRef;
}
