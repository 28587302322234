import { TrackEventProperties } from '@app/core/analytics/shared/analytics.type';

import { ProcedureSuggestion } from './procedure-suggestion.type';

export const buildMlProcedureBillingSuggestionEventPayload = (
  procedureSuggestion: ProcedureSuggestion,
  inputString?: string,
  suggestionFeedback?: string,
): Partial<TrackEventProperties> => {
  const { procedureType: procedureTypes } = procedureSuggestion;
  const procedureType = procedureTypes[0];
  const procedureCode = procedureType.procedureCodes[0];

  return {
    workflow: 'Charting',
    component: 'Office Visit',
    subcomponent: 'Procedure & Services',
    procedureCodeId: procedureCode.id,
    procedureCodeCpt: procedureCode.code,
    procedureCodeCptDescription: procedureCode.description,
    suggestionId: procedureSuggestion.id,
    inputString,
    suggestionFeedback,
  };
};

// After dismissing a suggestion, denotes how long the feedback component
// will be visible before disappearing. See the effect for the UNSET_DISMISS
// procedure suggestion action.
export const feedbackDelayMs = 5000;
