<div class="om-list"
     om-layout="vertical">
  <omg-section-title>
    Signature
  </omg-section-title>
  <div class="om-item"
       om-layout
       om-layout-gutter-large>
    <omg-checkout-issued-on-and-notify-patient [notifyPatient]="renewalCheckoutForm.controls.get('notifyPatient')"
                                               [disabled]="!this.patient.patientPreferences.acceptsClinicalEmails">
    </omg-checkout-issued-on-and-notify-patient>
  </div>
  <omg-rx-verification [cartType]="cartType"
                       [cartId]="cartId"
                       [cartStatus]="cartStatus"
                       [cartErrors]="cartState"
                       (complete)="onVerificationComplete($event)"
                       (cancel)="onCancel()">
  </omg-rx-verification>
</div>
