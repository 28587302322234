import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { FeatureFlagSelectors } from '@app/core';
import { Renewal } from '@app/features/renewals/shared/renewals.type';
import { RenewalActions, RenewalSelectors } from '@app/features/renewals/store';
import { MedicationRegimen } from '@app/modules/medications/shared';
import {
  MedicationActions,
  MedicationSelectors,
} from '@app/modules/medications/store';
import { ReferenceDataSelectors } from '@app/modules/reference-data/store/reference-data.selectors';
import { FocusService } from '@app/shared/directives/focus/focus.service';

import { RegimenForm } from './regimen-form';
import { StructuredRegimenForm } from './structured-regimen-form';

@Injectable()
export class RegimenFormService {
  constructor(
    private actions: RenewalActions,
    private selectors: RenewalSelectors,
    private referenceDataSelectors: ReferenceDataSelectors,
    private medicationActions: MedicationActions,
    private medicationSelectors: MedicationSelectors,
    private featureFlagSelectors: FeatureFlagSelectors,
    private focusService: FocusService,
  ) {}

  buildForm(
    renewal: Renewal,
    unsubscribe: Subject<void>,
    structuredRegimenForm: StructuredRegimenForm,
  ) {
    return new RegimenForm(
      this.actions,
      this.selectors,
      this.referenceDataSelectors,
      this.medicationActions,
      this.medicationSelectors,
      this.featureFlagSelectors,
      renewal.medicationRoute,
      renewal.medicationRegimen,
      unsubscribe,
      structuredRegimenForm,
    );
  }

  buildStructuredRegimenForm(regimen: MedicationRegimen) {
    return new StructuredRegimenForm(regimen, this.focusService);
  }
}
