import { Injectable } from '@angular/core';

import { FsaAction } from '@app/core/store';
import { StateActions } from '@app/core/store/shared/state';
import { ChangeRx } from '@app/features/change-rx/shared/change-rx.type';

import { ChangeRxSubmitAction } from './change-rx.reducer';

export const changeRxSubmitActionType = '[ChangeRx] Change Rx Submit';
export const changeRxSubmitSuccessActionType =
  '[ChangeRx] Change Rx Submit Success';
export const changeRxSubmitErrorActionType =
  '[ChangeRx] Change Rx Submit Error';
export const changeRxSubmitClearActionType =
  '[ChangeRx] Change Rx Submit Clear';
export class ChangeRxSubmit implements FsaAction<ChangeRx> {
  type = changeRxSubmitActionType;

  constructor(public changeRxSubmitAction: ChangeRxSubmitAction) {}
}

export class ChangeRxSubmitSuccess implements FsaAction<ChangeRx> {
  type = changeRxSubmitSuccessActionType;

  constructor(public changeRxSubmitAction: ChangeRxSubmitAction) {}
}

export class ChangeRxSubmitError implements FsaAction<ChangeRx> {
  type = changeRxSubmitErrorActionType;

  constructor(
    public error: any,
    public changeRxSubmitAction: ChangeRxSubmitAction,
  ) {}
}

export class ChangeRxSubmitClear implements FsaAction<void> {
  type = changeRxSubmitClearActionType;
}

export type ChangeRxSubmitActionTypes =
  | ChangeRxSubmit
  | ChangeRxSubmitSuccess
  | ChangeRxSubmitError
  | ChangeRxSubmitClear;

@Injectable()
export class ChangeRxActions extends StateActions<ChangeRx> {
  submit(changeRxSubmitAction: ChangeRxSubmitAction) {
    this.store.dispatch(new ChangeRxSubmit(changeRxSubmitAction));
  }

  clearChangeRxSuccess() {
    this.store.dispatch(new ChangeRxSubmitClear());
  }
}
