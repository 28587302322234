import { DropdownItem } from '@app/shared';

type ProcedureCaptureStatus = 'completed' | 'in-progress';

interface ProcedureCoding {
  system: string;
  code: string;
  display: string;
}

export interface Procedure {
  id: string;
  resourceType: string;
  status: ProcedureCaptureStatus;
  performedDateTime: string;
  code: {
    coding: ProcedureCoding[];
  };
  note: {
    text: string;
  };
}

export interface ProcedureCapture extends Procedure {
  documentId?: number;
  isFormExpanded?: boolean;
}

export interface ProcedureCaptureFormValue {
  procedureFhirId: string;
  procedureCode: string;
  procedureDate: Date;
  procedureResult: string;
}

export const procedureTypeOptions: DropdownItem[] = [
  {
    label: 'Screening mammography',
    value: '24623002',
  },
  {
    label: 'Screening colonoscopy ',
    value: '444783004',
  },
  {
    label: 'Diagnostic mammography',
    value: '241055006',
  },
  {
    label: 'Diagnostic colonoscopy ',
    value: '73761001',
  },
  {
    label: 'Biopsy/Pathology: Cervix ',
    value: '176786003',
  },
];
