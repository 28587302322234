import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { ProfileSelectors } from '@app/core';

import { ChangeRxForm } from '../../shared/change-rx-form';

@Component({
  selector: 'omg-change-rx-prior-auth-status',
  templateUrl: './change-rx-prior-auth-status.component.html',
  styleUrls: ['./change-rx-prior-auth-status.component.css'],
})
export class ChangeRxPriorAuthStatusComponent {
  @Input() form: ChangeRxForm;
}
