import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiService, patientRoute } from '@app/core';

import { mapMedicationRegimenToMedication } from './medications-api-mappers';
import { MedicationsApiService } from './medications-api.service';
import { MedicationRegimenForm } from './medications.type';
import {
  mapFormToSavePatientMedicationPayload,
  mapFormToSavePatientMedicationRegimenPayload,
  mapPatienMedicationsResponseToEntities,
  mapPatientMedicationRegimensToEntities,
  mapPatientMedicationRegimenToEntity,
  mapPatientMedicationResponseToEntity,
  mapPrescriptionHistoryResponseToEntities,
} from './patient-medications-api-mappers';
import {
  PatientMedicationPrescriptionHistoryItemResponse,
  PatientMedicationRegimenResponse,
  PatientMedicationResponse,
} from './patient-medications-api.type';
import {
  PatientMedication,
  PatientMedicationForm,
  PatientMedicationId,
  PatientMedicationRegimen,
} from './patient-medications.type';

export const patientMedicationsAdminRoute = (subRoute: string = ''): string => {
  const patientMedicationsPath = '/v2/admin/patient_medications';
  return `${patientMedicationsPath}${subRoute}`;
};

export const patientMedicationsRoute = (patientId: number): string => {
  const patientMedicationsPath = '/patient_medications';
  return patientRoute(patientId, patientMedicationsPath);
};

export const patientMedicationByIdRoute = (medicationId: number): string =>
  patientMedicationsAdminRoute(`/${medicationId}`);

export const patientMedicationRegimensRoute = (
  subRoute: string = '',
): string => {
  const patientMedicationRegimensPath = '/v2/admin/patient_medication_regimens';
  return `${patientMedicationRegimensPath}${subRoute}`;
};

export const patientMedicationRegimensByIdRoute = (
  medicationId: number,
): string =>
  `${patientMedicationByIdRoute(medicationId)}/patient_medication_regimens`;

export const patientMedicationsRxHistoryRoute = (
  medicationId: number,
): string => `${patientMedicationByIdRoute(medicationId)}/rx_history`;

@Injectable({
  providedIn: 'root',
})
export class PatientMedicationsApiService {
  constructor(
    private api: ApiService,
    private medicationService: MedicationsApiService,
  ) {}

  getAll(patientId: number): Observable<PatientMedication[]> {
    return this.api
      .get<PatientMedicationResponse[]>(patientMedicationsRoute(patientId))
      .pipe(map(mapPatienMedicationsResponseToEntities));
  }

  getPrescriptionHistory(medicationId: number) {
    return this.api
      .get<PatientMedicationPrescriptionHistoryItemResponse[]>(
        patientMedicationsRxHistoryRoute(medicationId),
      )
      .pipe(
        map(response => mapPrescriptionHistoryResponseToEntities(response)),
      );
  }

  getPatientMedicationRegimenHistory(medicationId: number) {
    return this.api
      .get<PatientMedicationRegimenResponse[]>(
        patientMedicationRegimensByIdRoute(medicationId),
      )
      .pipe(map(response => mapPatientMedicationRegimensToEntities(response)));
  }

  add(data: PatientMedicationForm): Observable<PatientMedication> {
    return this.api
      .save<PatientMedicationResponse>(
        patientMedicationsAdminRoute('/create_with_associations'),
        mapFormToSavePatientMedicationPayload(data),
      )
      .pipe(map(mapPatientMedicationResponseToEntity));
  }

  update(data: PatientMedicationForm): Observable<PatientMedicationRegimen> {
    // If not custom then only save the patient_medication_regimen
    if (!data.isCustomRegimen) {
      return this.savePatientMedicationRegimen(data);
    }

    // Save medication_regimen first, then patient_medication_regimen
    return this.medicationService
      .saveMedicationRegimen(data as MedicationRegimenForm)
      .pipe(
        switchMap(result => {
          const values = mapMedicationRegimenToMedication(result);

          return this.savePatientMedicationRegimen({
            ...data,
            ...values,
          });
        }),
      );
  }

  delete(medicationId: number): Observable<PatientMedication> {
    return this.api
      .update<PatientMedicationResponse>(
        patientMedicationByIdRoute(medicationId),
        {
          id: medicationId,
          is_deleted: true,
        },
      )
      .pipe(map(mapPatientMedicationResponseToEntity));
  }

  activate(
    medicationId: PatientMedicationId,
    isActive: boolean = false,
  ): Observable<PatientMedication> {
    return this.api
      .update<PatientMedicationResponse>(
        patientMedicationByIdRoute(medicationId),
        {
          id: medicationId,
          is_active: isActive,
        },
      )
      .pipe(map(mapPatientMedicationResponseToEntity));
  }

  private savePatientMedicationRegimen(
    data: PatientMedicationForm,
  ): Observable<PatientMedicationRegimen> {
    return this.api
      .save<PatientMedicationRegimenResponse>(
        patientMedicationRegimensRoute(),
        mapFormToSavePatientMedicationRegimenPayload(data),
      )
      .pipe(map(mapPatientMedicationRegimenToEntity));
  }
}
