import { zScoreFromMeasurement } from '../../../shared/z-score-to-percentile';
import { LMSParamsMap } from '../lms-params.type';

const POUND_PER_KG = 2.205;
// Data comes from this file https://www.cdc.gov/growthcharts/data/zscore/wtage.csv
const maleChildWeightLMSParams: LMSParamsMap = {
  24: { lambda: -0.20615245, mu: 12.6707633, sigma: 0.108125811 },
  24.5: { lambda: -0.216501213, mu: 12.74154396, sigma: 0.108166006 },
  25.5: { lambda: -0.239790488, mu: 12.88102276, sigma: 0.108274706 },
  26.5: { lambda: -0.266315853, mu: 13.01842382, sigma: 0.108421025 },
  27.5: { lambda: -0.295754969, mu: 13.1544966, sigma: 0.10860477 },
  28.5: { lambda: -0.327729368, mu: 13.28989667, sigma: 0.108825681 },
  29.5: { lambda: -0.361817468, mu: 13.42519408, sigma: 0.109083424 },
  30.5: { lambda: -0.397568087, mu: 13.56088113, sigma: 0.109377581 },
  31.5: { lambda: -0.434520252, mu: 13.69737858, sigma: 0.109707646 },
  32.5: { lambda: -0.472188756, mu: 13.83504622, sigma: 0.110073084 },
  33.5: { lambda: -0.510116627, mu: 13.97418299, sigma: 0.110473254 },
  34.5: { lambda: -0.547885579, mu: 14.1150324, sigma: 0.1109074 },
  35.5: { lambda: -0.58507011, mu: 14.25779618, sigma: 0.111374787 },
  36.5: { lambda: -0.621319726, mu: 14.40262749, sigma: 0.111874514 },
  37.5: { lambda: -0.656295986, mu: 14.54964614, sigma: 0.112405687 },
  38.5: { lambda: -0.689735029, mu: 14.69893326, sigma: 0.112967254 },
  39.5: { lambda: -0.721410388, mu: 14.85054151, sigma: 0.11355811 },
  40.5: { lambda: -0.751175223, mu: 15.00449143, sigma: 0.114176956 },
  41.5: { lambda: -0.778904279, mu: 15.16078454, sigma: 0.114822482 },
  42.5: { lambda: -0.804515498, mu: 15.31940246, sigma: 0.115493292 },
  43.5: { lambda: -0.828003255, mu: 15.48030313, sigma: 0.116187777 },
  44.5: { lambda: -0.849380372, mu: 15.64343309, sigma: 0.116904306 },
  45.5: { lambda: -0.86869965, mu: 15.80872535, sigma: 0.117641148 },
  46.5: { lambda: -0.886033992, mu: 15.97610456, sigma: 0.118396541 },
  47.5: { lambda: -0.901507878, mu: 16.14548194, sigma: 0.119168555 },
  48.5: { lambda: -0.915241589, mu: 16.31676727, sigma: 0.11995532 },
  49.5: { lambda: -0.927377772, mu: 16.4898646, sigma: 0.120754916 },
  50.5: { lambda: -0.938069819, mu: 16.66467529, sigma: 0.121565421 },
  51.5: { lambda: -0.94747794, mu: 16.84109948, sigma: 0.122384927 },
  52.5: { lambda: -0.955765694, mu: 17.01903746, sigma: 0.123211562 },
  53.5: { lambda: -0.963096972, mu: 17.1983908, sigma: 0.124043503 },
  54.5: { lambda: -0.969633434, mu: 17.37906341, sigma: 0.124878992 },
  55.5: { lambda: -0.975532355, mu: 17.56096245, sigma: 0.125716348 },
  56.5: { lambda: -0.980937915, mu: 17.74400082, sigma: 0.126554022 },
  57.5: { lambda: -0.986006518, mu: 17.92809121, sigma: 0.127390453 },
  58.5: { lambda: -0.99086694, mu: 18.11315625, sigma: 0.128224294 },
  59.5: { lambda: -0.995644402, mu: 18.29912286, sigma: 0.129054277 },
  60.5: { lambda: -1.000453886, mu: 18.48592413, sigma: 0.129879257 },
  61.5: { lambda: -1.005399668, mu: 18.67349965, sigma: 0.130698212 },
  62.5: { lambda: -1.010575003, mu: 18.86179576, sigma: 0.131510245 },
  63.5: { lambda: -1.016061941, mu: 19.05076579, sigma: 0.132314586 },
  64.5: { lambda: -1.021931241, mu: 19.24037019, sigma: 0.133110593 },
  65.5: { lambda: -1.028242376, mu: 19.43057662, sigma: 0.133897752 },
  66.5: { lambda: -1.035043608, mu: 19.62136007, sigma: 0.134675673 },
  67.5: { lambda: -1.042372125, mu: 19.8127028, sigma: 0.13544409 },
  68.5: { lambda: -1.050254232, mu: 20.0045944, sigma: 0.13620286 },
  69.5: { lambda: -1.058705595, mu: 20.19703171, sigma: 0.136951959 },
  70.5: { lambda: -1.067731529, mu: 20.39001872, sigma: 0.137691478 },
  71.5: { lambda: -1.077321193, mu: 20.58356862, sigma: 0.138421673 },
  72.5: { lambda: -1.087471249, mu: 20.77769565, sigma: 0.139142773 },
  73.5: { lambda: -1.098152984, mu: 20.97242631, sigma: 0.139855242 },
  74.5: { lambda: -1.10933408, mu: 21.16779192, sigma: 0.140559605 },
  75.5: { lambda: -1.120974043, mu: 21.36383013, sigma: 0.141256489 },
  76.5: { lambda: -1.133024799, mu: 21.56058467, sigma: 0.141946613 },
  77.5: { lambda: -1.145431351, mu: 21.75810506, sigma: 0.142630785 },
  78.5: { lambda: -1.158132499, mu: 21.95644627, sigma: 0.143309898 },
  79.5: { lambda: -1.171061612, mu: 22.15566842, sigma: 0.143984924 },
  80.5: { lambda: -1.184141975, mu: 22.35583862, sigma: 0.144656953 },
  81.5: { lambda: -1.197307185, mu: 22.55702268, sigma: 0.145327009 },
  82.5: { lambda: -1.210475099, mu: 22.75929558, sigma: 0.145996289 },
  83.5: { lambda: -1.223565263, mu: 22.9627344, sigma: 0.146666 },
  84.5: { lambda: -1.236497304, mu: 23.16741888, sigma: 0.147337375 },
  85.5: { lambda: -1.249186293, mu: 23.37343341, sigma: 0.148011715 },
  86.5: { lambda: -1.261555446, mu: 23.58086145, sigma: 0.148690256 },
  87.5: { lambda: -1.273523619, mu: 23.78979096, sigma: 0.149374297 },
  88.5: { lambda: -1.285013783, mu: 24.00031064, sigma: 0.150065107 },
  89.5: { lambda: -1.295952066, mu: 24.21251028, sigma: 0.150763933 },
  90.5: { lambda: -1.306268473, mu: 24.42648043, sigma: 0.151471982 },
  91.5: { lambda: -1.31589753, mu: 24.642312, sigma: 0.152190413 },
  92.5: { lambda: -1.324778843, mu: 24.86009596, sigma: 0.152920322 },
  93.5: { lambda: -1.332857581, mu: 25.07992303, sigma: 0.153662731 },
  94.5: { lambda: -1.340080195, mu: 25.30188584, sigma: 0.154418635 },
  95.5: { lambda: -1.346412105, mu: 25.52606977, sigma: 0.155188768 },
  96.5: { lambda: -1.351813296, mu: 25.75256528, sigma: 0.155973912 },
  97.5: { lambda: -1.356253969, mu: 25.9814599, sigma: 0.156774684 },
  98.5: { lambda: -1.359710858, mu: 26.2128399, sigma: 0.157591579 },
  99.5: { lambda: -1.362167159, mu: 26.44679027, sigma: 0.158424964 },
  100.5: { lambda: -1.363612378, mu: 26.68339457, sigma: 0.159275071 },
  101.5: { lambda: -1.364042106, mu: 26.92273494, sigma: 0.160141995 },
  102.5: { lambda: -1.363457829, mu: 27.16489199, sigma: 0.161025689 },
  103.5: { lambda: -1.361865669, mu: 27.40994539, sigma: 0.161925976 },
  104.5: { lambda: -1.35928261, mu: 27.65796978, sigma: 0.162842452 },
  105.5: { lambda: -1.355720571, mu: 27.90904433, sigma: 0.163774719 },
  106.5: { lambda: -1.351202536, mu: 28.16324264, sigma: 0.164722138 },
  107.5: { lambda: -1.345754408, mu: 28.42063744, sigma: 0.165683945 },
  108.5: { lambda: -1.339405453, mu: 28.68130005, sigma: 0.166659247 },
  109.5: { lambda: -1.332188093, mu: 28.94530029, sigma: 0.167647017 },
  110.5: { lambda: -1.324137479, mu: 29.21270645, sigma: 0.168646104 },
  111.5: { lambda: -1.315291073, mu: 29.48358527, sigma: 0.169655235 },
  112.5: { lambda: -1.30568824, mu: 29.75800198, sigma: 0.170673022 },
  113.5: { lambda: -1.295369867, mu: 30.03602021, sigma: 0.17169797 },
  114.5: { lambda: -1.284374967, mu: 30.31770417, sigma: 0.17272854 },
  115.5: { lambda: -1.272750864, mu: 30.60311107, sigma: 0.173762961 },
  116.5: { lambda: -1.260539193, mu: 30.89230072, sigma: 0.174799493 },
  117.5: { lambda: -1.247783611, mu: 31.18532984, sigma: 0.175836284 },
  118.5: { lambda: -1.234527763, mu: 31.48225315, sigma: 0.176871417 },
  119.5: { lambda: -1.220815047, mu: 31.78312329, sigma: 0.177902912 },
  120.5: { lambda: -1.206688407, mu: 32.08799062, sigma: 0.17892874 },
  121.5: { lambda: -1.19219015, mu: 32.39690313, sigma: 0.17994683 },
  122.5: { lambda: -1.177361786, mu: 32.7099062, sigma: 0.180955078 },
  123.5: { lambda: -1.162243894, mu: 33.02704244, sigma: 0.181951361 },
  124.5: { lambda: -1.146876007, mu: 33.34835148, sigma: 0.182933537 },
  125.5: { lambda: -1.131296524, mu: 33.67386973, sigma: 0.183899465 },
  126.5: { lambda: -1.115542634, mu: 34.00363017, sigma: 0.184847006 },
  127.5: { lambda: -1.099650267, mu: 34.33766207, sigma: 0.185774041 },
  128.5: { lambda: -1.083654055, mu: 34.67599076, sigma: 0.18667847 },
  129.5: { lambda: -1.067587314, mu: 35.01863732, sigma: 0.187558229 },
  130.5: { lambda: -1.051482972, mu: 35.36561737, sigma: 0.18841128 },
  131.5: { lambda: -1.035367321, mu: 35.71694723, sigma: 0.189235738 },
  132.5: { lambda: -1.019277299, mu: 36.07262569, sigma: 0.190029545 },
  133.5: { lambda: -1.003235326, mu: 36.43265996, sigma: 0.190790973 },
  134.5: { lambda: -0.987269866, mu: 36.79704392, sigma: 0.191518224 },
  135.5: { lambda: -0.971406609, mu: 37.1657671, sigma: 0.192209619 },
  136.5: { lambda: -0.955670107, mu: 37.53881268, sigma: 0.192863569 },
  137.5: { lambda: -0.940083834, mu: 37.91615721, sigma: 0.193478582 },
  138.5: { lambda: -0.924670244, mu: 38.2977703, sigma: 0.194053274 },
  139.5: { lambda: -0.909450843, mu: 38.6836143, sigma: 0.194586368 },
  140.5: { lambda: -0.894446258, mu: 39.07364401, sigma: 0.195076705 },
  141.5: { lambda: -0.879676305, mu: 39.46780643, sigma: 0.195523246 },
  142.5: { lambda: -0.865160071, mu: 39.86604044, sigma: 0.195925079 },
  143.5: { lambda: -0.850915987, mu: 40.26827652, sigma: 0.196281418 },
  144.5: { lambda: -0.836961905, mu: 40.67443658, sigma: 0.196591612 },
  145.5: { lambda: -0.823315176, mu: 41.08443363, sigma: 0.19685514 },
  146.5: { lambda: -0.809992726, mu: 41.49817164, sigma: 0.19707162 },
  147.5: { lambda: -0.797011132, mu: 41.91554528, sigma: 0.197240806 },
  148.5: { lambda: -0.784386693, mu: 42.33643978, sigma: 0.197362591 },
  149.5: { lambda: -0.772135506, mu: 42.76073078, sigma: 0.197437004 },
  150.5: { lambda: -0.760273528, mu: 43.18828419, sigma: 0.19746421 },
  151.5: { lambda: -0.748815968, mu: 43.61895703, sigma: 0.197444522 },
  152.5: { lambda: -0.737780398, mu: 44.0525931, sigma: 0.197378345 },
  153.5: { lambda: -0.727181568, mu: 44.48903027, sigma: 0.197266263 },
  154.5: { lambda: -0.717035494, mu: 44.92809483, sigma: 0.197108968 },
  155.5: { lambda: -0.707358338, mu: 45.36960315, sigma: 0.196907274 },
  156.5: { lambda: -0.698166437, mu: 45.81336172, sigma: 0.196662115 },
  157.5: { lambda: -0.689476327, mu: 46.25916729, sigma: 0.196374538 },
  158.5: { lambda: -0.68130475, mu: 46.70680701, sigma: 0.196045701 },
  159.5: { lambda: -0.673668658, mu: 47.15605863, sigma: 0.195676862 },
  160.5: { lambda: -0.666585194, mu: 47.60669074, sigma: 0.19526938 },
  161.5: { lambda: -0.660069969, mu: 48.05846572, sigma: 0.19482473 },
  162.5: { lambda: -0.654142602, mu: 48.51113138, sigma: 0.19434441 },
  163.5: { lambda: -0.648819666, mu: 48.96443224, sigma: 0.193830046 },
  164.5: { lambda: -0.644118611, mu: 49.41810374, sigma: 0.193283319 },
  165.5: { lambda: -0.640056805, mu: 49.87187409, sigma: 0.192705974 },
  166.5: { lambda: -0.636651424, mu: 50.32546478, sigma: 0.192099812 },
  167.5: { lambda: -0.633919328, mu: 50.77859121, sigma: 0.191466681 },
  168.5: { lambda: -0.631876912, mu: 51.23096332, sigma: 0.190808471 },
  169.5: { lambda: -0.63053994, mu: 51.68228625, sigma: 0.190127105 },
  170.5: { lambda: -0.629923353, mu: 52.13226113, sigma: 0.18942453 },
  171.5: { lambda: -0.630041066, mu: 52.58058583, sigma: 0.188702714 },
  172.5: { lambda: -0.630905733, mu: 53.02695588, sigma: 0.187963636 },
  173.5: { lambda: -0.632528509, mu: 53.47106525, sigma: 0.187209281 },
  174.5: { lambda: -0.634918779, mu: 53.91260737, sigma: 0.18644163 },
  175.5: { lambda: -0.638083884, mu: 54.35127608, sigma: 0.185662657 },
  176.5: { lambda: -0.642028835, mu: 54.78676659, sigma: 0.184874323 },
  177.5: { lambda: -0.646756013, mu: 55.21877657, sigma: 0.184078567 },
  178.5: { lambda: -0.652262297, mu: 55.64701131, sigma: 0.183277339 },
  179.5: { lambda: -0.658551638, mu: 56.07116407, sigma: 0.182472427 },
  180.5: { lambda: -0.665609025, mu: 56.49095862, sigma: 0.181665781 },
  181.5: { lambda: -0.673425951, mu: 56.90610886, sigma: 0.18085918 },
  182.5: { lambda: -0.681987284, mu: 57.31634059, sigma: 0.180054395 },
  183.5: { lambda: -0.691273614, mu: 57.72138846, sigma: 0.179253153 },
  184.5: { lambda: -0.701261055, mu: 58.12099696, sigma: 0.178457127 },
  185.5: { lambda: -0.711921092, mu: 58.51492143, sigma: 0.177667942 },
  186.5: { lambda: -0.723218488, mu: 58.90293208, sigma: 0.176887192 },
  187.5: { lambda: -0.735121189, mu: 59.28479948, sigma: 0.176116307 },
  188.5: { lambda: -0.747580416, mu: 59.66032626, sigma: 0.175356814 },
  189.5: { lambda: -0.760550666, mu: 60.02931704, sigma: 0.174610071 },
  190.5: { lambda: -0.773984558, mu: 60.39158721, sigma: 0.173877336 },
  191.5: { lambda: -0.787817728, mu: 60.74698785, sigma: 0.173159953 },
  192.5: { lambda: -0.801993069, mu: 61.09536847, sigma: 0.172459052 },
  193.5: { lambda: -0.816446409, mu: 61.43660077, sigma: 0.171775726 },
  194.5: { lambda: -0.831110299, mu: 61.77057372, sigma: 0.171110986 },
  195.5: { lambda: -0.845914498, mu: 62.09719399, sigma: 0.170465756 },
  196.5: { lambda: -0.860786514, mu: 62.41638628, sigma: 0.169840869 },
  197.5: { lambda: -0.875652181, mu: 62.72809362, sigma: 0.169237063 },
  198.5: { lambda: -0.890436283, mu: 63.03227756, sigma: 0.168654971 },
  199.5: { lambda: -0.905063185, mu: 63.32891841, sigma: 0.168095124 },
  200.5: { lambda: -0.91945749, mu: 63.61801537, sigma: 0.16755794 },
  201.5: { lambda: -0.933544683, mu: 63.89958662, sigma: 0.167043722 },
  202.5: { lambda: -0.947251765, mu: 64.17366943, sigma: 0.166552654 },
  203.5: { lambda: -0.960507855, mu: 64.44032016, sigma: 0.166084798 },
  204.5: { lambda: -0.973244762, mu: 64.69961427, sigma: 0.16564009 },
  205.5: { lambda: -0.985397502, mu: 64.95164625, sigma: 0.165218341 },
  206.5: { lambda: -0.996904762, mu: 65.1965295, sigma: 0.164819236 },
  207.5: { lambda: -1.007705555, mu: 65.43440186, sigma: 0.16444238 },
  208.5: { lambda: -1.017756047, mu: 65.66540015, sigma: 0.164087103 },
  209.5: { lambda: -1.027002713, mu: 65.88970117, sigma: 0.163752791 },
  210.5: { lambda: -1.035402243, mu: 66.10749114, sigma: 0.163438661 },
  211.5: { lambda: -1.042916356, mu: 66.31897311, sigma: 0.163143825 },
  212.5: { lambda: -1.049511871, mu: 66.52436618, sigma: 0.162867311 },
  213.5: { lambda: -1.055160732, mu: 66.72390443, sigma: 0.162608072 },
  214.5: { lambda: -1.059840019, mu: 66.91783563, sigma: 0.162365006 },
  215.5: { lambda: -1.063531973, mu: 67.10641956, sigma: 0.162136973 },
  216.5: { lambda: -1.066224038, mu: 67.28992603, sigma: 0.161922819 },
  217.5: { lambda: -1.067908908, mu: 67.46863255, sigma: 0.161721398 },
  218.5: { lambda: -1.068589885, mu: 67.64281378, sigma: 0.16153153 },
  219.5: { lambda: -1.068261146, mu: 67.8127675, sigma: 0.161352313 },
  220.5: { lambda: -1.066933756, mu: 67.97877331, sigma: 0.161182785 },
  221.5: { lambda: -1.064620976, mu: 68.14111022, sigma: 0.161022184 },
  222.5: { lambda: -1.061341755, mu: 68.30004741, sigma: 0.160869943 },
  223.5: { lambda: -1.057116957, mu: 68.4558454, sigma: 0.160725793 },
  224.5: { lambda: -1.051988979, mu: 68.60872174, sigma: 0.160589574 },
  225.5: { lambda: -1.04599033, mu: 68.75889263, sigma: 0.1604617 },
  226.5: { lambda: -1.039168248, mu: 68.90653028, sigma: 0.160342924 },
  227.5: { lambda: -1.031579574, mu: 69.05176427, sigma: 0.160234478 },
  228.5: { lambda: -1.023291946, mu: 69.19467288, sigma: 0.160138158 },
  229.5: { lambda: -1.014385118, mu: 69.33527376, sigma: 0.160056393 },
  230.5: { lambda: -1.004952366, mu: 69.47351373, sigma: 0.159992344 },
  231.5: { lambda: -0.995101924, mu: 69.60925782, sigma: 0.159949989 },
  232.5: { lambda: -0.984958307, mu: 69.74227758, sigma: 0.159934231 },
  233.5: { lambda: -0.974663325, mu: 69.87223885, sigma: 0.159951004 },
  234.5: { lambda: -0.964376555, mu: 69.99868896, sigma: 0.160007394 },
  235.5: { lambda: -0.954274945, mu: 70.12104381, sigma: 0.160111769 },
  236.5: { lambda: -0.944551187, mu: 70.23857482, sigma: 0.160273918 },
  237.5: { lambda: -0.935410427, mu: 70.35039626, sigma: 0.160505203 },
  238.5: { lambda: -0.927059784, mu: 70.45546105, sigma: 0.160818788 },
  239.5: { lambda: -0.919718461, mu: 70.55252127, sigma: 0.161229617 },
  240: { lambda: -0.91648762, mu: 70.59761453, sigma: 0.161476792 },
};

const femaleChildWeightLMSParams: LMSParamsMap = {
  24: { lambda: -0.73533951, mu: 12.05503983, sigma: 0.107399495 },
  24.5: { lambda: -0.75220657, mu: 12.13455523, sigma: 0.107740345 },
  25.5: { lambda: -0.78423366, mu: 12.2910249, sigma: 0.10847701 },
  26.5: { lambda: -0.81409582, mu: 12.44469258, sigma: 0.109280828 },
  27.5: { lambda: -0.841935504, mu: 12.59622335, sigma: 0.110144488 },
  28.5: { lambda: -0.867889398, mu: 12.74620911, sigma: 0.111060815 },
  29.5: { lambda: -0.892102647, mu: 12.89517218, sigma: 0.112022759 },
  30.5: { lambda: -0.914718817, mu: 13.04357164, sigma: 0.113023467 },
  31.5: { lambda: -0.935876584, mu: 13.19180874, sigma: 0.114056328 },
  32.5: { lambda: -0.955723447, mu: 13.34022934, sigma: 0.115114953 },
  33.5: { lambda: -0.974383363, mu: 13.48913319, sigma: 0.116193327 },
  34.5: { lambda: -0.991980756, mu: 13.63877446, sigma: 0.11728575 },
  35.5: { lambda: -1.008640742, mu: 13.78936547, sigma: 0.118386848 },
  36.5: { lambda: -1.024471278, mu: 13.94108332, sigma: 0.119491669 },
  37.5: { lambda: -1.039573604, mu: 14.09407175, sigma: 0.120595658 },
  38.5: { lambda: -1.054039479, mu: 14.24844498, sigma: 0.121694676 },
  39.5: { lambda: -1.067946784, mu: 14.40429169, sigma: 0.12278503 },
  40.5: { lambda: -1.081374153, mu: 14.56167529, sigma: 0.1238634 },
  41.5: { lambda: -1.094381409, mu: 14.72064045, sigma: 0.124926943 },
  42.5: { lambda: -1.107021613, mu: 14.88121352, sigma: 0.125973221 },
  43.5: { lambda: -1.119338692, mu: 15.04340553, sigma: 0.127000212 },
  44.5: { lambda: -1.131367831, mu: 15.20721443, sigma: 0.128006292 },
  45.5: { lambda: -1.143135936, mu: 15.37262729, sigma: 0.128990225 },
  46.5: { lambda: -1.15466215, mu: 15.53962221, sigma: 0.129951143 },
  47.5: { lambda: -1.165958392, mu: 15.70817017, sigma: 0.130888527 },
  48.5: { lambda: -1.177029925, mu: 15.87823668, sigma: 0.131802186 },
  49.5: { lambda: -1.187871001, mu: 16.04978452, sigma: 0.132692269 },
  50.5: { lambda: -1.198484073, mu: 16.2227706, sigma: 0.133559108 },
  51.5: { lambda: -1.208853947, mu: 16.39715363, sigma: 0.134403386 },
  52.5: { lambda: -1.218965087, mu: 16.57289122, sigma: 0.13522599 },
  53.5: { lambda: -1.228798212, mu: 16.74994187, sigma: 0.136028014 },
  54.5: { lambda: -1.238330855, mu: 16.92826587, sigma: 0.136810739 },
  55.5: { lambda: -1.247537914, mu: 17.10782615, sigma: 0.137575606 },
  56.5: { lambda: -1.256392179, mu: 17.28858894, sigma: 0.138324193 },
  57.5: { lambda: -1.264864846, mu: 17.47052444, sigma: 0.139058192 },
  58.5: { lambda: -1.272926011, mu: 17.65360733, sigma: 0.139779387 },
  59.5: { lambda: -1.28054514, mu: 17.83781722, sigma: 0.140489635 },
  60.5: { lambda: -1.287691525, mu: 18.02313904, sigma: 0.141190842 },
  61.5: { lambda: -1.294332076, mu: 18.20956418, sigma: 0.141884974 },
  62.5: { lambda: -1.300441561, mu: 18.3970876, sigma: 0.142573939 },
  63.5: { lambda: -1.305989011, mu: 18.58571243, sigma: 0.143259709 },
  64.5: { lambda: -1.310946941, mu: 18.77544728, sigma: 0.143944216 },
  65.5: { lambda: -1.315289534, mu: 18.966307, sigma: 0.144629359 },
  66.5: { lambda: -1.318992925, mu: 19.15831267, sigma: 0.14531699 },
  67.5: { lambda: -1.322035315, mu: 19.35149163, sigma: 0.146008903 },
  68.5: { lambda: -1.324398133, mu: 19.54587708, sigma: 0.146706813 },
  69.5: { lambda: -1.326064539, mu: 19.74150854, sigma: 0.147412363 },
  70.5: { lambda: -1.327020415, mu: 19.93843145, sigma: 0.148127109 },
  71.5: { lambda: -1.327256387, mu: 20.13669623, sigma: 0.148852482 },
  72.5: { lambda: -1.326763834, mu: 20.33635961, sigma: 0.149589838 },
  73.5: { lambda: -1.325538668, mu: 20.53748298, sigma: 0.1503404 },
  74.5: { lambda: -1.323579654, mu: 20.74013277, sigma: 0.151105277 },
  75.5: { lambda: -1.320888012, mu: 20.94438028, sigma: 0.151885464 },
  76.5: { lambda: -1.317468695, mu: 21.15030093, sigma: 0.152681819 },
  77.5: { lambda: -1.313331446, mu: 21.35797332, sigma: 0.15349505 },
  78.5: { lambda: -1.308487081, mu: 21.56748045, sigma: 0.154325756 },
  79.5: { lambda: -1.302948173, mu: 21.77890902, sigma: 0.155174414 },
  80.5: { lambda: -1.296733913, mu: 21.99234686, sigma: 0.15604132 },
  81.5: { lambda: -1.289863329, mu: 22.20788541, sigma: 0.156926667 },
  82.5: { lambda: -1.282358762, mu: 22.4256177, sigma: 0.157830504 },
  83.5: { lambda: -1.274244931, mu: 22.64563824, sigma: 0.158752743 },
  84.5: { lambda: -1.265548787, mu: 22.86804258, sigma: 0.159693163 },
  85.5: { lambda: -1.256299378, mu: 23.09292679, sigma: 0.16065141 },
  86.5: { lambda: -1.24653066, mu: 23.32038549, sigma: 0.161626956 },
  87.5: { lambda: -1.236266832, mu: 23.55051871, sigma: 0.162619308 },
  88.5: { lambda: -1.225551344, mu: 23.78341652, sigma: 0.1636276 },
  89.5: { lambda: -1.214410914, mu: 24.01917703, sigma: 0.1646511 },
  90.5: { lambda: -1.202884389, mu: 24.25789074, sigma: 0.165688808 },
  91.5: { lambda: -1.191007906, mu: 24.49964778, sigma: 0.166739662 },
  92.5: { lambda: -1.178818621, mu: 24.74453536, sigma: 0.167802495 },
  93.5: { lambda: -1.166354376, mu: 24.99263735, sigma: 0.168876037 },
  94.5: { lambda: -1.153653688, mu: 25.24403371, sigma: 0.169958922 },
  95.5: { lambda: -1.140751404, mu: 25.49880264, sigma: 0.171049756 },
  96.5: { lambda: -1.127684095, mu: 25.7570168, sigma: 0.172147043 },
  97.5: { lambda: -1.114490244, mu: 26.01874261, sigma: 0.173249185 },
  98.5: { lambda: -1.101204848, mu: 26.28404312, sigma: 0.174354569 },
  99.5: { lambda: -1.087863413, mu: 26.55297507, sigma: 0.175461512 },
  100.5: { lambda: -1.074500927, mu: 26.82558904, sigma: 0.176568284 },
  101.5: { lambda: -1.061151213, mu: 27.1019295, sigma: 0.177673124 },
  102.5: { lambda: -1.047847141, mu: 27.38203422, sigma: 0.178774242 },
  103.5: { lambda: -1.034620551, mu: 27.66593402, sigma: 0.179869829 },
  104.5: { lambda: -1.021502197, mu: 27.9536524, sigma: 0.180958063 },
  105.5: { lambda: -1.008521695, mu: 28.24520531, sigma: 0.182037118 },
  106.5: { lambda: -0.995707494, mu: 28.54060085, sigma: 0.183105172 },
  107.5: { lambda: -0.983086844, mu: 28.83983907, sigma: 0.18416041 },
  108.5: { lambda: -0.970685789, mu: 29.14291171, sigma: 0.185201039 },
  109.5: { lambda: -0.958529157, mu: 29.44980208, sigma: 0.186225287 },
  110.5: { lambda: -0.946640568, mu: 29.76048479, sigma: 0.187231416 },
  111.5: { lambda: -0.935042447, mu: 30.0749257, sigma: 0.188217723 },
  112.5: { lambda: -0.923756041, mu: 30.39308176, sigma: 0.18918255 },
  113.5: { lambda: -0.912801445, mu: 30.71490093, sigma: 0.190124286 },
  114.5: { lambda: -0.902197638, mu: 31.0403221, sigma: 0.191041375 },
  115.5: { lambda: -0.891962513, mu: 31.36927506, sigma: 0.191932319 },
  116.5: { lambda: -0.882112919, mu: 31.7016805, sigma: 0.192795682 },
  117.5: { lambda: -0.872664706, mu: 32.03744999, sigma: 0.193630095 },
  118.5: { lambda: -0.863632768, mu: 32.37648607, sigma: 0.19443426 },
  119.5: { lambda: -0.855031092, mu: 32.71868225, sigma: 0.195206948 },
  120.5: { lambda: -0.846872805, mu: 33.06392318, sigma: 0.195947008 },
  121.5: { lambda: -0.839170224, mu: 33.4120847, sigma: 0.196653365 },
  122.5: { lambda: -0.831934903, mu: 33.76303402, sigma: 0.197325023 },
  123.5: { lambda: -0.825177688, mu: 34.1166299, sigma: 0.197961065 },
  124.5: { lambda: -0.818908758, mu: 34.47272283, sigma: 0.198560655 },
  125.5: { lambda: -0.813137675, mu: 34.83115524, sigma: 0.199123037 },
  126.5: { lambda: -0.807873433, mu: 35.19176177, sigma: 0.199647538 },
  127.5: { lambda: -0.803122613, mu: 35.55437176, sigma: 0.200133598 },
  128.5: { lambda: -0.79889771, mu: 35.91879976, sigma: 0.200580618 },
  129.5: { lambda: -0.795203499, mu: 36.28486194, sigma: 0.200988216 },
  130.5: { lambda: -0.792047959, mu: 36.65236365, sigma: 0.201356017 },
  131.5: { lambda: -0.789435274, mu: 37.02110818, sigma: 0.201683791 },
  132.5: { lambda: -0.787374433, mu: 37.39088668, sigma: 0.201971282 },
  133.5: { lambda: -0.785870695, mu: 37.76148905, sigma: 0.202218375 },
  134.5: { lambda: -0.784929893, mu: 38.1326991, sigma: 0.202425006 },
  135.5: { lambda: -0.784557605, mu: 38.50429603, sigma: 0.202591183 },
  136.5: { lambda: -0.78475917, mu: 38.87605489, sigma: 0.20271698 },
  137.5: { lambda: -0.785539703, mu: 39.24774707, sigma: 0.202802535 },
  138.5: { lambda: -0.786904102, mu: 39.61914076, sigma: 0.202848049 },
  139.5: { lambda: -0.788858208, mu: 39.98999994, sigma: 0.202853758 },
  140.5: { lambda: -0.791403051, mu: 40.36009244, sigma: 0.202820053 },
  141.5: { lambda: -0.794546352, mu: 40.72917544, sigma: 0.202747236 },
  142.5: { lambda: -0.79829102, mu: 41.09701099, sigma: 0.202635758 },
  143.5: { lambda: -0.802640891, mu: 41.46335907, sigma: 0.202486098 },
  144.5: { lambda: -0.807599577, mu: 41.82797963, sigma: 0.202298783 },
  145.5: { lambda: -0.813170461, mu: 42.19063313, sigma: 0.202074385 },
  146.5: { lambda: -0.819356692, mu: 42.55108107, sigma: 0.201813521 },
  147.5: { lambda: -0.826161176, mu: 42.90908653, sigma: 0.201516851 },
  148.5: { lambda: -0.833586038, mu: 43.2644155, sigma: 0.201185082 },
  149.5: { lambda: -0.841634949, mu: 43.61683402, sigma: 0.200818928 },
  150.5: { lambda: -0.850307441, mu: 43.9661169, sigma: 0.200419208 },
  151.5: { lambda: -0.859607525, mu: 44.31203579, sigma: 0.199986681 },
  152.5: { lambda: -0.869534339, mu: 44.65437319, sigma: 0.199522233 },
  153.5: { lambda: -0.880088651, mu: 44.99291356, sigma: 0.199026736 },
  154.5: { lambda: -0.891270585, mu: 45.32744704, sigma: 0.198501096 },
  155.5: { lambda: -0.903079458, mu: 45.65777013, sigma: 0.197946255 },
  156.5: { lambda: -0.915513542, mu: 45.98368656, sigma: 0.197363191 },
  157.5: { lambda: -0.928569454, mu: 46.30500858, sigma: 0.196752931 },
  158.5: { lambda: -0.942245864, mu: 46.62155183, sigma: 0.196116472 },
  159.5: { lambda: -0.956537923, mu: 46.93314404, sigma: 0.19545489 },
  160.5: { lambda: -0.971440492, mu: 47.23962058, sigma: 0.194769279 },
  161.5: { lambda: -0.986947308, mu: 47.54082604, sigma: 0.194060758 },
  162.5: { lambda: -1.003050887, mu: 47.83661466, sigma: 0.193330477 },
  163.5: { lambda: -1.019742425, mu: 48.12685082, sigma: 0.192579614 },
  164.5: { lambda: -1.037011698, mu: 48.41140938, sigma: 0.191809374 },
  165.5: { lambda: -1.054846957, mu: 48.69017613, sigma: 0.191020995 },
  166.5: { lambda: -1.073234825, mu: 48.9630481, sigma: 0.190215739 },
  167.5: { lambda: -1.092160195, mu: 49.22993391, sigma: 0.189394901 },
  168.5: { lambda: -1.111606122, mu: 49.49075409, sigma: 0.188559804 },
  169.5: { lambda: -1.131553723, mu: 49.74544132, sigma: 0.187711798 },
  170.5: { lambda: -1.151982079, mu: 49.99394068, sigma: 0.186852266 },
  171.5: { lambda: -1.172868141, mu: 50.23620985, sigma: 0.185982617 },
  172.5: { lambda: -1.19418462, mu: 50.47222213, sigma: 0.185104331 },
  173.5: { lambda: -1.215907492, mu: 50.70195581, sigma: 0.184218803 },
  174.5: { lambda: -1.238005268, mu: 50.92540942, sigma: 0.183327556 },
  175.5: { lambda: -1.260445591, mu: 51.14259229, sigma: 0.182432113 },
  176.5: { lambda: -1.283193626, mu: 51.3535268, sigma: 0.181534018 },
  177.5: { lambda: -1.306212032, mu: 51.55824831, sigma: 0.180634839 },
  178.5: { lambda: -1.329460945, mu: 51.75680513, sigma: 0.179736168 },
  179.5: { lambda: -1.35289798, mu: 51.94925841, sigma: 0.178839614 },
  180.5: { lambda: -1.376478254, mu: 52.13568193, sigma: 0.177946804 },
  181.5: { lambda: -1.400154426, mu: 52.31616197, sigma: 0.177059379 },
  182.5: { lambda: -1.423876772, mu: 52.49079703, sigma: 0.17617899 },
  183.5: { lambda: -1.447593267, mu: 52.65969757, sigma: 0.175307296 },
  184.5: { lambda: -1.471249702, mu: 52.82298572, sigma: 0.174445958 },
  185.5: { lambda: -1.494789826, mu: 52.9807949, sigma: 0.173596636 },
  186.5: { lambda: -1.518155513, mu: 53.13326946, sigma: 0.172760982 },
  187.5: { lambda: -1.541286949, mu: 53.28056425, sigma: 0.17194064 },
  188.5: { lambda: -1.564122852, mu: 53.42284417, sigma: 0.171137232 },
  189.5: { lambda: -1.586600712, mu: 53.5602837, sigma: 0.170352363 },
  190.5: { lambda: -1.608657054, mu: 53.69306637, sigma: 0.169587605 },
  191.5: { lambda: -1.630227728, mu: 53.82138422, sigma: 0.168844497 },
  192.5: { lambda: -1.651248208, mu: 53.94543725, sigma: 0.168124538 },
  193.5: { lambda: -1.67165392, mu: 54.06543278, sigma: 0.167429179 },
  194.5: { lambda: -1.691380583, mu: 54.18158486, sigma: 0.166759816 },
  195.5: { lambda: -1.710364557, mu: 54.29411356, sigma: 0.166117788 },
  196.5: { lambda: -1.728543207, mu: 54.40324431, sigma: 0.165504365 },
  197.5: { lambda: -1.745855274, mu: 54.50920717, sigma: 0.164920747 },
  198.5: { lambda: -1.762241248, mu: 54.61223603, sigma: 0.164368054 },
  199.5: { lambda: -1.777643747, mu: 54.71256787, sigma: 0.16384732 },
  200.5: { lambda: -1.792007891, mu: 54.81044184, sigma: 0.163359491 },
  201.5: { lambda: -1.805281675, mu: 54.90609842, sigma: 0.162905415 },
  202.5: { lambda: -1.817416335, mu: 54.99977846, sigma: 0.162485839 },
  203.5: { lambda: -1.828366707, mu: 55.09172217, sigma: 0.162101402 },
  204.5: { lambda: -1.838091576, mu: 55.18216811, sigma: 0.161752634 },
  205.5: { lambda: -1.846554015, mu: 55.271352, sigma: 0.161439944 },
  206.5: { lambda: -1.853721704, mu: 55.35950558, sigma: 0.161163623 },
  207.5: { lambda: -1.859567242, mu: 55.44685531, sigma: 0.160923833 },
  208.5: { lambda: -1.864068443, mu: 55.53362107, sigma: 0.160720609 },
  209.5: { lambda: -1.86720861, mu: 55.62001464, sigma: 0.16055385 },
  210.5: { lambda: -1.8689768, mu: 55.70623826, sigma: 0.160423319 },
  211.5: { lambda: -1.869371157, mu: 55.79247939, sigma: 0.160328578 },
  212.5: { lambda: -1.868386498, mu: 55.87892356, sigma: 0.160269232 },
  213.5: { lambda: -1.866033924, mu: 55.96573022, sigma: 0.160244549 },
  214.5: { lambda: -1.862327775, mu: 56.05304601, sigma: 0.160253714 },
  215.5: { lambda: -1.857289195, mu: 56.14099882, sigma: 0.160295765 },
  216.5: { lambda: -1.850946286, mu: 56.22969564, sigma: 0.16036959 },
  217.5: { lambda: -1.84333425, mu: 56.3192203, sigma: 0.16047393 },
  218.5: { lambda: -1.834495505, mu: 56.40963105, sigma: 0.160607377 },
  219.5: { lambda: -1.824479785, mu: 56.50095811, sigma: 0.16076838 },
  220.5: { lambda: -1.813344222, mu: 56.59320107, sigma: 0.160955249 },
  221.5: { lambda: -1.801153404, mu: 56.68632619, sigma: 0.161166157 },
  222.5: { lambda: -1.787979408, mu: 56.78026364, sigma: 0.161399151 },
  223.5: { lambda: -1.773901816, mu: 56.87490465, sigma: 0.161652158 },
  224.5: { lambda: -1.759007704, mu: 56.97009856, sigma: 0.161922998 },
  225.5: { lambda: -1.743391606, mu: 57.06564989, sigma: 0.162209399 },
  226.5: { lambda: -1.72715546, mu: 57.16131528, sigma: 0.162509006 },
  227.5: { lambda: -1.710410733, mu: 57.25679821, sigma: 0.162819353 },
  228.5: { lambda: -1.693267093, mu: 57.35175792, sigma: 0.163138124 },
  229.5: { lambda: -1.67585442, mu: 57.44578172, sigma: 0.163462715 },
  230.5: { lambda: -1.658302847, mu: 57.53840429, sigma: 0.163790683 },
  231.5: { lambda: -1.640747464, mu: 57.62910094, sigma: 0.164119574 },
  232.5: { lambda: -1.623332891, mu: 57.7172758, sigma: 0.164446997 },
  233.5: { lambda: -1.606209374, mu: 57.80226553, sigma: 0.164770638 },
  234.5: { lambda: -1.589533346, mu: 57.88333502, sigma: 0.165088289 },
  235.5: { lambda: -1.573467222, mu: 57.95967458, sigma: 0.165397881 },
  236.5: { lambda: -1.558179166, mu: 58.0303973, sigma: 0.165697507 },
  237.5: { lambda: -1.543846192, mu: 58.09453209, sigma: 0.165985386 },
  238.5: { lambda: -1.530642461, mu: 58.15103575, sigma: 0.166260109 },
  239.5: { lambda: -1.518754013, mu: 58.1987714, sigma: 0.16652037 },
  240: { lambda: -1.51336185, mu: 58.21897289, sigma: 0.166644749 },
};

export const getWeightInKg = (weightInLb: number) => weightInLb / POUND_PER_KG;

export const getChildWeightPercentile = (
  gender: string,
  ageInMonths: number,
  weight: number,
) => {
  const weightInKg = getWeightInKg(weight);
  const childWeightLMSParams =
    gender === 'Female' ? femaleChildWeightLMSParams : maleChildWeightLMSParams;

  return zScoreFromMeasurement(weightInKg, ageInMonths, childWeightLMSParams);
};
