<div om-layout
     om-layout-align="start center"
     om-layout-gutter
     om-layout-fill>
  <omg-checkbox label="Take as needed for"
                [formControl]="usePrn"
                (click)="onUsePrnClick()"
                data-cy="regimen-editor-take-as-needed-for-checkbox">
  </omg-checkbox>
  <omg-auto-complete om-flex
                     #prnDropdown
                     *ngIf="!!usePrn.value && prns"
                     [formControl]="prnId"
                     (change)="onPrnIdChange($event)"
                     [items]="prns"
                     [selectOnTab]="true"
                     [customItem]="addCustomPrn"
                     bindValue="id"
                     bindLabel="desc"
                     addItemText="Create custom item"
                     placeholder="Select item"
                     trackByKey="value"
                     data-cy="regimen-editor-prn-dropdown">
  </omg-auto-complete>
</div>
