<div om-layout="vertical">
  <div om-layout
       om-layout-gutter>
    <strong om-flex>
      {{ procedureCapture.resourceType }}
    </strong>

    <i>Saved to Clincal Data Platform</i>
  </div>

  <div om-layout="vertical"
       om-layout-gutter
       class="padding-normal-vertical">
    <span>
      {{ procedureCapture.code?.coding[0]?.display }}
    </span>
    <span>
      {{ procedureCapture.performedDateTime | date:'MM/d/yyyy' }}
    </span>
    <div *ngIf="procedureCapture.note && procedureCapture.note[0] && procedureCapture.note[0].text">
      <span class="original-case"
            omgTooltip
            [tooltipText]="procedureCapture.note[0].text"
            tooltipPosition="top">
        {{ procedureCapture.note[0].text | slice:0:21 }}
      </span>
    </div>
  </div>
</div>
