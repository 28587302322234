import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiService, PatientSelectors } from '@app/core';
import { isTruthy } from '@app/utils';

@Injectable()
export class MessageTemplatesService {
  private readonly templatesPath = '/v2/admin/message_templates';

  constructor(
    private apiService: ApiService,
    private patientSelectors: PatientSelectors,
  ) {}

  get(templateId: number): Observable<string> {
    return this.patientSelectors.patientId.pipe(
      isTruthy(),
      switchMap(patientId =>
        this.apiService
          .get<{ html: string }>(`${this.templatesPath}/${templateId}`, {
            patient_id: patientId,
          })
          .pipe(map(resp => resp.html)),
      ),
    );
  }
}
