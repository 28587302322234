<div class="omg-dialog-container"
     omgTheme>
  <div omgDialogTitle>
    <h5 class="renewal-checkout-title">Renewal Checkout</h5>
    <button omgButton
            variant="none"
            class="om-icon icon-close clickable"
            (click)="close()"
            data-cy="renewal-checkout-close-btn"></button>
  </div>
  <div omgDialogContent>
    <div om-layout="vertical"
         *ngIf="checkoutForm && (patient$ | ngrxPush) as patient">
      <omg-checkout-patient-info [patient]="patient"
                                 [rxCartMeasurements]="rxCartMeasurements$ | ngrxPush">
      </omg-checkout-patient-info>
      <omg-renewal-checkout-prescriptions [checkoutRenewals]="checkoutForm.controls.get('refillRequests').value">
      </omg-renewal-checkout-prescriptions>
      <omg-renewal-signature [patient]="patient"
                             [renewalCheckoutForm]="checkoutForm"
                             [cartState]="cartState$ | ngrxPush"
                             (cancel)="close()"></omg-renewal-signature>
    </div>
  </div>
