import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { get, map } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

import { FeatureFlagSelectors, PatientSelectors } from '@app/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import {
  headPctField,
  htPctField,
  wtPctField,
} from '@app/modules/growth-charts/growth-charts/growth-charts.type';
import { camelCase, ordinalizePercentile } from '@app/utils';

import { getInfantGrowthOrdinalPercentiles } from '../../shared/growth-percentiles-utils';
import { InfantGrowthOrdinalPercentiles } from '../../shared/infant-growth-percentiles.type';
import {
  GrowthFieldConfig,
  infantFieldToConfigMapping,
} from './percentile-calculator';

@Component({
  selector: 'omg-infant-growth-measurements-form',
  templateUrl: './infant-growth-measurements-form.component.html',
  styleUrls: ['./infant-growth-measurements-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfantGrowthMeasurementsFormComponent
  implements OnInit, OnDestroy, OnChanges {
  gender: string;
  ageInMonths: number;
  ordinalPercentiles: InfantGrowthOrdinalPercentiles;
  showPercentiles$: Observable<boolean>;

  @Input() form: FormGroup;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private cdr: ChangeDetectorRef,
    private patient: PatientSelectors,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  ngOnInit() {
    this.showPercentiles$ = this.featureFlagSelectors.featureEnabled(
      FeatureFlagNames.pediatricsGrowthCharts,
    );

    this.listenToFormChanges();
    this.patient.gender.pipe(take(1)).subscribe(gender => {
      this.gender = gender;
    });

    this.patient.ageInMonths.pipe(take(1)).subscribe(ageInMonths => {
      this.ageInMonths = ageInMonths;
    });

    this.ordinalPercentiles = getInfantGrowthOrdinalPercentiles(
      this.form.value[wtPctField],
      this.form.value[htPctField],
      this.form.value[headPctField],
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // NOTE: A new form is passed down by the UnsignedSummaryComponent every time new data is
  // loaded. Listeners to form control valueChanges do not carry over, and must be re-registered
  // when the form changes. ordinalPercentile must be manually updated with the newly loaded data
  ngOnChanges(changes: SimpleChanges) {
    if (!changes.form.isFirstChange()) {
      this.listenToFormChanges();

      const { currentValue } = changes.form;
      // Clear or update percentile fields from new form control values (set from new API data)
      map(infantFieldToConfigMapping, (percentileConfig: GrowthFieldConfig) => {
        const { percentileFieldName } = percentileConfig;
        const percentile = get(
          currentValue,
          ['value', percentileFieldName],
          null,
        );

        this.setOrdinalPercentile(
          percentileFieldName,
          parseInt(percentile, 10),
        );
      });
    }
  }

  private setOrdinalPercentile(fieldName: string, value: number) {
    const camelizedField = camelCase(fieldName);

    if (camelizedField in this.ordinalPercentiles) {
      this.ordinalPercentiles[camelizedField] = value
        ? ordinalizePercentile(value)
        : null;
    }
  }

  private listenToFormChanges() {
    this.showPercentiles$.subscribe(percentileFlagOn => {
      if (percentileFlagOn) {
        map(
          infantFieldToConfigMapping,
          (infantPercentileConfig: GrowthFieldConfig, inputFormName: string) =>
            this.setupFormListener(infantPercentileConfig, inputFormName),
        );
      } else {
        map(
          Object.values(infantFieldToConfigMapping),
          ({ percentileFieldName }) =>
            this.form.removeControl(percentileFieldName),
        );
      }
    });
  }

  private setupFormListener(
    percentileConfig: GrowthFieldConfig,
    inputField: string,
  ) {
    const { percentileFieldName, percentileFn } = percentileConfig;
    const valueChangeDelay = 750;

    this.form
      .get(inputField)
      .valueChanges.pipe(
        debounceTime(valueChangeDelay),
        takeUntil(this.unsubscribe),
      )
      .subscribe(value =>
        this.updatePercentileFormField(
          percentileFieldName,
          value,
          percentileFn,
        ),
      );
  }

  /**
   * Calculates percentile using `value` and `getPercentile`
   * - sets the FormControl of key `fieldName` for updating via the API
   * - also updates the ordinalPercentile based the camelized `fieldName` to display in the UI
   */
  private updatePercentileFormField(
    fieldName: string,
    value: number,
    getPercentile: Function,
  ) {
    // Clear percentiles if value is falsy
    const percentile: number = value
      ? getPercentile(this.gender, this.ageInMonths, value)
      : null;
    this.form.controls[fieldName].setValue(percentile);

    const camelizedField = camelCase(fieldName);
    this.ordinalPercentiles[camelizedField] = ordinalizePercentile(percentile);

    this.cdr.detectChanges();
  }
}
