import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { clamp } from 'lodash';
import { BehaviorSubject, fromEvent, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { SearchIndex } from '@app/core/search';
import { TemplateInsertionService } from '@app/modules/messaging/shared/template-insertion.service';
import { Template } from '@app/modules/messaging/shared/template-insertion.type';

import { InfiniteScrollComponent } from '../infinite-scroll/infinite-scroll.component';
import { highlightText, InsertionState } from './inline-insertion.utils';

const minSearchLength = 2;
const INSERTION_RESULT_ITEM_HEIGHT = 30;
const MAX_INSERTION_RESULT_HEIGHT = 235;

@Component({
  selector: 'omg-inline-insertion',
  templateUrl: './inline-insertion.component.html',
  styleUrls: ['./inline-insertion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineInsertionComponent implements OnInit, OnDestroy {
  @ViewChild(InfiniteScrollComponent)
  infiniteScroll: InfiniteScrollComponent;

  @Input() previewAlignment = 'top';
  @Input() searchIndex: SearchIndex;
  @Input() insertionState$: Observable<InsertionState>;
  @Output() itemSelect = new EventEmitter<Template>();

  results$: Observable<Array<any>>;
  currentIndex$ = new BehaviorSubject(0);

  isSearching = false;

  private unsubscribe$ = new Subject();

  constructor(private templateInsertionService: TemplateInsertionService) {}

  ngOnInit() {
    this.setResults();
    this.listenForKeyboardEvents();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onItemMouseMove(index: number) {
    if (this.currentIndex$.value !== index) {
      this.currentIndex$.next(index);
    }
  }

  incrementIndex(direction: 'ArrowDown' | 'ArrowUp') {
    const directionAsIndex = direction === 'ArrowDown' ? 1 : -1;
    this.results$
      .pipe(
        take(1),
        withLatestFrom(this.currentIndex$),
        map(([results, currentIndex]) =>
          clamp(currentIndex + directionAsIndex, 0, results.length - 1),
        ),
        tap(index => {
          this.currentIndex$.next(index);
          this.scrollElement(index);
        }),
      )
      .subscribe();
  }

  scrollElement(index: number) {
    const indexOfFirstRenderedItem = this.infiniteScroll.viewport.getRenderedRange()
      .start;
    (this.infiniteScroll.viewport.elementRef.nativeElement
      .querySelectorAll('.infinite-scroll-item')
      .item(index - indexOfFirstRenderedItem) as any).scrollIntoViewIfNeeded(
      false,
    );
  }

  getFixedScrollHeight(results: Template[]) {
    return results.length * INSERTION_RESULT_ITEM_HEIGHT >
      MAX_INSERTION_RESULT_HEIGHT
      ? MAX_INSERTION_RESULT_HEIGHT
      : results.length * INSERTION_RESULT_ITEM_HEIGHT;
  }

  private setResults() {
    this.results$ = this.insertionState$.pipe(
      takeUntil(this.unsubscribe$),
      map(state => state.searchTerm),
      tap(
        searchTerm =>
          (this.isSearching = searchTerm.trim().length >= minSearchLength),
      ),
      debounceTime(250),
      switchMap((searchTerm: string) =>
        searchTerm.length >= minSearchLength
          ? this.templateSearch(searchTerm)
          : of([]),
      ),
      tap(() => this.currentIndex$.next(0)),
      startWith([]),
      shareReplay(1),
    );
  }

  private templateSearch(searchTerm: string) {
    return this.templateInsertionService
      .searchForTemplate(searchTerm, this.searchIndex)
      .pipe(
        take(1),
        tap(() => {
          this.isSearching = false;
        }),
        map(results =>
          results.map(result => ({
            ...result,
            highlightedTitle: this.highlightTitle(result.name, searchTerm),
          })),
        ),
      );
  }

  private highlightTitle(templateName: string, searchTerm: string) {
    return searchTerm && searchTerm.trim()
      ? highlightText(templateName, searchTerm)
      : null;
  }

  // tslint:disable-next-line: member-ordering
  private keyboardEvents = {
    ArrowUp: () => this.incrementIndex('ArrowUp'),
    ArrowDown: () => this.incrementIndex('ArrowDown'),
    Enter: currentItem => this.itemSelect.emit(currentItem),
  };

  private listenForKeyboardEvents() {
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter(event => this.keyboardEvents[event.key]),
        map(event => event.key),
        withLatestFrom(this.results$, this.currentIndex$),
        filter(([_, results, currentIndex]) => !!results[currentIndex]),
        tap(([key, results, currentIndex]) =>
          this.keyboardEvents[key](results[currentIndex]),
        ),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }
}
