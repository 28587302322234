import { ProblemForm } from '@app/modules/problems/shared/problems.type';
import { DropdownItem } from '@app/shared';
import { startCase } from '@app/utils';

import {
  ContactSearchResult,
  Specialty,
} from './consultant-search/consultant-search.type';
import { OrderIndication } from './order-indication/order-indication.type';

export interface OrderEvent {
  createdAt: string;
  onBehalfOf?: string;
  statusChange: string;
  updatedBy: string;
}

export enum OrderTypes {
  BasicFollowUpOrder = 'BasicFollowUpOrder',
  ConsultOrder = 'ConsultOrder',
  GenericFollowUpOrder = 'GenericFollowUpOrder',
  LabOrder = 'LabOrder',
  ProcedureOrder = 'ProcedureOrder',
  SurveyOrder = 'SurveyOrder',
  VaccineOrder = 'VaccineOrder',
  VisitFollowUpOrder = 'VisitFollowUpOrder',
  ConnectedDeviceOrder = 'ConnectedDeviceOrder',
}

export enum OrderMenuTypes {
  BasicFollowUpOrder = 'Follow-Up',
  ConsultOrder = 'Consult',
  LabOrder = 'Lab',
  ProcedureOrder = 'Procedure',
  SurveyOrder = 'Questionnaire',
  VaccineOrder = 'Vaccine',
  ConnectedDeviceOrder = 'Remote Measurement Collection',
}

export const orderFilterDropdownItems: DropdownItem[] = [
  {
    label: 'All Order Types',
    value: null,
  },
  ...Object.keys(OrderMenuTypes).map(orderType => ({
    label: OrderMenuTypes[orderType],
    value: orderType,
  })),
];

export const getOrderTypeDisplay = (orderType: keyof typeof OrderTypes) =>
  startCase(orderType).toLowerCase();

export type OrderStatus =
  | 'approved'
  | 'cancelled'
  | 'collected'
  | 'completed'
  | 'declined'
  | 'editing'
  | 'expired'
  | 'finished'
  | 'patient completed'
  | 'queued'
  | 'redacted'
  | 'reviewed'
  | 'sent'
  | 'signed'
  | 'unsigned';

export interface Order {
  createdAt: string;
  id: number;
  status: OrderStatus;
  type: OrderTypes;
  reviewable: boolean;
  totalComments?: number;
  urgency: UrgencyOption;
}

export interface OrderWithSupportingDocuments extends Order {
  supportingDocuments?: SupportingDocument[];
  unsuccessfulSupportingDocuments?: SupportingDocument[];
}

export interface OrderWithIndications extends Order {
  supportsMultipleIndications?: boolean;
  indication: OrderIndication;
  indications: OrderIndication[];
}

export interface ProcedureOrderWithIndications extends OrderWithIndications {
  performNow: boolean;
}

export interface ProblemProcedureOrder {
  form: ProblemForm;
  order: Partial<ProcedureOrderWithIndications>;
}

export interface PatientInsuranceApprovalExplanation {
  state: 'yes' | 'no' | 'unknown';
  text?: string;
}

export interface OrderAutoApprovalExplanation {
  procedureRequiresPreAuth?: boolean;
  procedureRequiresPreAuthText?: string;
  actionToTake?: string;
  contactAcceptsPatientInsurance?: PatientInsuranceApprovalExplanation;
  contactCompletenessText?: string;
  contactIsComplete?: boolean;
  patientHasPpoOrSelfPay?: boolean;
  patientInsuranceText?: string;
}

export interface SupportingDocument {
  id: number;
  author?: string;
  createdAt?: string;
  approximatePdfPages?: number;
  subject?: string;
}

export type UrgencyOption = 'routine' | 'urgent' | 'stat';

type UrgencyLabelMap = { [urgencyOption in UrgencyOption]: string };

export const urgencyLabelMap: UrgencyLabelMap = {
  routine: 'Routine',
  urgent: 'Urgent',
  stat: 'STAT',
};

export type OrderWithConsultant = Order &
  ConsultantDetails &
  ConsultantOrderDetails &
  ConsultantOrderSigningOptions;

export interface ConsultantDetails {
  contact?: ContactSearchResult;
  specialty?: Specialty;
}

export interface ConsultantOrderDetails {
  autoApprovalExplanations?: OrderAutoApprovalExplanation;
  canBeAutoApproved?: boolean;
  clinicalHistory: string;
  denialComments?: string;
  events: OrderEvent[];
  insAuthCode?: string;
  insAuthExpirationDate?: Date;
  insuranceAuthorization?: any;
  onBehalfOfId?: number;
  transmissionFailed?: boolean;
  urgency: UrgencyOption;
  wasAutoApproved?: boolean;
  transmissions: any[];
}

export interface ConsultantOrderSigningOptions {
  status: OrderStatus;
  sendManually?: boolean;
  approvalWasAutomated?: boolean;
}

export const automateApprovalOptions: ConsultantOrderSigningOptions = {
  status: 'sent',
  approvalWasAutomated: true,
};

export const manualTransmissionOptions: ConsultantOrderSigningOptions = {
  status: 'sent',
  sendManually: true,
};

export interface FollowUpOrder extends Order {
  dueOn: string;
  followUpOrderEvents: OrderEvent[];
  orderedBy: string;
  reminderGranularity: string;
  updatedAt: string;
}

export interface Contact {
  name: string;
  specialties: any[];
}

export class OrderContact implements Contact {
  id: number;
  name = '';
  specialties: any[] = [];
  displayName: string;
}
