import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';

import { Todo } from '@app/modules/todo/shared/todo.type';
import { AutoCompleteComponent } from '@app/shared';
import { ToastMessageService } from '@app/shared/components/toast';

import { TaskAssignmentService } from '../../shared/task-assignment.service';
import { TodoActions } from '../../store/todo.actions';

@Component({
  selector: 'omg-task-assignment',
  templateUrl: './task-assignment.component.html',
  styleUrls: ['./task-assignment.component.scss'],
})
export class TaskAssignmentComponent {
  @Input() todo: Observable<Todo>;
  @Output() assigneeUpdated = new EventEmitter<string>();

  @ViewChild('assigneeSearch')
  private assigneeSearchRef: AutoCompleteComponent;

  searchResults: Observable<Array<any>>;

  constructor(
    private taskAssignmentService: TaskAssignmentService,
    private toastMessageService: ToastMessageService,
    private todoActions: TodoActions,
  ) {}

  onSearch(text: string) {
    if (text.length > 1) {
      this.searchResults = this.taskAssignmentService.searchTaskAssignees(text);
    }
  }

  clearAssignee() {
    this.assigneeSearchRef.writeValue(null); // Set the value to null to clear it; the dropdown won't open since the items have been reset
  }

  restoreAssignee(todo) {
    if (todo.assignee !== null) {
      this.assigneeSearchRef.writeValue(todo.assignee);
    }
  }

  updateAssignee(todo: Todo, reassign: Boolean = true) {
    if (todo.assignee !== null) {
      this.todoActions.updateTodo(todo);
      this.assigneeSearchRef.resetItems(); // Reset after updating the model so that the dropdown doesn't open on click
      if (reassign) {
        this.assigneeUpdated.emit(todo.assignee.identifier);
        this.toastMessageService.add({
          severity: 'success',
          detail: 'Task reassigned',
        });
      }
    }
  }

  filterTaskAssignees(term: string, item: any) {
    // return true so that all elasticsearch autocomplete results are displayed
    return true;
  }
}
