import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { DetectResizeService } from '@app/shared/directives/detect-resize/detect-resize.service';
import { compact } from '@app/utils/shared/lodash-fp';

import { setElementHeight } from './card.utils';

@Component({
  selector: 'omg-card-body',
  templateUrl: './card-body.component.html',
  styleUrls: ['./card-body.component.scss'],
})
export class CardBodyComponent implements AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject();
  @ViewChild('cardBody', { static: true }) cardBody: ElementRef;

  constructor(private detectResizeService: DetectResizeService) {}

  ngAfterViewInit() {
    const resizeObservers = compact([
      this.detectResizeService.getResizeEvents$(this.container, 30),
      this.detectResizeService.getResizeEvents$(this.card, 30),
      this.container?.hasAttribute('docked')
        ? this.detectResizeService.getResizeEvents$(this.cardContent, 30)
        : null,
    ]);

    if (this.el && this.container) {
      merge(...resizeObservers)
        .pipe(
          tap(() => setElementHeight(this.el, this.container)),
          takeUntil(this.unsubscribe$),
        )
        .subscribe();
    }
  }

  private get container() {
    return this.el.closest('[sticky-card-container]');
  }

  private get card() {
    return this.el.closest('omg-card');
  }

  private get el() {
    return this.cardBody && this.cardBody.nativeElement;
  }

  private get cardContent() {
    return this.el.children[0];
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
