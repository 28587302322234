import Perfume from 'perfume.js';
import { IPerfumeOptions } from 'perfume.js/dist/types/types';

declare var newrelic: any;
const newRelicEnabled = (window as any).newrelic;

function logPerfume(options): void {
  const style = 'color:#ff6d00;font-size:11px;';
  // tslint:disable-next-line: no-console
  console.log(`%c Perfume.js: ${options.metricName} `, style, options.data);
}

export const trackMetrics = (metricName: string, data?: any) => {
  if (metricName === 'navigationTiming') {
    Object.keys(data).forEach(key =>
      newrelic.setCustomAttribute(key, data[key]),
    );
    newrelic.addPageAction(metricName);
  } else if (typeof data !== 'object') {
    newrelic.setCustomAttribute(metricName, data);
    newrelic.addPageAction(metricName);
  }
};

export function perfumeAnalyticsTracker(options: Partial<any>) {
  const { metricName, data } = options;
  logPerfume(options);
  if (newRelicEnabled) {
    trackMetrics(metricName, data);
  }
}

export const PerfumeConfig: IPerfumeOptions = {
  analyticsTracker: perfumeAnalyticsTracker,
};

export const perfume = new Perfume(PerfumeConfig);
