import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';

import { PatientSelectors, Profile, ProfileSelectors } from '@app/core';

import { Renewal } from '../../shared/renewals.type';
import { RenewalSelectors } from '../../store';

@Component({
  selector: 'omg-ready-for-checkout',
  templateUrl: './ready-for-checkout.component.html',
  styleUrls: ['./ready-for-checkout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadyForCheckoutComponent implements OnInit {
  approvedOrDeniedRenewals$: Observable<Renewal[]>;
  acceptsDigitalCommunications$: Observable<boolean>;
  cartId$: Observable<number>;
  profile$: Observable<Profile>;
  isProvider$: Observable<boolean>;
  loading$: Observable<boolean>;
  incompleteDemographics$: Observable<boolean>;

  @Input()
  isListView = false;

  constructor(
    private renewalSelectors: RenewalSelectors,
    private patientSelectors: PatientSelectors,
    private profileSelectors: ProfileSelectors,
  ) {}

  ngOnInit() {
    this.acceptsDigitalCommunications$ = this.patientSelectors.acceptsDigitalCommunications;
    this.approvedOrDeniedRenewals$ = this.renewalSelectors.approvedOrDeniedRenewals;
    this.cartId$ = this.renewalSelectors.cartId;
    this.isProvider$ = this.profileSelectors.hasRole('provider');
    this.loading$ = this.renewalSelectors.loading;
    this.profile$ = this.profileSelectors.profile;
    this.incompleteDemographics$ = this.patientSelectors.hasIncompleteDemographics;
  }
}
