import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { getBmi } from '../../shared/summaries-utils';
import { SummaryMeasurementsByAbbreviation } from '../../shared/summaries.type';

@Component({
  selector: 'omg-summary-measurements-table',
  templateUrl: './summary-measurements-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryMeasurementsTableComponent implements OnChanges {
  @Input() measurements: SummaryMeasurementsByAbbreviation;
  @Input() showInfantVitals: boolean;

  bmi: number;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.measurements) {
      const weight = this.measurements.wt || { value: null };
      const height = this.measurements.ht || { value: null };
      const weightValue = weight.value || 0;
      const heightValue = height.value || 0;

      this.bmi = getBmi(weightValue, heightValue);
    }
  }
}
