<div class="-immediate-errors"
     om-layout
     om-layout-gutter-large
     [formGroup]="form">
  <label class="-stacked"
         om-flex="15">
    infant wt
    <label class="plain-text"
           om-layout
           om-layout-align="center center"
           om-layout-gutter-mini>
      <input omgInputNumber
             type="number"
             min="0"
             max="500"
             step="0.01"
             name="inf_wt"
             omgSyncInput
             [control]="form.get('inf_wt')"
             om-layout-fill />
      <span>kg</span>
    </label>
  </label>
  <span class="padding-half-vertical"
        om-flex="15"
        om-layout
        om-layout-align="start end">
    <div *ngIf="!!(showPercentiles$ | ngrxPush) && !!ordinalPercentiles.wtPct"
         om-layout
         om-layout-gutter-large>
      <span om-flex="15">| </span>
      <span>{{ ordinalPercentiles.wtPct }}</span>
    </div>
  </span>
  <label class="-stacked"
         om-flex="15">
    ht
    <label class="plain-text"
           om-layout
           om-layout-align="center center"
           om-layout-gutter-mini>
      <input omgInputNumber
             type="number"
             min="0"
             max="99.9"
             step="0.01"
             name="ht"
             omgSyncInput
             [control]="form.get('ht')"
             om-layout-fill>
      <span>in</span>
    </label>
  </label>
  <span class="padding-half-vertical"
        om-flex="15"
        om-layout
        om-layout-align="start end">
    <div *ngIf="!!(showPercentiles$ | ngrxPush) && !!ordinalPercentiles.htPct"
         om-layout
         om-layout-gutter-large>
      <span om-flex="15">| </span>
      <span>{{ ordinalPercentiles.htPct }}</span>
    </div>
  </span>
  <label class="-stacked"
         om-flex="15">
    head circ
    <label class="plain-text"
           om-layout
           om-layout-align="center center"
           om-layout-gutter-mini>
      <input omgInputNumber
             type="number"
             min="0"
             max="99.9"
             step="0.01"
             name="head"
             omgSyncInput
             [control]="form.get('head')"
             om-layout-fill>
      <span>cm</span>
    </label>
  </label>
  <span class="padding-half-vertical"
        om-flex="15"
        om-layout
        om-layout-align="start end">
    <div *ngIf="!!(showPercentiles$ | ngrxPush) && !!ordinalPercentiles.headPct"
         om-layout
         om-layout-gutter-large>
      <span om-flex="15">| </span>
      <span>{{ ordinalPercentiles.headPct }}</span>
    </div>
  </span>
</div>
