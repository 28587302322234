<omg-section-title *ngIf="!hideTitle">
  <span om-flex="80">
    Procedure Summary
  </span>
</omg-section-title>

<div class="padding-normal"
     *ngIf="!readOnly">
  <omg-chart-text-box quillFormat="text"
                      [control]="procedureSummaryControl"
                      (blur)="onBlur()"></omg-chart-text-box>
  <div *ngIf="!procedureSummaryFormValid && (procedureSummaryControl.dirty || procedureSummaryControl.touched)">
    <div class="alert">
      Please add a procedure summary
    </div>
  </div>
</div>

<div class="padding-normal"
     *ngIf="readOnly">
  <p omgTheme
     class="procedure-summary-body"
     [innerHTML]="procedureInteraction.procedureSummary | safe:'html'"></p>

</div>
