import { ordinalizePercentile } from '@app/utils';

import { ChildGrowthOrdinalPercentiles } from './child-growth-percentiles.type';
import { InfantGrowthOrdinalPercentiles } from './infant-growth-percentiles.type';

/**
 * Note: the keys of the ordinalPercentile must match the camelized form field name
 * i.e. if the form keys are [ foo_pct, bar_baz ]
 *      the ordinalPercentiles here must be [ fooPct, barBaz ]
 */

export const getInfantGrowthOrdinalPercentiles = (
  wtPct: number,
  htPct: number,
  headPct: number,
): InfantGrowthOrdinalPercentiles => ({
  wtPct: ordinalizePercentile(wtPct),
  htPct: ordinalizePercentile(htPct),
  headPct: ordinalizePercentile(headPct),
});

// wt us used for all ages excluding infants
// percentiles are not differentiated between infant and child
// TODO: clean up percentiles field names to be inclusive instead of exclusive
export const getChildGrowthOrdinalPercentiles = (
  wtPct: number,
  htPct: number,
  bmiPct: number,
): ChildGrowthOrdinalPercentiles => ({
  wtPct: ordinalizePercentile(wtPct),
  htPct: ordinalizePercentile(htPct),
  bmiPct: ordinalizePercentile(bmiPct),
});
