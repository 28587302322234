import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';

import { createHotkeyHandler } from './shortcut-click-handlers';
import { HotkeyHandler, HotkeyHandlerType } from './shortcut-click.type';

/** For more information on possible hotkeys, please see https://craig.is/killing/mice. */
@Directive({
  selector: '[omgShortcutClick]',
})
export class ShortcutClickDirective implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input('omgShortcutClick') key: string;
  @Input() handlerType: HotkeyHandlerType = 'focusClick';

  private registeredKeys: Hotkey[] = [];

  constructor(private el: ElementRef, private hotkeyService: HotkeysService) {}

  ngOnInit() {
    this.registerHotkey(this.key);
  }

  ngOnDestroy() {
    this.registeredKeys.forEach(registeredKey =>
      this.deregisterHotkey(registeredKey),
    );
  }

  deregisterHotkey(hotkey: Hotkey): Hotkey {
    this.registeredKeys = this.registeredKeys.filter(
      registeredKeys => registeredKeys !== hotkey,
    );
    this.hotkeyService.remove(hotkey);

    return hotkey;
  }

  registerHotkey(key: string, handler?: HotkeyHandler): Hotkey {
    const focusElementHandler = createHotkeyHandler('focusClick', this.el);
    const hotkey = new Hotkey(key, handler || focusElementHandler);

    this.registeredKeys = [...this.registeredKeys, hotkey];
    this.hotkeyService.add(hotkey);

    return hotkey;
  }
}
