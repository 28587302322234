import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiService, PatientSelectors } from '@app/core';
import { isTruthy } from '@app/utils';

interface TemplateResponse {
  body: string;
}

@Injectable({
  providedIn: 'root',
})
export class NoteTemplateService {
  private readonly templatesPath = '/v2/admin/note_templates';

  constructor(
    private apiService: ApiService,
    private patientSelectors: PatientSelectors,
  ) {}

  get(templateId: number): Observable<string> {
    return this.patientSelectors.patientId.pipe(
      isTruthy(),
      switchMap(patientId =>
        this.apiService
          .get<TemplateResponse>(`${this.templatesPath}/${templateId}`, {
            patient_id: patientId,
          })
          .pipe(map(resp => resp.body)),
      ),
    );
  }
}
