import { Injectable } from '@angular/core';
import { Client } from 'elasticsearch-browser';
import { Observable, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { camelCase } from '@app/utils';

import { CookieService } from '../auth/shared/cookie.service';
import { ConfigService } from '../config';
import { ElasticsearchLocationService } from './elasticsearch-location.service';
import { ElasticsearchLocation } from './elasticsearch-location.type';

interface SearchOptions {
  camelize?: boolean;
}

@Injectable()
export class SearchService {
  private client$ = new ReplaySubject<Client>(1);
  private initialized = false;

  elasticsearchLocation: ElasticsearchLocation;

  constructor(
    private configService: ConfigService,
    private elasticsearchLocationService: ElasticsearchLocationService,
    private cookie: CookieService,
  ) {}

  search(request: any, options: SearchOptions = {}): Observable<any> {
    this.initialize();
    return this.searchRequest(request, options, 'search');
  }

  scroll(request: any, options: SearchOptions = {}): Observable<any> {
    this.initialize();
    return this.searchRequest(request, options, 'scroll');
  }

  private searchRequest(
    request: any,
    options: SearchOptions = {},
    mode: 'search' | 'scroll',
  ) {
    return this.client$.pipe(
      switchMap(client => {
        if (!client) {
          throw new Error('Elasticsearch location is undefined');
        }

        const req =
          mode === 'scroll' ? client.scroll(request) : client.search(request);

        return req.then(response =>
          options.camelize ? camelCase(response) : response,
        );
      }),
    );
  }

  private initialize() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    return this.elasticsearchLocationService
      .get()
      .subscribe((esLocation: ElasticsearchLocation) => {
        if (!esLocation || !esLocation.url) {
          this.client$.next(null);
          return;
        }

        const esUrl = new URL(esLocation.url);
        const basicCredentialsSupplied =
          esUrl.username.length !== 0 && esUrl.password.length !== 0;

        if (basicCredentialsSupplied) {
          this.client$.next(new Client({ host: esUrl.href }));
        } else {
          const token = this.cookie.get();
          const authHeaders = { Authorization: `Bearer ${token}` };

          const client = new Client({
            host: {
              protocol: esUrl.protocol,
              host: esUrl.hostname,
              port: esUrl.port,
              path: esUrl.pathname,
              headers: authHeaders,
            },
          });
          this.client$.next(client);
        }
      });
  }
}
