import { Directionality } from '@angular/cdk/bidi';
import {
  Injectable,
  InjectionToken,
  Injector,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { of as observableOf } from 'rxjs';

import {
  ComponentType,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import {
  ComponentPortal,
  PortalInjector,
  TemplatePortal,
} from '@angular/cdk/portal';

import { DialogConfig } from './dialog-config';
import { DialogContainer } from './dialog-container';
import { DialogRef } from './dialog-ref';

export const OMG_DIALOG_DATA = new InjectionToken<'OmgDialogData'>(
  'OMG_DIALOG_DATA',
);

const buildDefaultConfig = (): DialogConfig => ({
  ...new DialogConfig(),
});

const applyConfigDefaults = (
  config?: DialogConfig,
  defaultOptions?: DialogConfig,
): DialogConfig => ({ ...defaultOptions, ...config });

/**
 * Service to open modal dialogs.
 *
 * @note Much of the dialog is based off the material dialog
 * @see https://material.angular.io/components/dialog/overview
 * @see https://github.com/angular/material2/blob/master/src/lib/dialog/dialog.ts
 */
@Injectable()
export class DialogService implements OnDestroy {
  constructor(private injector: Injector, private overlay: Overlay) {}

  ngOnDestroy() {}

  open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: DialogConfig<D>,
  ): DialogRef<T, R> {
    config = applyConfigDefaults(config, buildDefaultConfig());

    const overlayRef = this.createOverlay(config);
    const dialogContainer = this.attachDialogContainer(overlayRef, config);

    const dialogRef = this.attachDialogContent<T, R>(
      componentOrTemplateRef,
      dialogContainer,
      overlayRef,
      config,
    );

    return dialogRef;
  }

  /**
   * Creates the overlay into which the dialog will be loaded.
   * @param config The dialog configuration.
   * @returns A promise resolving to the OverlayRef for the created overlay.
   */
  private createOverlay(config: DialogConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  /**
   * Creates an overlay config from a dialog config.
   * @param dialogConfig The dialog configuration.
   * @returns The overlay configuration.
   */
  private getOverlayConfig(dialogConfig: DialogConfig): OverlayConfig {
    const state = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      scrollStrategy: dialogConfig.scrollStrategy,
      panelClass: dialogConfig.panelClass,
      hasBackdrop: dialogConfig.hasBackdrop,
      direction: dialogConfig.direction,
      minWidth: dialogConfig.minWidth,
      minHeight: dialogConfig.minHeight,
      maxWidth: dialogConfig.maxWidth,
      maxHeight: dialogConfig.maxHeight,
      disposeOnNavigation: dialogConfig.closeOnNavigation,
    });

    if (dialogConfig.backdropClass) {
      state.backdropClass = dialogConfig.backdropClass;
    }

    return state;
  }

  /**
   * Attaches an DialogContainer to a dialog's already-created overlay.
   * @param overlay Reference to the dialog's underlying overlay.
   * @param config The dialog configuration.
   * @returns A promise resolving to a ComponentRef for the attached container.
   */
  private attachDialogContainer(overlayRef: OverlayRef, config: DialogConfig) {
    const userInjector =
      config && config.viewContainerRef && config.viewContainerRef.injector;
    const injector = new PortalInjector(
      userInjector || this.injector,
      new WeakMap([[DialogConfig, config]]),
    );

    const containerPortal = new ComponentPortal(
      DialogContainer,
      config.viewContainerRef,
      injector,
    );

    const containerRef = overlayRef.attach<DialogContainer>(containerPortal);
    return containerRef.instance;
  }

  /**
   * Attaches the user-provided component to the already-created DialogContainer.
   * @param componentOrTemplateRef The type of component being loaded into the dialog,
   *     or a TemplateRef to instantiate as the content.
   * @param dialogContainer Reference to the wrapping DialogContainer.
   * @param overlayRef Reference to the overlay in which the dialog resides.
   * @param config The dialog configuration.
   * @returns A promise resolving to the DialogRef that should be returned to the user.
   */
  private attachDialogContent<T, R>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    dialogContainer: DialogContainer,
    overlayRef: OverlayRef,
    config: DialogConfig,
  ): DialogRef<T, R> {
    // Create a reference to the dialog we're creating in order to give the user a handle
    // to modify and close it.
    const dialogRef = new DialogRef<T, R>(
      overlayRef,
      dialogContainer,
      config.id,
    );

    // When the dialog backdrop is clicked, we want to close it.
    if (config.hasBackdrop) {
      overlayRef.backdropClick().subscribe(() => {
        if (!dialogRef.disableClose) {
          dialogRef.close();
        }
      });
    }

    if (componentOrTemplateRef instanceof TemplateRef) {
      dialogContainer.attachTemplatePortal(
        new TemplatePortal<T>(componentOrTemplateRef, null, <any>{
          $implicit: config.data,
          dialogRef,
        }),
      );
    } else {
      const injector = this.createInjector<T>(
        config,
        dialogRef,
        dialogContainer,
      );
      const contentRef = dialogContainer.attachComponentPortal<T>(
        new ComponentPortal(componentOrTemplateRef, undefined, injector),
      );
      dialogRef.componentInstance = contentRef.instance;
    }

    dialogRef
      .updateSize(config.width, config.height)
      .updatePosition(config.position);

    return dialogRef;
  }

  /**
   * Creates a custom injector to be used inside the dialog. This allows a component loaded inside
   * of a dialog to close itself and, optionally, to return a value.
   * @param config Config object that is used to construct the dialog.
   * @param dialogRef Reference to the dialog.
   * @param container Dialog container element that wraps all of the contents.
   * @returns The custom injector that can be used inside the dialog.
   */
  private createInjector<T>(
    config: DialogConfig,
    dialogRef: DialogRef<T>,
    dialogContainer: DialogContainer,
  ): PortalInjector {
    const userInjector =
      config && config.viewContainerRef && config.viewContainerRef.injector;

    // Instantiate new WeakMap for our custom injection tokens
    const injectionTokens = new WeakMap<any, any>([
      [DialogContainer, dialogContainer],
      [OMG_DIALOG_DATA, config.data],
      [DialogRef, dialogRef],
    ]);

    if (
      config.direction &&
      (!userInjector ||
        !userInjector.get<Directionality | null>(Directionality, null))
    ) {
      injectionTokens.set(Directionality, {
        value: config.direction,
        change: observableOf(),
      });
    }

    // Instantiate new PortalInjector
    return new PortalInjector(userInjector || this.injector, injectionTokens);
  }
}
