import { Injectable } from '@angular/core';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { OrderTypes } from '@app/modules/orders/shared/order.type';

import { CommentsActions } from '../store/comments.actions';
import { CommentsSelectors } from '../store/comments.selectors';
import {
  Commentable,
  CommentableTypeToCommentsReferenceMap,
} from './comments.type';

@Injectable()
export class CommentsService {
  constructor(
    private commentsActions: CommentsActions,
    private commentsSelectors: CommentsSelectors,
  ) {}

  private summaryNoteRef = {
    get: id => this.commentsActions.getSummaryComments(id),
    save: (id, body) => this.commentsActions.createSummaryComment(id, body),
    delete: id => this.commentsActions.deleteComment(id),
    pending: this.commentsSelectors.summaryCommentsPending,
    error: this.commentsSelectors.summaryCommentsError,
    comments$: this.commentsSelectors.summaryAllComments,
  };

  private timelinePostRef = {
    get: id => this.commentsActions.getPostComments(id),
    save: (id, body) => this.commentsActions.createPostComment(id, body),
    delete: id => this.commentsActions.deleteComment(id),
    pending: this.commentsSelectors.postCommentsPending,
    error: this.commentsSelectors.postCommentsError,
    comments$: this.commentsSelectors.postAllComments,
  };

  private labOrderRef = {
    get: (id: number) =>
      this.commentsActions.getOrderComments({ id, type: OrderTypes.LabOrder }),
    save: (id: number, body: string) =>
      this.commentsActions.createOrderComment(
        { id, type: OrderTypes.LabOrder },
        body,
      ),
    delete: (id: number) => this.commentsActions.deleteComment(id),
    pending: this.commentsSelectors.orderCommentsPending,
    error: this.commentsSelectors.orderCommentsError,
    comments$: this.commentsSelectors.allOrderComments,
  };

  private consultOrderRef = {
    get: (id: number) =>
      this.commentsActions.getOrderComments({
        id,
        type: OrderTypes.ConsultOrder,
      }),
    save: (id: number, body: string) =>
      this.commentsActions.createOrderComment(
        { id, type: OrderTypes.ConsultOrder },
        body,
      ),
    delete: (id: number) => this.commentsActions.deleteComment(id),
    pending: this.commentsSelectors.orderCommentsPending,
    error: this.commentsSelectors.orderCommentsError,
    comments$: this.commentsSelectors.allOrderComments,
  };

  private procedureOrderRef = {
    get: (id: number) =>
      this.commentsActions.getOrderComments({
        id,
        type: OrderTypes.ProcedureOrder,
      }),
    save: (id: number, body: string) =>
      this.commentsActions.createOrderComment(
        { id, type: OrderTypes.ProcedureOrder },
        body,
      ),
    delete: (id: number) => this.commentsActions.deleteComment(id),
    pending: this.commentsSelectors.orderCommentsPending,
    error: this.commentsSelectors.orderCommentsError,
    comments$: this.commentsSelectors.allOrderComments,
  };

  private procedureInteractionRef = {
    get: (id: number) =>
      this.commentsActions.getProcedureInteractionComments(id),
    save: (id: number, body: string) =>
      this.commentsActions.createProcedureInteractionComment(id, body),
    delete: (id: number) => this.commentsActions.deleteComment(id),
    pending: this.commentsSelectors.procedureInteractionCommentsPending,
    error: this.commentsSelectors.procedureInteractionCommentsError,
    comments$: this.commentsSelectors.procedureInteractionAllComments,
  };

  private renewalsRef = {
    get: (id: number) => this.commentsActions.getRenewalComments(id),
    save: (id: number, body: string) =>
      this.commentsActions.createRenewalComment(id, body),
    delete: (id: number) => this.commentsActions.deleteComment(id),
    pending: this.commentsSelectors.renewalCommentsPending,
    error: this.commentsSelectors.renewalCommentsError,
    comments$: this.commentsSelectors.renewalAllComments,
  };

  private refMap: CommentableTypeToCommentsReferenceMap = {
    summary: this.summaryNoteRef,
    note: this.summaryNoteRef,
    letter: this.summaryNoteRef,
    timeline_post: this.timelinePostRef,
    lab_order: this.labOrderRef,
    consult_order: this.consultOrderRef,
    procedure_order: this.procedureOrderRef,
    renewals: this.renewalsRef,
    procedure_interaction: this.procedureInteractionRef,
  };

  get(commentable: Commentable) {
    const ref = this.refMap[commentable.commentableType];
    ref.get(commentable.id);
    return ref.pending.pipe(
      filter(pending => !pending),
      switchMap(() => ref.comments$),
    );
  }

  save(commentable: Commentable, comment: string) {
    const ref = this.refMap[commentable.commentableType];
    ref.save(commentable.id, comment);

    return ref.pending.pipe(
      filter(pending => !pending),
      withLatestFrom(ref.error),
      map(([_, error]) => error === null),
    );
  }

  delete(commentable: Commentable, commentId: number) {
    const ref = this.refMap[commentable.commentableType];
    ref.delete(commentId);
    return ref.pending.pipe(
      withLatestFrom(ref.error),
      filter(([pending, error]) => !pending && error === null),
    );
  }
}
