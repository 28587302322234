import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PatientApiService } from './shared/patient-api.service';
import { PatientActions } from './store/patient.actions';
import { PatientEffects } from './store/patient.effects';
import { patientReducer, patientStatePath } from './store/patient.reducer';
import { PatientSelectors } from './store/patient.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(patientStatePath, patientReducer),
    EffectsModule.forFeature([PatientEffects]),
  ],
  providers: [PatientApiService, PatientSelectors, PatientActions],
})
export class PatientModule {
  constructor() {}
}
