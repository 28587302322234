import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import {
  orderCommentsReducer,
  OrderCommentsState,
} from './order-comments.reducer';
import {
  postCommentsReducer,
  PostCommentsState,
} from './post-comments.reducer';
import {
  procedureInteractionCommentsReducer,
  ProcedureInteractionCommentsState,
} from './procedure-interaction-comments.reducer';
import {
  renewalCommentsReducer,
  RenewalCommentsState,
} from './renewal-comments.reducer';
import {
  summaryCommentsReducer,
  SummaryCommentsState,
} from './summary-comments.reducer';

export const commentsStatePath = 'comments';

export const selectCommentsState = createFeatureSelector<CommentsState>(
  commentsStatePath,
);
export interface CommentsState {
  summaryComments: SummaryCommentsState;
  postComments: PostCommentsState;
  orderComments: OrderCommentsState;
  renewalComments: RenewalCommentsState;
  procedureInteractionComments: ProcedureInteractionCommentsState;
}

export const commentsReducers: ActionReducerMap<CommentsState> = {
  summaryComments: summaryCommentsReducer,
  postComments: postCommentsReducer,
  orderComments: orderCommentsReducer,
  renewalComments: renewalCommentsReducer,
  procedureInteractionComments: procedureInteractionCommentsReducer,
  /* Register comments reducers here. */
};
