import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SummariesComponent } from '../summaries/components/summaries/summaries.component';
import { NewWorkspaceItemComponent } from './components/new-workspace-item/new-workspace-item.component';
import { WorkspaceComponent } from './components/workspace/workspace.component';

export const summariesRoutePath = ':id/edit';

const routes: Routes = [
  {
    path: '',
    component: WorkspaceComponent,
    children: [
      {
        path: '',
        redirectTo: 'new',
      },
      {
        path: 'new',
        component: NewWorkspaceItemComponent,
      },
      {
        path: summariesRoutePath,
        component: SummariesComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WorkspaceRoutingModule {}
