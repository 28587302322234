import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { showMoreOptions } from '@app/modules/shared-rx/utils';

import { ChangeRxForm } from '../../shared/change-rx-form';
import { ChangeRx, RxChangeRequestOption } from '../../shared/change-rx.type';
import { ChangeRxSelectors } from '../../store/change-rx.selectors';

@Component({
  selector: 'omg-change-rx-medication-select',
  templateUrl: './change-rx-medication-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeRxMedicationSelectComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();

  @Input() options: RxChangeRequestOption[];

  @Input() form: ChangeRxForm;

  constructor(private changeRxSelectors: ChangeRxSelectors) {}

  ngOnInit() {
    this.listenToFormControls();
    this.form.controls
      .get('selectedRxChangeRequestOption')
      .setValue(this.options[0]);
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  listenToFormControls() {
    this.form.controls
      .get('selectedRxChangeRequestOption')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(changeRequestOption => {
        const refills = this.form.controls.get('refills');
        const quantity = this.form.controls.get('quantity');
        if (changeRequestOption) {
          refills.setValue(changeRequestOption['fills']);
          quantity.setValue(changeRequestOption['quantity']);
        } else {
          refills.setValue('');
          quantity.setValue('');
        }
      });
  }
}
