import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { PatientSelectors, ProfileSelectors } from '@app/core';
import { DatePipe } from '@app/shared/pipes';

@Injectable()
export class ChartVariablesService {
  constructor(
    private patientSelectors: PatientSelectors,
    private profileSelectors: ProfileSelectors,
    private datePipe: DatePipe,
  ) {}

  populate(value: string): Observable<string> {
    return combineLatest([
      this.patientSelectors.patient,
      this.profileSelectors.profile,
    ]).pipe(
      take(1),
      map(results => {
        let transformedValue = value;
        const patient = results[0];
        const profile = results[1];
        const now = new Date();
        const variables = {
          '\\.last_name': patient.lastName,
          '\\.fullname': patient.fullName,
          '\\.preferred_name': patient.preferredName,
          '\\.pcp': patient.pcp || 'one of our primary care providers',
          '\\.brand': patient.brand,
          '\\.office': patient.office ? patient.office.name : '',
          '\\.website': patient.website,
          '\\.my_first_name': profile.firstName,
          '\\.my_last_name': profile.lastName,
          '\\.my_name': profile.displayName,
          '\\.my_title': profile.displayNameWithSuffix,
          '\\.my_role': profile.suffixRole,
          '\\.my_npi': profile.npi || '',
          '\\.todays_date': this.datePipe.transform(now, '4y'),
          '\\.current_time':
            this.datePipe.transform(now, '12Time') +
            ' ' +
            this.datePipe.getTimeZone(now),
        };

        Object.keys(variables).forEach(varName => {
          transformedValue = transformedValue.replace(
            new RegExp(varName, 'g'),
            variables[varName],
          );
        });

        return transformedValue;
      }),
    );
  }
}
