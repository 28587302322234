import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

import { TrackEventProperties } from '@app/core/analytics/shared/analytics.type';

import { ChartTextBoxComponent } from './chart-text-box.component';

@Directive({
  selector: '[omgSnippets]',
})
export class TemplateInsertionDirective implements OnInit {
  @Input() quillFormat = 'text';
  @Input() singleLine = false;
  @Input() maxLength: number = null;
  @Output() blur = new EventEmitter();
  @Output() enterPressed = new EventEmitter();

  /**
   * Analytics properties passed to ChartTextBoxComponent and InlineInsertionDirective
   * to provide context about the component in which snippet insertion events occur.
   */
  @Input() insertionEventProps: Partial<TrackEventProperties>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public el: ElementRef<HTMLElement>,
    public viewContainerRef: ViewContainerRef,
    public cd: ChangeDetectorRef,
    public ngControl: NgControl,
  ) {}

  ngOnInit() {
    this.useChartTextBox();
  }

  useChartTextBox() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      ChartTextBoxComponent,
    );

    const componentRef = this.viewContainerRef.createComponent(
      componentFactory,
    );
    componentRef.instance.insertionEventProps = this.insertionEventProps;
    componentRef.instance.control = <FormControl>this.ngControl.control;
    componentRef.instance.placeholderText = this.el.nativeElement.getAttribute(
      'placeholderText',
    );
    componentRef.instance.quillFormat = this.quillFormat;
    componentRef.instance.dataCy = this.el.nativeElement.getAttribute(
      'data-cy',
    );
    componentRef.instance.maxLength = this.maxLength;
    componentRef.instance.singleLine = this.singleLine;
    componentRef.instance.blur = this.blur;
    componentRef.instance.enterPressed = this.enterPressed;

    this.el.nativeElement.remove();
    this.cd.markForCheck();
  }
}
