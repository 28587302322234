import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'omg-renewal-note-banners',
  templateUrl: './renewal-note-banners.component.html',
})
export class RenewalNoteBannersComponent implements OnInit {
  @Input() fromPharmacist: string;
  @Input() fromPatient: string;
  @Input() cancellationReason: string;

  constructor() {}

  ngOnInit() {}
}
