import {
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  SecurityContext,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Tooltip } from 'primeng/tooltip';

@Directive({
  selector: '[omgTooltip]',
})
export class TooltipDirective extends Tooltip implements OnInit, OnChanges {
  @Input()
  public tooltipText: string;

  @Input()
  public showTooltip = true;

  constructor(el: ElementRef, zone: NgZone, private sanitizer: DomSanitizer) {
    super(el, zone);

    this.showDelay = 250;
  }

  public ngOnInit() {
    this.disabled = !this.showTooltip;
    this.setSanitzedText(this.tooltipText);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.showTooltip) {
      this.disabled = !changes.showTooltip.currentValue;
    }

    if (changes.tooltipText) {
      this.setSanitzedText(changes.tooltipText.currentValue);
    }
  }

  private setSanitzedText(rawText: string) {
    const safeHTML = this.sanitizer.sanitize(SecurityContext.HTML, rawText);

    this._text = <string>safeHTML;
  }
}
