import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import {
  HelpRequestOptions,
  HelpRequestService,
} from '@app/modules/help-request/help-request.service';
import { DropdownItem, OMG_DIALOG_DATA } from '@app/shared';
import { DialogRef } from '@app/shared/components/dialog';
import { ToastMessageService } from '@app/shared/components/toast';
import { get } from '@app/utils';

import { mapAppointmentTypeToDropdownItem } from '../../../features/follow-up-order/shared/follow-up-order-mappers';
import { FollowUpOrderAppointmentType } from '../../../features/follow-up-order/shared/follow-up-order.type';
import { HelpFlowType, HelpFlowTypeResponse } from '../help-request.type';

@Component({
  selector: 'omg-help-request-confirmation-modal',
  templateUrl: './help-request-confirmation-modal.component.html',
  styleUrls: ['./help-request-confirmation-modal.component.scss'],
})
export class HelpRequestConfirmationModalComponent implements OnInit {
  isLinkedToNote: boolean;
  hasRequiredNoteDetails: boolean;
  helpFlowTypes$: Observable<DropdownItem[]>;
  formGroup: FormGroup;

  get maxDetailsLength() {
    return 500;
  }

  constructor(
    public dialogRef: DialogRef<HelpRequestConfirmationModalComponent, boolean>,
    @Inject(OMG_DIALOG_DATA) public requestOptions: HelpRequestOptions,
    private helpRequestService: HelpRequestService,
    private toastMessageService: ToastMessageService,
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      helpFlowType: new FormControl(),
      details: new FormControl(),
    });

    this.isLinkedToNote = !!this.requestOptions.linkedNoteId;

    this.hasRequiredNoteDetails =
      this.isLinkedToNote && !!get('summary.subject', this.requestOptions);

    this.helpFlowTypes$ = this.helpRequestService.getHelpFlowTypes().pipe(
      map((helpFlowTypeResponse: HelpFlowTypeResponse) =>
        helpFlowTypeResponse.helpflowTypes.map(type => ({
          label: type.displayName,
          value: type.id,
        })),
      ),
      tap(types =>
        this.formGroup.get('helpFlowType').patchValue(types[0].value),
      ),
    );
  }

  getVMTHelp() {
    this.helpRequestService
      .getHelp(
        this.requestOptions,
        this.formGroup.get('helpFlowType').value,
        this.formGroup.get('details').value,
      )
      .pipe(
        take(1),
        catchError(error => {
          this.toastMessageService.add({
            severity: 'error',
            summary: 'Your request is incomplete',
            detail: `${
              error.status === 500 ? `A server error occurred. ` : ''
            }Please try again`,
          });
          return error;
        }),
      )
      .subscribe(() => {
        this.close();
      });
  }

  close() {
    this.dialogRef.close(false);
  }
}
