import { Injectable } from '@angular/core';

import { GraphQLService } from '@app/core/graphql/graphql.service';

@Injectable()
export class TodoReassignmentFeedbackService {
  constructor(private graphQL: GraphQLService) {}

  saveFeedback(id: string, feedbackCategory: string, feedbackText: string) {
    this.graphQL
      .query(
        `mutation UpsertMlFeedbackTodoMessageReassignment($id: ID!, $feedbackCategory: String, $feedbackText: String) {
        upsertMlFeedbackTodoMessageReassignment(input: {
          todoMessageReassignmentId: $id, feedbackCategory: $feedbackCategory, feedbackText: $feedbackText
        }) {
          success
          errors
        }
      }`,
        { id, feedbackCategory, feedbackText },
      )
      .subscribe();
  }

  saveAssignee(id: string, assigneeId: string, assigneeType: string) {
    this.graphQL
      .query(
        `mutation UpsertMlFeedbackTodoMessageReassignment($id: ID!, $assigneeId: ID, $assigneeType: String!) {
        upsertMlFeedbackTodoMessageReassignment(input: {
          todoMessageReassignmentId: $id, newAssigneeId: $assigneeId, newAssigneeType: $assigneeType
        }) {
          success
          errors
        }
      }`,
        { id, assigneeId, assigneeType },
      )
      .subscribe();
  }
}
