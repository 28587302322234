<span *ngIf="resolvableImage"
      [omgBackgroundImage]="resolvableImage"></span>
<img *ngIf="url"
     hidden
     [src]="url"
     (error)="fallbackToInitials()" />
<span *ngIf="!resolvableImage"
      om-layout="vertical"
      om-layout-align="center"
      [class]="'combo-' + colorCombo">
  <strong>{{initials}}</strong>
</span>
