<div class="padding-normal"
     om-layout-wrap
     om-layout>
  <omg-change-rx-status-approval></omg-change-rx-status-approval>
  <div om-flex="100"
       class="padding-normal-vertical">
    <div><strong>Medication Change</strong></div>
    <div>{{ form.controls.get('selectedRxChangeRequestOption')?.value?.medForDisplay.name }} {{
      form.controls.get('selectedRxChangeRequestOption')?.value?.medForDisplay.dispensableTextDesc }}</div>
    <div>{{ form.controls.get('selectedRxChangeRequestOption')?.value?.medForDisplay.instructions }}</div>
  </div>
</div>
