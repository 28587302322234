<ng-container [formGroup]="form">
  <label om-layout-gutter-mini
         class="plain-text">
    Qty
    <input omgInputNumber
           omgTrimTrailingZeros
           om-flex="10"
           name="quantity"
           type="number"
           formControlName="quantity"
           data-cy="new-rx-quantity-input" />
    <span *ngIf="showPackageSize">
      {{ packageSizeDescription }}
    </span>
  </label>

  <span class="padding-half-horizontal"
        *ngIf="showPackageSizes">
    <omg-auto-complete #packageSizeInput
                       [clearable]="false"
                       [omgFocusOn]="focusOnMedicationKey"
                       (focusOnRequest)="packageSizeInput.focus()"
                       resetItemsOnClear="false"
                       formControlName="medicationPackageSizeId"
                       [items]="packageSizes"
                       bindValue="id"
                       [bindLabel]="form.get('quantity').value === 1 ? 'desc' : 'descPlural'"
                       [fillLayout]="false"
                       trackByKey="value">
    </omg-auto-complete>
  </span>
</ng-container>
