import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeRxForm } from '../../shared/change-rx-form';
import { ChangeRx } from '../../shared/change-rx.type';

@Component({
  selector: 'omg-change-rx-medication-change',
  templateUrl: './change-rx-medication-change.component.html',
  styleUrls: ['./change-rx-medication-change.component.css'],
})
export class ChangeRxMedicationChangeComponent {
  @Input() form: ChangeRxForm;
  @Input() changeRx: ChangeRx;

  regimenEditable = false;

  constructor() {}
}
