import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { Todo } from '@app/modules/todo/shared/todo.type';
import { isTruthy } from '@app/utils';

import { ChangeRxForm } from './../../shared/change-rx-form';
import { ChangeRxFormService } from '../../shared/change-rx-form.service';
import { ChangeRxGuard } from '../../shared/change-rx.guard';
import { ChangeRx } from '../../shared/change-rx.type';
import { ChangeRxSelectors } from '../../store/change-rx.selectors';

@Component({
  selector: 'omg-change-item-detail',
  templateUrl: './change-item-detail.component.html',
  styleUrls: ['./change-item-detail.component.scss'],
})
export class ChangeItemDetailComponent implements OnInit, OnDestroy {
  changeRx$: Observable<ChangeRx>;
  todo$: Observable<Todo>;
  form: ChangeRxForm;

  constructor(
    private changeRxSelectors: ChangeRxSelectors,
    private route: ActivatedRoute,
    private changeRxGuard: ChangeRxGuard,
    private changeRxFormService: ChangeRxFormService,
  ) {}

  ngOnInit() {
    this.changeRxGuard
      .canActivate(this.route.snapshot)
      .pipe(
        isTruthy(),
        tap(() => this.setupSelectors()),
      )
      .subscribe();
  }

  /* istanbul ignore next */
  ngOnDestroy() {}

  private setupSelectors() {
    this.changeRx$ = this.route.paramMap.pipe(
      map((params: ParamMap) => +params.get('id')),
      switchMap((id: number) => this.changeRxSelectors.getById(id)),
      tap(change => this.form = this.form || this.changeRxFormService.buildForm(change)),
    );
  }
}
