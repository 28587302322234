import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';

import { StickyStatesService } from '@app/core/services/sticky-states.service';

import { perfume } from './core/analytics/shared/perfume-config';
import { DocumentTitleSetterService, RouteTracker } from './core/services';

@Component({
  selector: 'omg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  get appLoader(): HTMLElement {
    return <HTMLElement>document.querySelector('.app-loading');
  }

  hideAppLoader() {
    this.appLoader.style.display = 'none';
  }

  showAppLoader() {
    this.appLoader.style.display = 'flex';
  }

  constructor(
    private router: Router,
    private stickyStatesService: StickyStatesService,
    private documentTitleSetter: DocumentTitleSetterService,
    private routeTracker: RouteTracker,
  ) {
    perfume.start('AppComponent');
    this.setupLoader();
  }

  ngOnInit(): void {
    this.forcePathLocationStrategy();
  }

  ngAfterViewInit(): void {
    perfume.end('AppComponent');
  }

  private loadStartEvents(event: RouterEvent) {
    return event instanceof NavigationStart;
  }

  private loadEndEvents(event: RouterEvent) {
    return (
      event instanceof NavigationEnd ||
      event instanceof NavigationError ||
      event instanceof NavigationCancel
    );
  }

  private setupLoader() {
    this.router.events.subscribe((event: RouterEvent) => {
      if (this.loadStartEvents(event)) {
        this.showAppLoader();
      }
      if (this.loadEndEvents(event)) {
        this.hideAppLoader();
      }
    });
  }

  private forcePathLocationStrategy(): void {
    // This function forces URLs that use the HashLocationStrategy form (e.g. https://ui.1life.com/#/schedule)
    // to use PathLocationStrategy instead (e.g. https://ui.1life.com/schedule).
    //
    // This should be safe to remove once we've changed all incoming links to the new strategy, and informed
    // onelife-ui users to update their bookmarks and other external links.
    const hash = window.location.hash;
    if (hash.length > 0) {
      const destination = hash.slice(1);
      this.router.navigate([destination], { replaceUrl: true });
    }
  }
}
