import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { RxMedicationDispensable } from '@app/modules/rx-cart/shared/rx-cart.type';
import { RxPackageSize } from '@app/modules/shared-rx/shared-rx.type';

@Component({
  selector: 'omg-cart-item-quantity-and-package-size',
  templateUrl: './cart-item-quantity-and-package-size.component.html',
  styleUrls: ['./cart-item-quantity-and-package-size.component.scss'],
})
export class CartItemQuantityAndPackageSizeComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() medicationDispensable: RxMedicationDispensable;
  @Input() set focusOnMedicationKey(id: string) {
    this._focusOnMedicationKey = `medCart-packageSize${id}`;
  }
  private _focusOnMedicationKey: string;

  showPackageSize: boolean;
  showPackageSizes: boolean;
  packageSizeDescription: string;
  packageSizes: RxPackageSize[];

  get focusOnMedicationKey(): string {
    return this._focusOnMedicationKey;
  }

  ngOnInit() {
    if (
      !!this.medicationDispensable &&
      this.medicationDispensable.packageSizes
    ) {
      this.setPackageSizeOptions();
    }
  }

  setPackageSizeOptions() {
    this.showPackageSize = this.medicationDispensable.packageSizes.length === 1;
    this.showPackageSizes = this.medicationDispensable.packageSizes.length > 1;

    this.setPackageSizeDescription();
    this.setPackageSizes();
  }

  setPackageSizeDescription() {
    if (this.form.get('quantity').value === 1) {
      this.packageSizeDescription = this.medicationDispensable.packageSizes[0].desc;
    } else {
      this.packageSizeDescription = this.medicationDispensable.packageSizes[0].descPlural;
    }
  }

  setPackageSizes() {
    this.packageSizes = this.medicationDispensable.packageSizes || [];
  }
}
