import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { isDateAfter, isDateBefore } from '@app/utils/dates';

@Component({
  selector: 'omg-cart-item-fill-after-date',
  templateUrl: './cart-item-fill-after-date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartItemFillAfterDateComponent
  implements OnInit, OnDestroy, OnChanges {
  @Input() minEarliestFillDate: string;
  @Input() maxEarliestFillDate: string;
  @Input() isRequired: boolean;
  @Input() inputName = 'fillAfterDateField';
  @Input() fillAfter: FormControl;

  @Output() recalculateDates = new EventEmitter();

  private unsubscribe = new Subject();
  minDate: Date;
  maxDate: Date;

  ngOnInit() {
    this.fillAfter.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.onDateChanged());

    this.setMinMaxFillDates();
  }

  ngOnChanges(changes: SimpleChanges) {
    const {
      currentValue: currentMin = null,
      previousValue: previousMin = null,
    } = changes.minEarliestFillDate || {};

    const {
      currentValue: currentMax = null,
      previousValue: previousMax = null,
    } = changes.maxEarliestFillDate || {};

    if (currentMin !== previousMin || currentMax !== previousMax) {
      this.setMinMaxFillDates();
      this.onDateChanged();
    }
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onDateChanged() {
    const fillAfter: Date = this.fillAfter.value;
    if (!!this.minDate && isDateBefore(fillAfter, this.minDate)) {
      this.fillAfter.setValue(this.minDate);
    }

    if (!!this.maxDate && isDateAfter(fillAfter, this.maxDate)) {
      this.fillAfter.setErrors({ ...this.fillAfter.errors, max: true });
    }

    if (!fillAfter && this.isRequired) {
      this.fillAfter.setErrors({
        ...this.fillAfter.errors,
        required: true,
      });
    }
    this.recalculateDates.emit();
  }

  private setMinMaxFillDates() {
    if (!!this.minEarliestFillDate) {
      this.minDate = new Date(this.minEarliestFillDate);
    }

    if (!!this.maxEarliestFillDate) {
      this.maxDate = new Date(this.maxEarliestFillDate);
    }
  }
}
