import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { DenialReason } from '@app/features/renewals/shared/renewals.type';
import { DialogService } from '@app/shared';
import { ActionBarComponent } from '@app/shared/components/action-bar/action-bar.component';

import { ChangeRxForm } from './../../shared/change-rx-form';
import { ChangeRxApprovalDialogComponent } from '../change-rx-approval-dialog/change-rx-approval-dialog.component';

@Component({
  selector: 'omg-change-item-action-bar',
  templateUrl: './change-item-action-bar.component.html',
  styleUrls: ['./change-item-action-bar.component.scss'],
})
export class ChangeItemActionBarComponent implements OnInit {
  constructor(private dialogService: DialogService) {}

  @Input()
  form: ChangeRxForm;

  @ViewChild(ActionBarComponent, { static: true })
  actionBar: ActionBarComponent;

  ngOnInit(): void {}

  get disabledActions() {
    return !this.form || this.form.model.saving;
  }

  openApprovalModal(isApproved: boolean, reasonForDenial?: DenialReason) {
    if (isApproved && !this.form.controls.valid) {
      this.form.controls.markAsDirty();
      return;
    }

    this.dialogService.open(ChangeRxApprovalDialogComponent, {
      autoFocus: true,
      disableClose: true,
      data: {
        form: this.form,
        isApproved,
        reasonForDenial,
      },
    });
  }

  openDenyDrawer() {
    this.actionBar.showConfirmDrawer();
  }

  closeDenyDrawer() {
    this.actionBar.hideConfirmDrawer();
  }
}
