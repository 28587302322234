import { InjectionToken } from '@angular/core';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ActionReducerMap } from '@ngrx/store';

import {
  ActionNameTypeMapFactory,
  buildTokenName,
  StateAction,
  StateConfig,
  stateReducerFactory,
  StoreEntityState,
} from '@app/core/store/shared/state';
import { PrescriberCredentials } from '@app/modules/shared-rx/prescriber-credential.type';

import { Renewal, RenewalCart } from '../shared/renewals.type';
import {
  CheckoutRenewalCartError,
  CheckoutRenewalCartSuccess,
  LoadPrescriberCredentials,
  LoadPrescriberCredentialsError,
  LoadPrescriberCredentialsSuccess,
  LoadRenewalCartError,
  LoadRenewalCartSuccess,
  RenewalActionTypes,
  RenewalCustomAction,
  UpdateCartState,
  UpdateRenewalCommentTotal,
} from './renewals.actions';

export const renewalsStateConfig: StateConfig = {
  statePath: 'renewals',
  entityName: 'Renewal',
  pluralName: 'Renewals',
};

export const renewalsActionNameTypeMap = ActionNameTypeMapFactory(
  renewalsStateConfig,
);

export function selectRenewalId(renewal: Renewal): number {
  return renewal && renewal.id;
}

export const renewalsEntityAdapter: EntityAdapter<Renewal> = createEntityAdapter<
  Renewal
>({
  selectId: selectRenewalId,
});

export interface RenewalEntityState extends StoreEntityState<Renewal> {
  prescriberCredentials: {
    [key: number]: PrescriberCredentials;
    loading: boolean;
  };
  loading: boolean;
  error: any;
  renewalCount: number;
  cartState: RenewalCart;
}
export const defaultCartState: Partial<RenewalCart> = {
  passwordVerified: false,
  invalidPassword: '',
  hasUnknownError: false,
  validationError: '',
};

export const initialState: RenewalEntityState = renewalsEntityAdapter.getInitialState(
  {
    loading: false,
    error: null,
    renewalCount: 0,
    cartState: <any>defaultCartState,
    prescriberCredentials: null,
  },
);

export const RenewalsReducerToken = new InjectionToken<
  ActionReducerMap<RenewalEntityState>
>(buildTokenName(renewalsStateConfig));

type UpdateCartStateAction = CheckoutRenewalCartError | UpdateCartState;

const updateCartState = (state: any, action: UpdateCartStateAction) => {
  return {
    ...state,
    loading: false,
    cartState: {
      ...state.cartState,
      ...action.payload,
    },
  };
};

type LoadPrescriberCredentialAction =
  | LoadPrescriberCredentials
  | LoadPrescriberCredentialsSuccess
  | LoadPrescriberCredentialsError;

const updatePrescriberCredentials = (
  state: any,
  action: LoadPrescriberCredentialAction,
) => {
  const { renewalId, ...payload } = (<LoadPrescriberCredentialsSuccess>(
    action
  )).payload;
  return {
    ...state,
    loading: false,
    prescriberCredentials: {
      ...state.prescriberCredentials,
      [renewalId]: payload,
      loading: false,
    },
  };
};

export function renewalsReducer(
  state = initialState,
  action: StateAction<Renewal> | RenewalCustomAction,
): RenewalEntityState {
  switch (action.type) {
    case RenewalActionTypes.LoadRenewalCart: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case RenewalActionTypes.LoadRenewalCartSuccess: {
      const { cartItems, ...cartState } = (<LoadRenewalCartSuccess>(
        action
      )).payload;
      return renewalsEntityAdapter.addAll(cartItems, {
        ...state,
        cartState: { ...state.cartState, ...cartState, cartCompleteAt: null },
        loading: false,
        error: null,
        renewalCount: (cartItems && cartItems.length) || 0,
      });
    }

    case RenewalActionTypes.LoadRenewalCartError: {
      return {
        ...state,
        loading: false,
        error: (<LoadRenewalCartError>action).payload,
      };
    }

    case RenewalActionTypes.LoadPrescriberCredentials: {
      return {
        ...state,
        loading: true,
        error: null,
        prescriberCredentials: {
          ...state.prescriberCredentials,
          loading: true,
        },
      };
    }

    case RenewalActionTypes.LoadPrescriberCredentialsSuccess: {
      return updatePrescriberCredentials(
        state,
        <LoadPrescriberCredentialsSuccess>action,
      );
    }

    case RenewalActionTypes.LoadPrescriberCredentialsError: {
      return {
        ...state,
        loading: false,
        error: (<LoadPrescriberCredentialsError>action).payload,
        prescriberCredentials: {
          ...state.prescriberCredentials,
          loading: false,
        },
      };
    }

    case RenewalActionTypes.UpdateRenewalCommentTotal: {
      const act = <UpdateRenewalCommentTotal>action;
      return {
        ...renewalsEntityAdapter.updateOne(
          {
            id: act.payload.id,
            changes: { totalComments: act.payload.totalComments },
          },
          state,
        ),
      };
    }

    case RenewalActionTypes.CheckoutRenewalCartSuccess: {
      const { cartItems, ...cartState } = (<CheckoutRenewalCartSuccess>(
        action
      )).payload;
      return renewalsEntityAdapter.updateMany(cartItems, {
        ...state,
        cartState,
        loading: false,
        error: null,
        renewalCount: state.ids.length - cartItems.length,
      });
    }

    case RenewalActionTypes.CheckoutRenewalCartError: {
      return updateCartState(state, <CheckoutRenewalCartError>action);
    }

    case RenewalActionTypes.UpdateCartState: {
      return updateCartState(state, <UpdateCartState>action);
    }

    default: {
      return { ...state };
    }
  }
}

export const renewalsReducerFactory = () => {
  return stateReducerFactory<Renewal, RenewalCustomAction, RenewalEntityState>(
    renewalsActionNameTypeMap,
    renewalsEntityAdapter,
    renewalsReducer,
    initialState,
  );
};
