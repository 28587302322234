<div #container
     [ngClass]="{
        'p-splitbutton p-buttonset p-component': true,
        'p-disabled': disabled
      }"
     [ngStyle]="style"
     class="-{{variant}}">
  <button #defaultbtn
          class="p-button-text-only"
          [type]="type"
          pButton
          [icon]="icon"
          [iconPos]="iconPos"
          [label]="label"
          (click)="onDefaultButtonClick($event)"
          [ngClass]="{'-disabled': disabledLabel || disabled}"
          [disabled]="disabledLabel || disabled"
          [attr.tabindex]="tabindex">
  </button><button type="button"
          pButton
          class="p-splitbutton-menubutton"
          icon="pi pi-chevron-down"
          *ngIf="items"
          (click)="onDropdownButtonClick($event)"
          [ngClass]="{'-disabled': disabledMenu || disabled}"
          [disabled]="disabledMenu || disabled"></button>
  <div #overlay
       [ngClass]="'p-menu p-menu-dynamic p-component p-helper-clearfix'"
       *ngIf="overlayVisible"
       [ngStyle]="menuStyle"
       [class]="menuStyleClass"
       [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}"
       (@overlayAnimation.start)="onOverlayAnimationStart($event)">
    <ul class="p-menu-list p-reset">
      <ng-template ngFor
                   let-item
                   [ngForOf]="items">
        <li class="p-menuitem p-component"
            role="menuitem"
            omgTooltip
            [tooltipText]="item.toolTipMessage"
            [showTooltip]="item.showToolTip"
            tooltipPosition="right"
            *ngIf="item.visible !== false">
          <a *ngIf="!item.routerLink"
             [attr.href]="item.url"
             class="p-menuitem-link"
             [attr.target]="item.target"
             [ngClass]="{'p-disabled': item.disabled}"
             (click)="itemClick($event, item)">
            <span [ngClass]="'p-menuitem-icon'"
                  [class]="item.icon"
                  *ngIf="item.icon"></span>
            <span class="p-menuitem-text">{{item.label}}</span>
          </a>
          <a *ngIf="item.routerLink"
             [routerLink]="item.routerLink"
             [queryParams]="item.queryParams"
             class="p-menuitem-link"
             [attr.target]="item.target"
             [ngClass]="{'p-disabled': item.disabled}"
             (click)="itemClick($event, item)">
            <span [ngClass]="'p-menuitem-icon'"
                  [class]="item.icon"
                  *ngIf="item.icon"></span>
            <span class="p-menuitem-text">{{item.label}}</span>
          </a>
        </li>
      </ng-template>
    </ul>
  </div>
</div>
