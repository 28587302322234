<div class="omg-dialog-container"
     style="width: 560px;"
     omgTheme>
  <div omgDialogTitle>
    <h5 class="delete-message-title">Delete Message Thread</h5>
    <button omgButton
            variant="none"
            class="om-icon icon-close clickable"
            (click)="dialogRef.close()"></button>
  </div>
  <div omgDialogContent>
    <div class="padding-normal"
         om-layout="vertical"
         om-layout-gutter>
      <p>This will permanently delete the entire message thread, attachments, and associated comments. It will no
        longer
        be available in the patient's record.</p>
      <p><strong>This action is irreversible.</strong></p>
    </div>
  </div>
  <div omgDialogActions>
    <button omgButton
            variant="critical"
            type="submit"
            (click)="onDeletePost()">
      Delete Message
    </button>
    <button omgButton
            variant="secondary"
            type="button"
            (click)="dialogRef.close()">
      Never Mind
    </button>
  </div>
</div>
