<omg-section-title>
  <div om-layout
       om-layout-align="space-between center"
       om-flex=100>
    <span>Procedures & Services</span>
    <span>CPT</span>
  </div>
  <div *ngIf="canDeleteServiceTicketItem$ | ngrxPush"
       class="delete-spacing"></div>
</omg-section-title>
<ul class="padding-normal"
    om-layout="vertical"
    om-layout-gutter>
  <li class="om-animate"
      *ngFor="let serviceTicketItem of serviceTicketItems; trackBy: trackByFn">
    <div *ngIf="!serviceTicketItem.hideFromNote">
      <div om-layout
           om-layout-align="space-between center">
        <div om-layout
             om-layout-align="space-between center"
             om-flex="100">
          <span>
            <strong>
              {{ serviceTicketItem.title }}
              <span *ngIf="serviceTicketItem.indications"> &ndash; {{ serviceTicketItem.indications }}</span>
            </strong>
          </span>
          <span>{{ formatCptCodes(serviceTicketItem.cpts) }}</span>
        </div>
        <span *ngIf="canDeleteServiceTicketItem$ | ngrxPush"
              class="om-icon clickable fa fa-trash delete-spacing"
              omgTooltip
              tooltipText="Delete from Note"
              tooltipPosition="left"
              (click)="deleteServiceTicketItem(serviceTicketItem)"></span>
      </div>
      <omg-collapse om-collapse>
        <omg-collapse-toggle>
          <omg-collapsed disableAnimation="true">
            <button type="button"
                    variant="link"
                    omgButton
                    om-stop-event="click">
              Show Notes
            </button>
          </omg-collapsed>
        </omg-collapse-toggle>
        <omg-expanded>
          <span [innerText]="serviceTicketItem.summary"></span>
        </omg-expanded>
      </omg-collapse>
    </div>
  </li>
  <li class="om-messages"
      *ngIf="showServiceTicketDeleteError">
    Unable to delete procedure or service.
  </li>
</ul>

<div class="padding-normal"
     om-layout="vertical"
     om-layout-gutter>
  <omg-procedure-suggestion *ngFor="let suggestion of procedureSuggestions"
                            [procedureSuggestion]="suggestion"
                            [patientId]="patientId">
  </omg-procedure-suggestion>
</div>
