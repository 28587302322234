import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { Profile, ProfileIdentity, ProfileSelectors } from '@app/core';
import { Observable } from 'rxjs';

import { FocusService } from '@app/shared/directives/focus/focus.service';
import { get } from 'lodash';
import { take } from 'rxjs/operators';
import { InternalUserPreferencesService } from '../../shared/internal-user-preferences.service';
import { InternalUserAssignee } from '../../shared/internal-user-preferences.type';
import { MessagingService } from '../../shared/messaging.service';
import { Message, Post } from '../../shared/messaging.type';

@Component({
  selector: 'omg-in-progress-message-header',
  templateUrl: './in-progress-message-header.component.html',
  styleUrls: ['./in-progress-message-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InProgressMessageHeaderComponent implements OnInit, OnChanges {
  @Input() inProgressMessage: Message | Post;

  @Output() checkIfShouldUpdate = new EventEmitter<{
    data: Message | Post;
    save: boolean;
  }>();

  profile$: Observable<Profile>;
  primaryIdentity$: Observable<ProfileIdentity>;
  assigneesItems$: Observable<InternalUserAssignee[]>;
  notifyItems$: Observable<InternalUserAssignee[]>;

  notified = false;
  assigned = false;

  notifyFocusOnKey = 'currentMessage-notify';
  assignFocusOnKey = 'currentMessage-assign';

  constructor(
    private profileSelectors: ProfileSelectors,
    private internalUserPreferencesService: InternalUserPreferencesService,
    private messagingService: MessagingService,
    private focusService: FocusService,
  ) {}

  ngOnInit() {
    this.profile$ = this.profileSelectors.profile;
    this.primaryIdentity$ = this.profileSelectors.primaryIdentity;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!get(changes.inProgressMessage.currentValue, 'replyTo.name')) {
      this.fetchDefaultAssignee();
    }
  }

  fetchDefaultAssignee() {
    this.internalUserPreferencesService
      .get()
      .pipe(take(1))
      .subscribe(preferences => {
        this.setReplyTo(preferences.defaultSendAsAssignee, true);
      });
  }

  setReplyTo(assignee: InternalUserAssignee, update = true) {
    this.inProgressMessage.replyTo = assignee;
    if (update) {
      this.checkUpdate(update);
    }
  }

  setNotificationRecipient(assignee: InternalUserAssignee) {
    this.inProgressMessage.notificationRecipient = {
      assigneeIdent: assignee.identifier,
      ...assignee,
    };
    this.checkUpdate();
  }

  searchForAssignees(text: string) {
    if (text && text.length > 1) {
      this.assigneesItems$ = this.messagingService.searchTaskAssignees(text);
    }
  }

  searchForNotify(text: string) {
    if (text && text.length > 1) {
      this.notifyItems$ = this.messagingService.searchTaskAssignees(text);
    }
  }

  filterTaskAssignees(term: string, item: any) {
    // return true so that all elasticsearch autocomplete results are displayed
    return true;
  }

  startNotify() {
    this.notified = true;
    this.focusService.setFocus(this.notifyFocusOnKey);
  }

  startAssign() {
    this.assigned = true;
    this.focusService.setFocus(this.assignFocusOnKey);
  }

  private checkUpdate(save: boolean = true) {
    this.checkIfShouldUpdate.emit({ data: this.inProgressMessage, save });
  }
}
