import { Component, Input } from '@angular/core';

import { AnalyticsActions } from '@app/core';
import {
  AnalyticsEvent,
  TrackEventProperties,
} from '@app/core/analytics/shared/analytics.type';
import { CollapseDirective } from '@app/shared';
import { FocusService } from '@app/shared/directives/focus/focus.service';

@Component({
  selector: 'omg-template-insertion-button',
  templateUrl: './template-insertion-button.component.html',
  styleUrls: ['./template-insertion-button.component.scss'],
})
export class TemplateInsertionButtonComponent {
  @Input() actionBarCollapseRef: CollapseDirective;
  @Input() analyticsEventProperties: Partial<TrackEventProperties>;

  readonly focusOnKey = 'templateInsertion-search';

  constructor(
    private focusService: FocusService,
    private analyticsActions: AnalyticsActions,
  ) {}

  startTemplateInsertion() {
    this.actionBarCollapseRef.expand();
    this.focusService.setFocus(this.focusOnKey);
    this.trackTemplateInsertionStart();
  }

  private trackTemplateInsertionStart() {
    this.analyticsActions.trackEvent(
      AnalyticsEvent.TemplateInsertStarted,
      this.analyticsEventProperties,
    );
  }
}
