import { FormControl, FormGroup, Validators } from '@angular/forms';

import {
  bmiField,
  bmiPctField,
  headField,
  headPctField,
  htField,
  htPctField,
  infWtField,
  wtField,
  wtPctField,
} from '@app/modules/growth-charts/growth-charts/growth-charts.type';
import { wholeNumberValidator } from '@app/shared';

import {
  SummaryMeasurementUpdateData,
  SummaryProviderRecommendationUpdateData,
  SummaryUpdateData,
} from './summaries-update.type';
import {
  Summary,
  SummaryMeasurementsByAbbreviation,
  SummaryProviderRecommendation,
} from './summaries.type';

/* istanbul ignore next */
export const mapVitalsToForm = (
  measurements: SummaryMeasurementsByAbbreviation,
): FormGroup =>
  new FormGroup({
    sys: new FormControl(measurements.sys.value, [
      Validators.pattern(/[0-9]{0,3}/),
      Validators.min(0),
      Validators.max(300),
      wholeNumberValidator(),
    ]),
    dia: new FormControl(measurements.dia.value, [
      Validators.pattern(/[0-9]{0,3}/),
      Validators.min(0),
      Validators.max(200),
      wholeNumberValidator(),
    ]),
    HR: new FormControl(measurements.HR.value, [
      Validators.pattern(/[0-9]{0,3}/),
      Validators.min(0),
      Validators.max(300),
      wholeNumberValidator(),
    ]),
    RR: new FormControl(measurements.RR.value, [
      Validators.pattern(/[0-9]{0,3}/),
      Validators.min(0),
      Validators.max(100),
      wholeNumberValidator(),
    ]),
    temp: new FormControl(measurements.temp.value, [
      Validators.pattern(/([0-9]{1,3}(\.[0-9]?)?)?/),
      Validators.min(90),
      Validators.max(108),
    ]),
  });

/* istanbul ignore next */
export const mapMeasurementsToForm = (summary: Summary): FormGroup => {
  const form = new FormGroup({
    vitalsForm: mapVitalsToForm(summary.measurements),
  });

  const weightPercentile = summary.measurements.wt_pct;
  const heightPercentile = summary.measurements.ht_pct;

  if (summary.createdForInfant) {
    form.addControl(
      'infantGrowthForm',
      new FormGroup({
        [infWtField]: new FormControl(summary.measurements.inf_wt.value, [
          Validators.pattern(/([0-9]{0,3}(.[0-9]{0,2})?)?/),
          Validators.min(0),
          Validators.max(500),
        ]),
        [wtPctField]: new FormControl(weightPercentile.value),
        [htField]: new FormControl(summary.measurements.ht.value, [
          Validators.pattern(/([0-9]{0,2}(.[0-9]?)?)?/),
          Validators.min(0),
          Validators.max(99.9),
        ]),
        [htPctField]: new FormControl(heightPercentile.value),
        [headField]: new FormControl(summary.measurements.head.value, [
          Validators.pattern(/([0-9]{0,2}(.[0-9]?)?)?/),
          Validators.min(0),
          Validators.max(99.9),
        ]),
        [headPctField]: new FormControl(summary.measurements.head_pct.value),
      }),
    );
  } else {
    form.addControl(
      'bmiForm',
      new FormGroup({
        [wtField]: new FormControl(summary.measurements.wt.value, [
          Validators.pattern(/([0-9]{1,3}(.[0-9]?)?)?/),
          Validators.min(0),
          Validators.max(999),
        ]),
        [wtPctField]: new FormControl(weightPercentile.value),
        [htField]: new FormControl(summary.measurements.ht.value, [
          Validators.pattern(/([0-9]{1,2}(.[0-9]?)?)?/),
          Validators.min(0),
          Validators.max(99),
        ]),
        [htPctField]: new FormControl(heightPercentile.value),
        [bmiField]: new FormControl(summary.measurements.BMI.value, [
          Validators.pattern(/([0-9]{1,3}(.[0-9]?)?)?/),
          Validators.min(0),
          Validators.max(99),
        ]),
        [bmiPctField]: new FormControl(summary.measurements.bmi_pct.value),
      }),
    );
  }

  return form;
};

const summaryProviderRecommendation = (summary: Summary): any => {
  const hasProviderRecommendation = summary.summaryProviderRecommendation;
  return {
    body: (hasProviderRecommendation && hasProviderRecommendation.body) || '',
    s3Pointers:
      (hasProviderRecommendation && hasProviderRecommendation.s3Pointers) || [],
  };
};

/* istanbul ignore next */
export const mapSummaryToUnsignedForm = (summary: Summary): FormGroup => {
  const recommendation = summaryProviderRecommendation(summary);
  return new FormGroup({
    subject: new FormControl(summary.subject || ''),
    subjective: new FormControl(summary.subjective || ''),
    objective: new FormControl(summary.objective || ''),
    afterVisitGuidance: new FormControl(recommendation.body),
    timeBasedVisit: new FormControl(summary.timeBasedVisit || false),
    summaryMeasurementsForm: mapMeasurementsToForm(summary),
  });
};

/* istanbul ignore next */
export const mapMeasurementsFormToData = (
  form,
  summary: Summary,
): SummaryMeasurementUpdateData[] =>
  Object.keys(form).reduce((accumulator, current) => {
    if (form[current] && typeof form[current] === 'object') {
      accumulator = [
        ...accumulator,
        ...mapMeasurementsFormToData(form[current], summary),
      ];
    } else {
      accumulator.push({
        abbreviation: summary.measurements[current].abbreviation,
        measurement_type_id: summary.measurements[current].measurementTypeId,
        metric: summary.measurements[current].metric,
        value: form[current],
      });
    }

    return accumulator;
  }, []);

export const mapSummaryProviderRecommendationS3PointerToSaveData = attachmentData => {
  return {
    id: attachmentData.id,
    bucket: attachmentData.bucket,
    key: attachmentData.key,
    content_length: attachmentData.contentLength,
    content_type: attachmentData.contentType,
    title: attachmentData.title,
    _destroy: attachmentData.destroy,
  };
};

export const mapAfterVisitGuidanceToSummaryProviderRecommendation = (
  formValues,
  summary: Summary,
): SummaryProviderRecommendationUpdateData => {
  return {
    id:
      summary.summaryProviderRecommendation &&
      summary.summaryProviderRecommendation.id,
    body: formValues.afterVisitGuidance,
  };
};

/* istanbul ignore next */
export const mapUnsignedFormToSummaryUpdate = (
  formValues,
  summary: Summary,
): SummaryUpdateData => {
  const measurements = mapMeasurementsFormToData(
    formValues.summaryMeasurementsForm,
    summary,
  );

  const providerRecommendation = mapAfterVisitGuidanceToSummaryProviderRecommendation(
    formValues,
    summary,
  );
  return {
    subject: formValues.subject || '',
    subjective: formValues.subjective,
    objective: formValues.objective,
    measurements,
    time_based_visit: formValues.timeBasedVisit,
    summary_provider_recommendation_attributes: providerRecommendation,
  };
};

export const mapSummaryToSummaryProviderRecommendationUpdateData = (
  summary: Summary,
  attachments: any[],
): Partial<SummaryUpdateData> => {
  return {
    summary_provider_recommendation_attributes: {
      id: summary.summaryProviderRecommendation.id,
      body: summary.summaryProviderRecommendation.body,
      s3_pointers_attributes: attachments.map(
        mapSummaryProviderRecommendationS3PointerToSaveData,
      ),
    },
  };
};
