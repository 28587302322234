<ng-container>
  <div #cardTitle
       class="card-title"
       omgTheme
       om-layout
       om-layout-align="space-between center">
    <strong class="text"
            [attr.smallFontSize]="smallFontSize"
            *ngIf="heading">{{ heading }}</strong>
    <div om-layout
         om-layout-align="start stretch"
         class="card-title-content">
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
