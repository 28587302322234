import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { newEntityId } from '@app/modules/medications/shared';
import { Prn } from '@app/modules/medications/shared/prn.type';
import { AutoCompleteComponent } from '@app/shared';

@Component({
  selector: 'omg-prn-selector',
  templateUrl: './prn-selector.component.html',
})
export class PrnSelectorComponent implements OnInit, OnDestroy {
  @Input() usePrn: FormControl;
  @Input() prnId: FormControl;
  @Input() prnDescription: FormControl;
  @Input() prns: Prn[];
  @Output() validate = new EventEmitter<void>();
  @Output() changeEvent = new EventEmitter<any>();
  @ViewChild('prnDropdown')
  prnDropdown: AutoCompleteComponent;

  private unsubscribe$ = new Subject<void>();

  constructor() {}

  ngOnInit() {
    this.validate.emit();
    this.usePrn.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(value => !!value),
      )
      .subscribe(this.focusOnSelector);
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onUsePrnClick() {
    this.changeEvent.emit({ type: 'usePrnClick' });
  }

  onPrnIdChange(event) {
    this.changeEvent.emit({ event: event, type: 'prnIdChange' });
  }

  addCustomPrn = (prnDescription: string) => {
    const prnId = newEntityId;
    const customPrn = { id: prnId, desc: prnDescription };
    this.patchFormValue(prnId, prnDescription);
    return customPrn;
  };

  private patchFormValue(prnId, prnDescription) {
    this.prnDescription.patchValue(prnDescription);
    this.prnId.patchValue(prnId);
  }

  private focusOnSelector = () => {
    setTimeout(() => this.prnDropdown.focus());
  };
}
