<omg-section-title data-cy="medical-history">
  <div om-layout
       om-layout-fill
       om-layout-align="space-between center">
    <span>Medical History</span>
    <span class="om-icon clickable fa fa-trash"
          (click)="deleteFromNote()"
          *ngIf="!signed"
          omgStopEvent
          omgTooltip
          tooltipText="Delete from Note"
          tooltipPosition="left"></span>
  </div>
</omg-section-title>

<ul class="padding-normal"
    om-layout="vertical"
    om-layout-gutter>
  <li class="om-animate"
      *ngFor="let problem of activeProblems; trackBy: trackByFn">
    <div>
      <b>{{ problem.problemType.clinicalDescription }}</b>
      <span *ngIf="problem?.problemCodeLocation?.name">, {{ problem.problemCodeLocation.name }}</span>
      <span *ngIf="problem.briefComment"> - {{ problem.briefComment }}</span>
      <span *ngIf="problem.onset || problem.resolution"> (
        <span *ngIf="problem.onset">onset {{ problem.onset }}</span>
        <span *ngIf="problem.onset && problem.resolution">, </span>
        <span *ngIf="problem.resolution">resolved {{ problem.resolution }}</span>
        <span> )</span>
      </span>
      <span *ngIf="problem.code"> - {{ problem.code }}</span>
    </div>
    <p *ngIf="problem.summary">{{ problem.summary }}</p>
  </li>
  <li class="om-animate"
      *ngFor="let problem of resolvedProblems; trackBy: trackByFn">
    <div>
      <b>{{ problem.problemType.clinicalDescription }}</b>
      <span *ngIf="problem?.problemCodeLocation?.name">, {{ problem.problemCodeLocation.name }}</span>
      <span *ngIf="problem.briefComment"> - {{ problem.briefComment }}</span>
      <span *ngIf="problem.onset || problem.resolution"> (
        <span *ngIf="problem.onset">onset {{ problem.onset }}</span>
        <span *ngIf="problem.onset && problem.resolution">, </span>
        <span *ngIf="problem.resolution">resolved {{ problem.resolution }}</span>
        <span> )</span>
      </span>
      <span> - Resolved</span>
      <span *ngIf="problem.code"> - {{ problem.code }}</span>
    </div>
    <p *ngIf="problem.summary">{{ problem.summary }}</p>
  </li>
</ul>
<div *ngIf="noSignificantMedicalHistory"
     class="padding-normal">
  No significant past medical history
</div>
