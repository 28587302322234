import { zScoreFromMeasurement } from '../../../shared/z-score-to-percentile';
import { LMSParamsMap } from '../lms-params.type';

const CM_PER_INCH = 2.54;
// Data comes from this file https://www.cdc.gov/growthcharts/data/zscore/statage.csv
const maleChildHeightLMSParams: LMSParamsMap = {
  24: { lambda: 0.941523967, mu: 86.45220101, sigma: 0.040321528 },
  24.5: { lambda: 1.00720807, mu: 86.86160934, sigma: 0.040395626 },
  25.5: { lambda: 0.837251351, mu: 87.65247282, sigma: 0.040577525 },
  26.5: { lambda: 0.681492975, mu: 88.42326434, sigma: 0.040723122 },
  27.5: { lambda: 0.538779654, mu: 89.17549228, sigma: 0.040833194 },
  28.5: { lambda: 0.407697153, mu: 89.91040853, sigma: 0.040909059 },
  29.5: { lambda: 0.286762453, mu: 90.62907762, sigma: 0.040952433 },
  30.5: { lambda: 0.174489485, mu: 91.33242379, sigma: 0.04096533 },
  31.5: { lambda: 0.069444521, mu: 92.02127167, sigma: 0.040949976 },
  32.5: { lambda: -0.029720564, mu: 92.69637946, sigma: 0.040908737 },
  33.5: { lambda: -0.124251789, mu: 93.35846546, sigma: 0.040844062 },
  34.5: { lambda: -0.215288396, mu: 94.00822923, sigma: 0.040758431 },
  35.5: { lambda: -0.30385434, mu: 94.64636981, sigma: 0.040654312 },
  36.5: { lambda: -0.390918369, mu: 95.27359106, sigma: 0.04053412 },
  37.5: { lambda: -0.254801167, mu: 95.91474929, sigma: 0.040572876 },
  38.5: { lambda: -0.125654535, mu: 96.54734328, sigma: 0.04061691 },
  39.5: { lambda: -0.00316735, mu: 97.17191309, sigma: 0.040666414 },
  40.5: { lambda: 0.11291221, mu: 97.78897727, sigma: 0.040721467 },
  41.5: { lambda: 0.222754969, mu: 98.3990283, sigma: 0.040782045 },
  42.5: { lambda: 0.326530126, mu: 99.00254338, sigma: 0.040848042 },
  43.5: { lambda: 0.42436156, mu: 99.599977, sigma: 0.040919281 },
  44.5: { lambda: 0.516353108, mu: 100.191764, sigma: 0.040995524 },
  45.5: { lambda: 0.602595306, mu: 100.7783198, sigma: 0.041076485 },
  46.5: { lambda: 0.683170764, mu: 101.3600411, sigma: 0.041161838 },
  47.5: { lambda: 0.758158406, mu: 101.9373058, sigma: 0.041251224 },
  48.5: { lambda: 0.827636736, mu: 102.5104735, sigma: 0.041344257 },
  49.5: { lambda: 0.891686306, mu: 103.0798852, sigma: 0.041440534 },
  50.5: { lambda: 0.95039153, mu: 103.645864, sigma: 0.041539635 },
  51.5: { lambda: 1.003830006, mu: 104.208713, sigma: 0.041641136 },
  52.5: { lambda: 1.05213569, mu: 104.7687256, sigma: 0.041744602 },
  53.5: { lambda: 1.0953669, mu: 105.3261638, sigma: 0.041849607 },
  54.5: { lambda: 1.133652119, mu: 105.8812823, sigma: 0.041955723 },
  55.5: { lambda: 1.167104213, mu: 106.4343146, sigma: 0.042062532 },
  56.5: { lambda: 1.195845353, mu: 106.9854769, sigma: 0.042169628 },
  57.5: { lambda: 1.220004233, mu: 107.534968, sigma: 0.042276619 },
  58.5: { lambda: 1.239715856, mu: 108.0829695, sigma: 0.042383129 },
  59.5: { lambda: 1.255121285, mu: 108.6296457, sigma: 0.042488804 },
  60.5: { lambda: 1.266367398, mu: 109.1751441, sigma: 0.042593311 },
  61.5: { lambda: 1.273606657, mu: 109.7195954, sigma: 0.042696342 },
  62.5: { lambda: 1.276996893, mu: 110.2631136, sigma: 0.042797615 },
  63.5: { lambda: 1.276701119, mu: 110.8057967, sigma: 0.042896877 },
  64.5: { lambda: 1.272887366, mu: 111.3477265, sigma: 0.042993904 },
  65.5: { lambda: 1.265728536, mu: 111.8889694, sigma: 0.043088503 },
  66.5: { lambda: 1.255402281, mu: 112.4295761, sigma: 0.043180513 },
  67.5: { lambda: 1.242090871, mu: 112.9695827, sigma: 0.043269806 },
  68.5: { lambda: 1.225981067, mu: 113.5090108, sigma: 0.043356287 },
  69.5: { lambda: 1.207263978, mu: 114.0478678, sigma: 0.043439893 },
  70.5: { lambda: 1.186140222, mu: 114.5861486, sigma: 0.043520597 },
  71.5: { lambda: 1.162796198, mu: 115.1238315, sigma: 0.043598407 },
  72.5: { lambda: 1.137442868, mu: 115.6608862, sigma: 0.043673359 },
  73.5: { lambda: 1.110286487, mu: 116.1972691, sigma: 0.043745523 },
  74.5: { lambda: 1.081536236, mu: 116.732925, sigma: 0.043815003 },
  75.5: { lambda: 1.05140374, mu: 117.2677879, sigma: 0.043881929 },
  76.5: { lambda: 1.020102497, mu: 117.8017819, sigma: 0.043946461 },
  77.5: { lambda: 0.987847213, mu: 118.3348215, sigma: 0.044008785 },
  78.5: { lambda: 0.954853043, mu: 118.8668123, sigma: 0.044069112 },
  79.5: { lambda: 0.921334742, mu: 119.397652, sigma: 0.044127675 },
  80.5: { lambda: 0.887505723, mu: 119.9272309, sigma: 0.044184725 },
  81.5: { lambda: 0.85357703, mu: 120.455433, sigma: 0.044240532 },
  82.5: { lambda: 0.819756239, mu: 120.9821362, sigma: 0.044295379 },
  83.5: { lambda: 0.786246296, mu: 121.5072136, sigma: 0.044349559 },
  84.5: { lambda: 0.753244292, mu: 122.0305342, sigma: 0.044403374 },
  85.5: { lambda: 0.720940222, mu: 122.5519634, sigma: 0.04445713 },
  86.5: { lambda: 0.689515708, mu: 123.0713645, sigma: 0.044511135 },
  87.5: { lambda: 0.659142731, mu: 123.588599, sigma: 0.044565693 },
  88.5: { lambda: 0.629997853, mu: 124.1035312, sigma: 0.044621104 },
  89.5: { lambda: 0.602203984, mu: 124.6160161, sigma: 0.044677662 },
  90.5: { lambda: 0.575908038, mu: 125.1259182, sigma: 0.044735646 },
  91.5: { lambda: 0.55123134, mu: 125.6331012, sigma: 0.044795322 },
  92.5: { lambda: 0.528279901, mu: 126.1374319, sigma: 0.044856941 },
  93.5: { lambda: 0.507143576, mu: 126.6387804, sigma: 0.04492073 },
  94.5: { lambda: 0.487895344, mu: 127.1370217, sigma: 0.044986899 },
  95.5: { lambda: 0.470590753, mu: 127.6320362, sigma: 0.045055632 },
  96.5: { lambda: 0.455267507, mu: 128.1237104, sigma: 0.045127088 },
  97.5: { lambda: 0.441945241, mu: 128.6119383, sigma: 0.045201399 },
  98.5: { lambda: 0.430625458, mu: 129.096622, sigma: 0.045278671 },
  99.5: { lambda: 0.421291648, mu: 129.5776723, sigma: 0.045358979 },
  100.5: { lambda: 0.413909588, mu: 130.0550101, sigma: 0.045442372 },
  101.5: { lambda: 0.408427813, mu: 130.5285669, sigma: 0.045528869 },
  102.5: { lambda: 0.404778262, mu: 130.9982857, sigma: 0.045618459 },
  103.5: { lambda: 0.402877077, mu: 131.4641218, sigma: 0.045711105 },
  104.5: { lambda: 0.402625561, mu: 131.9260439, sigma: 0.045806742 },
  105.5: { lambda: 0.40391127, mu: 132.3840348, sigma: 0.045905281 },
  106.5: { lambda: 0.406609232, mu: 132.838092, sigma: 0.046006604 },
  107.5: { lambda: 0.410583274, mu: 133.2882291, sigma: 0.046110573 },
  108.5: { lambda: 0.415687443, mu: 133.7344759, sigma: 0.046217028 },
  109.5: { lambda: 0.421767514, mu: 134.1768801, sigma: 0.04632579 },
  110.5: { lambda: 0.428662551, mu: 134.6155076, sigma: 0.046436662 },
  111.5: { lambda: 0.436206531, mu: 135.0504433, sigma: 0.04654943 },
  112.5: { lambda: 0.44423, mu: 135.4817925, sigma: 0.046663871 },
  113.5: { lambda: 0.45256176, mu: 135.9096813, sigma: 0.046779748 },
  114.5: { lambda: 0.461030578, mu: 136.3342577, sigma: 0.046896817 },
  115.5: { lambda: 0.469466904, mu: 136.7556923, sigma: 0.047014827 },
  116.5: { lambda: 0.477704608, mu: 137.1741794, sigma: 0.047133525 },
  117.5: { lambda: 0.48558272, mu: 137.5899378, sigma: 0.047252654 },
  118.5: { lambda: 0.492947182, mu: 138.0032114, sigma: 0.047371961 },
  119.5: { lambda: 0.499652617, mu: 138.4142703, sigma: 0.047491194 },
  120.5: { lambda: 0.505564115, mu: 138.8234114, sigma: 0.047610108 },
  121.5: { lambda: 0.510559047, mu: 139.2309592, sigma: 0.047728463 },
  122.5: { lambda: 0.514528903, mu: 139.6372663, sigma: 0.04784603 },
  123.5: { lambda: 0.517381177, mu: 140.042714, sigma: 0.047962592 },
  124.5: { lambda: 0.519041285, mu: 140.4477127, sigma: 0.048077942 },
  125.5: { lambda: 0.519454524, mu: 140.8527022, sigma: 0.048191889 },
  126.5: { lambda: 0.518588072, mu: 141.2581515, sigma: 0.048304259 },
  127.5: { lambda: 0.516433004, mu: 141.6645592, sigma: 0.048414893 },
  128.5: { lambda: 0.513006312, mu: 142.072452, sigma: 0.048523648 },
  129.5: { lambda: 0.508352901, mu: 142.4823852, sigma: 0.048630402 },
  130.5: { lambda: 0.502547502, mu: 142.8949403, sigma: 0.04873505 },
  131.5: { lambda: 0.495696454, mu: 143.3107241, sigma: 0.048837504 },
  132.5: { lambda: 0.487939275, mu: 143.7303663, sigma: 0.048937694 },
  133.5: { lambda: 0.479449924, mu: 144.1545167, sigma: 0.049035564 },
  134.5: { lambda: 0.470437652, mu: 144.5838414, sigma: 0.049131073 },
  135.5: { lambda: 0.461147305, mu: 145.0190192, sigma: 0.049224189 },
  136.5: { lambda: 0.451858946, mu: 145.4607359, sigma: 0.049314887 },
  137.5: { lambda: 0.442886661, mu: 145.9096784, sigma: 0.049403145 },
  138.5: { lambda: 0.434576385, mu: 146.3665278, sigma: 0.049488934 },
  139.5: { lambda: 0.427302633, mu: 146.8319513, sigma: 0.049572216 },
  140.5: { lambda: 0.421464027, mu: 147.3065929, sigma: 0.049652935 },
  141.5: { lambda: 0.417477538, mu: 147.7910635, sigma: 0.049731004 },
  142.5: { lambda: 0.415771438, mu: 148.2859294, sigma: 0.0498063 },
  143.5: { lambda: 0.416777012, mu: 148.7917006, sigma: 0.04987865 },
  144.5: { lambda: 0.420919142, mu: 149.3088178, sigma: 0.049947823 },
  145.5: { lambda: 0.428606007, mu: 149.8376391, sigma: 0.050013518 },
  146.5: { lambda: 0.440218167, mu: 150.3784267, sigma: 0.050075353 },
  147.5: { lambda: 0.456097443, mu: 150.9313331, sigma: 0.050132858 },
  148.5: { lambda: 0.476536014, mu: 151.4963887, sigma: 0.050185471 },
  149.5: { lambda: 0.501766234, mu: 152.0734897, sigma: 0.050232532 },
  150.5: { lambda: 0.531951655, mu: 152.6623878, sigma: 0.050273285 },
  151.5: { lambda: 0.567179725, mu: 153.2626819, sigma: 0.050306885 },
  152.5: { lambda: 0.607456565, mu: 153.8738124, sigma: 0.050332406 },
  153.5: { lambda: 0.652704121, mu: 154.495058, sigma: 0.05034886 },
  154.5: { lambda: 0.702759868, mu: 155.1255365, sigma: 0.050355216 },
  155.5: { lambda: 0.757379106, mu: 155.7642086, sigma: 0.050350423 },
  156.5: { lambda: 0.816239713, mu: 156.4098858, sigma: 0.050333444 },
  157.5: { lambda: 0.878947416, mu: 157.0612415, sigma: 0.050303283 },
  158.5: { lambda: 0.945053486, mu: 157.7168289, sigma: 0.050259018 },
  159.5: { lambda: 1.014046108, mu: 158.3750929, sigma: 0.050199837 },
  160.5: { lambda: 1.085383319, mu: 159.034399, sigma: 0.050125062 },
  161.5: { lambda: 1.158487278, mu: 159.6930501, sigma: 0.05003418 },
  162.5: { lambda: 1.232768816, mu: 160.3493168, sigma: 0.049926861 },
  163.5: { lambda: 1.307628899, mu: 161.0014586, sigma: 0.049802977 },
  164.5: { lambda: 1.382473225, mu: 161.6477515, sigma: 0.04966261 },
  165.5: { lambda: 1.456720479, mu: 162.2865119, sigma: 0.049506051 },
  166.5: { lambda: 1.529810247, mu: 162.9161202, sigma: 0.049333801 },
  167.5: { lambda: 1.601219573, mu: 163.535045, sigma: 0.049146553 },
  168.5: { lambda: 1.670433444, mu: 164.1418486, sigma: 0.04894519 },
  169.5: { lambda: 1.736995571, mu: 164.7352199, sigma: 0.048730749 },
  170.5: { lambda: 1.800483802, mu: 165.3139755, sigma: 0.048504404 },
  171.5: { lambda: 1.860518777, mu: 165.8770715, sigma: 0.048267442 },
  172.5: { lambda: 1.916765525, mu: 166.4236087, sigma: 0.04802123 },
  173.5: { lambda: 1.968934444, mu: 166.9528354, sigma: 0.047767192 },
  174.5: { lambda: 2.016781776, mu: 167.4641466, sigma: 0.047506783 },
  175.5: { lambda: 2.060109658, mu: 167.9570814, sigma: 0.047241456 },
  176.5: { lambda: 2.098765817, mu: 168.4313175, sigma: 0.04697265 },
  177.5: { lambda: 2.132642948, mu: 168.8866644, sigma: 0.046701759 },
  178.5: { lambda: 2.16167779, mu: 169.3230548, sigma: 0.046430122 },
  179.5: { lambda: 2.185849904, mu: 169.7405351, sigma: 0.046159004 },
  180.5: { lambda: 2.205180153, mu: 170.139255, sigma: 0.045889585 },
  181.5: { lambda: 2.219728869, mu: 170.5194567, sigma: 0.045622955 },
  182.5: { lambda: 2.2295937, mu: 170.881464, sigma: 0.045360101 },
  183.5: { lambda: 2.234907144, mu: 171.2256717, sigma: 0.045101913 },
  184.5: { lambda: 2.235833767, mu: 171.5525345, sigma: 0.044849174 },
  185.5: { lambda: 2.232567138, mu: 171.8625576, sigma: 0.044602566 },
  186.5: { lambda: 2.2253265, mu: 172.1562865, sigma: 0.044362674 },
  187.5: { lambda: 2.214353232, mu: 172.4342983, sigma: 0.044129985 },
  188.5: { lambda: 2.199905902, mu: 172.6971935, sigma: 0.043904897 },
  189.5: { lambda: 2.182262864, mu: 172.9455898, sigma: 0.043687723 },
  190.5: { lambda: 2.161704969, mu: 173.180112, sigma: 0.043478698 },
  191.5: { lambda: 2.138524662, mu: 173.4013896, sigma: 0.043277987 },
  192.5: { lambda: 2.113023423, mu: 173.6100518, sigma: 0.043085685 },
  193.5: { lambda: 2.085490286, mu: 173.8067179, sigma: 0.042901835 },
  194.5: { lambda: 2.0562195, mu: 173.9919998, sigma: 0.042726424 },
  195.5: { lambda: 2.025496648, mu: 174.1664951, sigma: 0.042559396 },
  196.5: { lambda: 1.993598182, mu: 174.3307855, sigma: 0.042400652 },
  197.5: { lambda: 1.960789092, mu: 174.4854344, sigma: 0.042250063 },
  198.5: { lambda: 1.927320937, mu: 174.6309856, sigma: 0.042107465 },
  199.5: { lambda: 1.89343024, mu: 174.7679617, sigma: 0.041972676 },
  200.5: { lambda: 1.859337259, mu: 174.8968634, sigma: 0.041845488 },
  201.5: { lambda: 1.825245107, mu: 175.0181691, sigma: 0.041725679 },
  202.5: { lambda: 1.791339209, mu: 175.1323345, sigma: 0.041613015 },
  203.5: { lambda: 1.757787065, mu: 175.2397926, sigma: 0.041507249 },
  204.5: { lambda: 1.724738292, mu: 175.340954, sigma: 0.041408129 },
  205.5: { lambda: 1.692324905, mu: 175.4362071, sigma: 0.041315398 },
  206.5: { lambda: 1.660661815, mu: 175.5259191, sigma: 0.041228796 },
  207.5: { lambda: 1.629847495, mu: 175.6104358, sigma: 0.04114806 },
  208.5: { lambda: 1.599964788, mu: 175.690083, sigma: 0.041072931 },
  209.5: { lambda: 1.571081817, mu: 175.7651671, sigma: 0.04100315 },
  210.5: { lambda: 1.543252982, mu: 175.8359757, sigma: 0.040938463 },
  211.5: { lambda: 1.516519998, mu: 175.9027788, sigma: 0.040878617 },
  212.5: { lambda: 1.490912963, mu: 175.9658293, sigma: 0.040823368 },
  213.5: { lambda: 1.466451429, mu: 176.0253641, sigma: 0.040772475 },
  214.5: { lambda: 1.44314546, mu: 176.081605, sigma: 0.040725706 },
  215.5: { lambda: 1.420996665, mu: 176.1347593, sigma: 0.040682834 },
  216.5: { lambda: 1.399999187, mu: 176.1850208, sigma: 0.04064364 },
  217.5: { lambda: 1.380140651, mu: 176.2325707, sigma: 0.040607913 },
  218.5: { lambda: 1.361403047, mu: 176.2775781, sigma: 0.040575448 },
  219.5: { lambda: 1.343763564, mu: 176.3202008, sigma: 0.040546051 },
  220.5: { lambda: 1.327195355, mu: 176.3605864, sigma: 0.040519532 },
  221.5: { lambda: 1.311668242, mu: 176.3988725, sigma: 0.040495713 },
  222.5: { lambda: 1.297149359, mu: 176.4351874, sigma: 0.040474421 },
  223.5: { lambda: 1.283603728, mu: 176.469651, sigma: 0.040455493 },
  224.5: { lambda: 1.270994782, mu: 176.5023751, sigma: 0.040438773 },
  225.5: { lambda: 1.25928483, mu: 176.533464, sigma: 0.040424111 },
  226.5: { lambda: 1.248435461, mu: 176.5630153, sigma: 0.040411366 },
  227.5: { lambda: 1.23840791, mu: 176.5911197, sigma: 0.040400405 },
  228.5: { lambda: 1.229163362, mu: 176.6178621, sigma: 0.040391101 },
  229.5: { lambda: 1.220663228, mu: 176.6433219, sigma: 0.040383334 },
  230.5: { lambda: 1.212869374, mu: 176.6675729, sigma: 0.04037699 },
  231.5: { lambda: 1.20574431, mu: 176.6906844, sigma: 0.040371962 },
  232.5: { lambda: 1.199251356, mu: 176.712721, sigma: 0.040368149 },
  233.5: { lambda: 1.19335477, mu: 176.733743, sigma: 0.040365456 },
  234.5: { lambda: 1.188019859, mu: 176.753807, sigma: 0.040363795 },
  235.5: { lambda: 1.183213059, mu: 176.7729657, sigma: 0.04036308 },
  236.5: { lambda: 1.178901998, mu: 176.7912687, sigma: 0.040363233 },
  237.5: { lambda: 1.175055543, mu: 176.8087622, sigma: 0.040364179 },
  238.5: { lambda: 1.171643828, mu: 176.8254895, sigma: 0.04036585 },
  239.5: { lambda: 1.16863827, mu: 176.8414914, sigma: 0.04036818 },
  240: { lambda: 1.167279219, mu: 176.8492322, sigma: 0.040369574 },
};

const femaleChildHeightLMSParams: LMSParamsMap = {
  24: { lambda: 1.07244896, mu: 84.97555512, sigma: 0.040791394 },
  24.5: { lambda: 1.051272912, mu: 85.3973169, sigma: 0.040859727 },
  25.5: { lambda: 1.041951175, mu: 86.29026318, sigma: 0.041142161 },
  26.5: { lambda: 1.012592236, mu: 87.15714182, sigma: 0.041349399 },
  27.5: { lambda: 0.970541909, mu: 87.9960184, sigma: 0.041500428 },
  28.5: { lambda: 0.921129988, mu: 88.8055115, sigma: 0.041610508 },
  29.5: { lambda: 0.868221392, mu: 89.58476689, sigma: 0.041691761 },
  30.5: { lambda: 0.81454413, mu: 90.33341722, sigma: 0.04175368 },
  31.5: { lambda: 0.761957977, mu: 91.0515436, sigma: 0.041803562 },
  32.5: { lambda: 0.711660228, mu: 91.7396352, sigma: 0.041846882 },
  33.5: { lambda: 0.664323379, mu: 92.39854429, sigma: 0.041887626 },
  34.5: { lambda: 0.620285102, mu: 93.02945392, sigma: 0.041928568 },
  35.5: { lambda: 0.57955631, mu: 93.63382278, sigma: 0.041971514 },
  36.5: { lambda: 0.54198094, mu: 94.21335709, sigma: 0.042017509 },
  37.5: { lambda: 0.511429832, mu: 94.79643239, sigma: 0.042104522 },
  38.5: { lambda: 0.482799937, mu: 95.37391918, sigma: 0.042199507 },
  39.5: { lambda: 0.455521041, mu: 95.94692677, sigma: 0.042300333 },
  40.5: { lambda: 0.429150288, mu: 96.51644912, sigma: 0.042405225 },
  41.5: { lambda: 0.403351725, mu: 97.08337211, sigma: 0.042512706 },
  42.5: { lambda: 0.377878239, mu: 97.6484807, sigma: 0.042621565 },
  43.5: { lambda: 0.352555862, mu: 98.21246579, sigma: 0.042730809 },
  44.5: { lambda: 0.327270297, mu: 98.77593069, sigma: 0.042839638 },
  45.5: { lambda: 0.301955463, mu: 99.33939735, sigma: 0.042947412 },
  46.5: { lambda: 0.276583851, mu: 99.9033122, sigma: 0.043053626 },
  47.5: { lambda: 0.251158446, mu: 100.4680516, sigma: 0.043157889 },
  48.5: { lambda: 0.225705996, mu: 101.033927, sigma: 0.043259907 },
  49.5: { lambda: 0.20027145, mu: 101.6011898, sigma: 0.043359463 },
  50.5: { lambda: 0.174913356, mu: 102.1700358, sigma: 0.043456406 },
  51.5: { lambda: 0.149700081, mu: 102.7406094, sigma: 0.043550638 },
  52.5: { lambda: 0.12470671, mu: 103.3130077, sigma: 0.043642107 },
  53.5: { lambda: 0.100012514, mu: 103.8872839, sigma: 0.043730791 },
  54.5: { lambda: 0.075698881, mu: 104.4634511, sigma: 0.043816701 },
  55.5: { lambda: 0.051847635, mu: 105.0414853, sigma: 0.043899867 },
  56.5: { lambda: 0.02853967, mu: 105.6213287, sigma: 0.043980337 },
  57.5: { lambda: 0.005853853, mu: 106.2028921, sigma: 0.044058171 },
  58.5: { lambda: -0.016133871, mu: 106.7860583, sigma: 0.04413344 },
  59.5: { lambda: -0.037351181, mu: 107.3706841, sigma: 0.044206218 },
  60.5: { lambda: -0.057729947, mu: 107.9566031, sigma: 0.044276588 },
  61.5: { lambda: -0.077206672, mu: 108.5436278, sigma: 0.044344632 },
  62.5: { lambda: -0.09572283, mu: 109.1315521, sigma: 0.044410436 },
  63.5: { lambda: -0.113225128, mu: 109.7201531, sigma: 0.044474084 },
  64.5: { lambda: -0.129665689, mu: 110.3091934, sigma: 0.044535662 },
  65.5: { lambda: -0.145002179, mu: 110.8984228, sigma: 0.044595254 },
  66.5: { lambda: -0.159197885, mu: 111.4875806, sigma: 0.044652942 },
  67.5: { lambda: -0.172221748, mu: 112.0763967, sigma: 0.044708809 },
  68.5: { lambda: -0.184048358, mu: 112.6645943, sigma: 0.044762936 },
  69.5: { lambda: -0.194660215, mu: 113.2518902, sigma: 0.044815402 },
  70.5: { lambda: -0.204030559, mu: 113.8380006, sigma: 0.044866288 },
  71.5: { lambda: -0.212174408, mu: 114.4226317, sigma: 0.044915672 },
  72.5: { lambda: -0.219069129, mu: 115.0054978, sigma: 0.044963636 },
  73.5: { lambda: -0.224722166, mu: 115.5863089, sigma: 0.045010259 },
  74.5: { lambda: -0.229140412, mu: 116.1647782, sigma: 0.045055624 },
  75.5: { lambda: -0.232335686, mu: 116.7406221, sigma: 0.045099817 },
  76.5: { lambda: -0.234324563, mu: 117.3135622, sigma: 0.045142924 },
  77.5: { lambda: -0.235128195, mu: 117.8833259, sigma: 0.045185036 },
  78.5: { lambda: -0.234772114, mu: 118.4496481, sigma: 0.045226249 },
  79.5: { lambda: -0.233286033, mu: 119.0122722, sigma: 0.045266662 },
  80.5: { lambda: -0.230703633, mu: 119.5709513, sigma: 0.045306383 },
  81.5: { lambda: -0.227062344, mu: 120.1254495, sigma: 0.045345524 },
  82.5: { lambda: -0.222403111, mu: 120.6755427, sigma: 0.045384203 },
  83.5: { lambda: -0.216770161, mu: 121.22102, sigma: 0.045422551 },
  84.5: { lambda: -0.210210748, mu: 121.7616844, sigma: 0.045460702 },
  85.5: { lambda: -0.202774891, mu: 122.2973542, sigma: 0.045498803 },
  86.5: { lambda: -0.194515104, mu: 122.827864, sigma: 0.045537012 },
  87.5: { lambda: -0.185486099, mu: 123.3530652, sigma: 0.045575495 },
  88.5: { lambda: -0.175744476, mu: 123.8728276, sigma: 0.045614432 },
  89.5: { lambda: -0.165348396, mu: 124.38704, sigma: 0.045654016 },
  90.5: { lambda: -0.15435722, mu: 124.8956114, sigma: 0.04569445 },
  91.5: { lambda: -0.142831123, mu: 125.398472, sigma: 0.045735953 },
  92.5: { lambda: -0.130830669, mu: 125.895574, sigma: 0.045778759 },
  93.5: { lambda: -0.118416354, mu: 126.3868929, sigma: 0.045823114 },
  94.5: { lambda: -0.105648092, mu: 126.8724284, sigma: 0.04586928 },
  95.5: { lambda: -0.092584657, mu: 127.3522056, sigma: 0.045917535 },
  96.5: { lambda: -0.079283065, mu: 127.8262759, sigma: 0.045968169 },
  97.5: { lambda: -0.065797888, mu: 128.2947187, sigma: 0.04602149 },
  98.5: { lambda: -0.0521805, mu: 128.757642, sigma: 0.046077818 },
  99.5: { lambda: -0.03847825, mu: 129.2151839, sigma: 0.046137487 },
  100.5: { lambda: -0.024733545, mu: 129.6675143, sigma: 0.046200842 },
  101.5: { lambda: -0.010982868, mu: 130.1148354, sigma: 0.04626824 },
  102.5: { lambda: 0.002744306, mu: 130.5573839, sigma: 0.046340046 },
  103.5: { lambda: 0.016426655, mu: 130.995432, sigma: 0.046416629 },
  104.5: { lambda: 0.030052231, mu: 131.4292887, sigma: 0.046498361 },
  105.5: { lambda: 0.043619747, mu: 131.8593015, sigma: 0.046585611 },
  106.5: { lambda: 0.05713988, mu: 132.2858574, sigma: 0.046678741 },
  107.5: { lambda: 0.070636605, mu: 132.7093845, sigma: 0.046778099 },
  108.5: { lambda: 0.08414848, mu: 133.1303527, sigma: 0.04688401 },
  109.5: { lambda: 0.097729873, mu: 133.5492749, sigma: 0.046996769 },
  110.5: { lambda: 0.111452039, mu: 133.9667073, sigma: 0.047116633 },
  111.5: { lambda: 0.125404005, mu: 134.3832499, sigma: 0.047243801 },
  112.5: { lambda: 0.13969316, mu: 134.7995463, sigma: 0.047378413 },
  113.5: { lambda: 0.154445482, mu: 135.2162826, sigma: 0.047520521 },
  114.5: { lambda: 0.169805275, mu: 135.634186, sigma: 0.047670085 },
  115.5: { lambda: 0.185934346, mu: 136.0540223, sigma: 0.047826946 },
  116.5: { lambda: 0.203010488, mu: 136.4765925, sigma: 0.04799081 },
  117.5: { lambda: 0.2212252, mu: 136.9027281, sigma: 0.048161228 },
  118.5: { lambda: 0.240780542, mu: 137.3332846, sigma: 0.04833757 },
  119.5: { lambda: 0.261885086, mu: 137.7691339, sigma: 0.048519011 },
  120.5: { lambda: 0.284748919, mu: 138.2111552, sigma: 0.048704503 },
  121.5: { lambda: 0.309577733, mu: 138.6602228, sigma: 0.048892759 },
  122.5: { lambda: 0.336566048, mu: 139.1171933, sigma: 0.049082239 },
  123.5: { lambda: 0.365889711, mu: 139.5828898, sigma: 0.049271137 },
  124.5: { lambda: 0.397699038, mu: 140.0580848, sigma: 0.049457371 },
  125.5: { lambda: 0.432104409, mu: 140.5434787, sigma: 0.049638596 },
  126.5: { lambda: 0.46917993, mu: 141.0396832, sigma: 0.049812203 },
  127.5: { lambda: 0.508943272, mu: 141.5471945, sigma: 0.049975355 },
  128.5: { lambda: 0.551354277, mu: 142.0663731, sigma: 0.050125012 },
  129.5: { lambda: 0.596307363, mu: 142.59742, sigma: 0.050257992 },
  130.5: { lambda: 0.643626542, mu: 143.1403553, sigma: 0.050371024 },
  131.5: { lambda: 0.693062173, mu: 143.6949981, sigma: 0.050460835 },
  132.5: { lambda: 0.744289752, mu: 144.2609497, sigma: 0.050524236 },
  133.5: { lambda: 0.79691098, mu: 144.8375809, sigma: 0.050558224 },
  134.5: { lambda: 0.85045728, mu: 145.4240246, sigma: 0.050560083 },
  135.5: { lambda: 0.904395871, mu: 146.0191748, sigma: 0.050527494 },
  136.5: { lambda: 0.958138449, mu: 146.621692, sigma: 0.050458634 },
  137.5: { lambda: 1.011054559, mu: 147.2300177, sigma: 0.050352269 },
  138.5: { lambda: 1.062474568, mu: 147.8423918, sigma: 0.050207825 },
  139.5: { lambda: 1.111727029, mu: 148.4568879, sigma: 0.050025434 },
  140.5: { lambda: 1.158135105, mu: 149.0714413, sigma: 0.049805967 },
  141.5: { lambda: 1.201050821, mu: 149.6838943, sigma: 0.049551023 },
  142.5: { lambda: 1.239852328, mu: 150.2920328, sigma: 0.049262895 },
  143.5: { lambda: 1.274006058, mu: 150.8936469, sigma: 0.048944504 },
  144.5: { lambda: 1.303044695, mu: 151.4865636, sigma: 0.048599314 },
  145.5: { lambda: 1.326605954, mu: 152.0686985, sigma: 0.048231224 },
  146.5: { lambda: 1.344443447, mu: 152.6380955, sigma: 0.047844442 },
  147.5: { lambda: 1.356437773, mu: 153.1929631, sigma: 0.047443362 },
  148.5: { lambda: 1.362602695, mu: 153.7317031, sigma: 0.04703243 },
  149.5: { lambda: 1.363085725, mu: 154.2529332, sigma: 0.046616026 },
  150.5: { lambda: 1.358162799, mu: 154.755501, sigma: 0.046198356 },
  151.5: { lambda: 1.348227142, mu: 155.2384904, sigma: 0.04578335 },
  152.5: { lambda: 1.333772923, mu: 155.7012216, sigma: 0.045374597 },
  153.5: { lambda: 1.315374704, mu: 156.1432438, sigma: 0.044975281 },
  154.5: { lambda: 1.293664024, mu: 156.564323, sigma: 0.044588148 },
  155.5: { lambda: 1.269304678, mu: 156.9644258, sigma: 0.044215488 },
  156.5: { lambda: 1.242968236, mu: 157.3436995, sigma: 0.043859135 },
  157.5: { lambda: 1.21531127, mu: 157.7024507, sigma: 0.04352048 },
  158.5: { lambda: 1.186955477, mu: 158.0411233, sigma: 0.043200497 },
  159.5: { lambda: 1.158471522, mu: 158.3602756, sigma: 0.042899776 },
  160.5: { lambda: 1.130367088, mu: 158.6605588, sigma: 0.042618565 },
  161.5: { lambda: 1.103079209, mu: 158.9426964, sigma: 0.042356812 },
  162.5: { lambda: 1.076970655, mu: 159.2074654, sigma: 0.042114211 },
  163.5: { lambda: 1.052329922, mu: 159.455679, sigma: 0.041890247 },
  164.5: { lambda: 1.029374161, mu: 159.688172, sigma: 0.04168424 },
  165.5: { lambda: 1.008254396, mu: 159.9057871, sigma: 0.041495379 },
  166.5: { lambda: 0.989062282, mu: 160.1093647, sigma: 0.041322765 },
  167.5: { lambda: 0.971837799, mu: 160.299733, sigma: 0.041165437 },
  168.5: { lambda: 0.95657215, mu: 160.4776996, sigma: 0.041022401 },
  169.5: { lambda: 0.94324228, mu: 160.6440526, sigma: 0.040892651 },
  170.5: { lambda: 0.931767062, mu: 160.7995428, sigma: 0.040775193 },
  171.5: { lambda: 0.922058291, mu: 160.9448916, sigma: 0.040669052 },
  172.5: { lambda: 0.914012643, mu: 161.0807857, sigma: 0.040573288 },
  173.5: { lambda: 0.907516917, mu: 161.2078755, sigma: 0.040487005 },
  174.5: { lambda: 0.902452436, mu: 161.3267744, sigma: 0.040409354 },
  175.5: { lambda: 0.898698641, mu: 161.4380593, sigma: 0.040339537 },
  176.5: { lambda: 0.896143482, mu: 161.5422726, sigma: 0.040276811 },
  177.5: { lambda: 0.894659668, mu: 161.639917, sigma: 0.040220488 },
  178.5: { lambda: 0.89413892, mu: 161.7314645, sigma: 0.040169932 },
  179.5: { lambda: 0.894475371, mu: 161.8173534, sigma: 0.040124562 },
  180.5: { lambda: 0.895569834, mu: 161.8979913, sigma: 0.040083845 },
  181.5: { lambda: 0.897330209, mu: 161.9737558, sigma: 0.040047295 },
  182.5: { lambda: 0.899671635, mu: 162.0449969, sigma: 0.040014473 },
  183.5: { lambda: 0.902516442, mu: 162.1120386, sigma: 0.03998498 },
  184.5: { lambda: 0.905793969, mu: 162.17518, sigma: 0.039958458 },
  185.5: { lambda: 0.909440266, mu: 162.2346979, sigma: 0.039934584 },
  186.5: { lambda: 0.913397733, mu: 162.2908474, sigma: 0.039913066 },
  187.5: { lambda: 0.91761471, mu: 162.343864, sigma: 0.039893644 },
  188.5: { lambda: 0.922045055, mu: 162.3939652, sigma: 0.039876087 },
  189.5: { lambda: 0.926647697, mu: 162.4413513, sigma: 0.039860185 },
  190.5: { lambda: 0.931386217, mu: 162.4862071, sigma: 0.039845754 },
  191.5: { lambda: 0.93622842, mu: 162.5287029, sigma: 0.039832629 },
  192.5: { lambda: 0.941145943, mu: 162.5689958, sigma: 0.039820663 },
  193.5: { lambda: 0.94611388, mu: 162.6072309, sigma: 0.039809725 },
  194.5: { lambda: 0.95111043, mu: 162.6435418, sigma: 0.0397997 },
  195.5: { lambda: 0.956116576, mu: 162.6780519, sigma: 0.039790485 },
  196.5: { lambda: 0.961115792, mu: 162.7108751, sigma: 0.039781991 },
  197.5: { lambda: 0.966093766, mu: 162.7421168, sigma: 0.039774136 },
  198.5: { lambda: 0.971038162, mu: 162.7718741, sigma: 0.03976685 },
  199.5: { lambda: 0.975938391, mu: 162.8002371, sigma: 0.03976007 },
  200.5: { lambda: 0.980785418, mu: 162.8272889, sigma: 0.039753741 },
  201.5: { lambda: 0.985571579, mu: 162.8531067, sigma: 0.039747815 },
  202.5: { lambda: 0.99029042, mu: 162.8777619, sigma: 0.039742249 },
  203.5: { lambda: 0.994936555, mu: 162.9013208, sigma: 0.039737004 },
  204.5: { lambda: 0.999505539, mu: 162.9238449, sigma: 0.039732048 },
  205.5: { lambda: 1.003993753, mu: 162.9453912, sigma: 0.039727352 },
  206.5: { lambda: 1.0083983, mu: 162.9660131, sigma: 0.03972289 },
  207.5: { lambda: 1.012716921, mu: 162.9857599, sigma: 0.03971864 },
  208.5: { lambda: 1.016947912, mu: 163.0046776, sigma: 0.039714581 },
  209.5: { lambda: 1.021090055, mu: 163.0228094, sigma: 0.039710697 },
  210.5: { lambda: 1.025142554, mu: 163.0401953, sigma: 0.039706971 },
  211.5: { lambda: 1.029104983, mu: 163.0568727, sigma: 0.039703391 },
  212.5: { lambda: 1.032977233, mu: 163.0728768, sigma: 0.039699945 },
  213.5: { lambda: 1.036759475, mu: 163.0882404, sigma: 0.039696623 },
  214.5: { lambda: 1.040452117, mu: 163.1029943, sigma: 0.039693415 },
  215.5: { lambda: 1.044055774, mu: 163.1171673, sigma: 0.039690313 },
  216.5: { lambda: 1.047571238, mu: 163.1307866, sigma: 0.039687311 },
  217.5: { lambda: 1.050999451, mu: 163.1438776, sigma: 0.039684402 },
  218.5: { lambda: 1.054341482, mu: 163.1564644, sigma: 0.039681581 },
  219.5: { lambda: 1.057598512, mu: 163.1685697, sigma: 0.039678842 },
  220.5: { lambda: 1.060771808, mu: 163.1802146, sigma: 0.039676182 },
  221.5: { lambda: 1.063862715, mu: 163.1914194, sigma: 0.039673596 },
  222.5: { lambda: 1.066872639, mu: 163.202203, sigma: 0.039671082 },
  223.5: { lambda: 1.069803036, mu: 163.2125835, sigma: 0.039668635 },
  224.5: { lambda: 1.072655401, mu: 163.2225779, sigma: 0.039666254 },
  225.5: { lambda: 1.075431258, mu: 163.2322024, sigma: 0.039663936 },
  226.5: { lambda: 1.078132156, mu: 163.2414722, sigma: 0.039661679 },
  227.5: { lambda: 1.080759655, mu: 163.2504019, sigma: 0.039659481 },
  228.5: { lambda: 1.083315329, mu: 163.2590052, sigma: 0.039657339 },
  229.5: { lambda: 1.085800751, mu: 163.2672954, sigma: 0.039655252 },
  230.5: { lambda: 1.088217496, mu: 163.2752848, sigma: 0.039653218 },
  231.5: { lambda: 1.090567133, mu: 163.2829854, sigma: 0.039651237 },
  232.5: { lambda: 1.092851222, mu: 163.2904086, sigma: 0.039649306 },
  233.5: { lambda: 1.095071313, mu: 163.297565, sigma: 0.039647424 },
  234.5: { lambda: 1.097228939, mu: 163.304465, sigma: 0.039645591 },
  235.5: { lambda: 1.099325619, mu: 163.3111185, sigma: 0.039643804 },
  236.5: { lambda: 1.101362852, mu: 163.3175349, sigma: 0.039642063 },
  237.5: { lambda: 1.103342119, mu: 163.3237231, sigma: 0.039640367 },
  238.5: { lambda: 1.105264876, mu: 163.3296918, sigma: 0.039638715 },
  239.5: { lambda: 1.107132561, mu: 163.3354491, sigma: 0.039637105 },
  240: { lambda: 1.108046193, mu: 163.338251, sigma: 0.039636316 },
};

export const getChildHeightPercentile = (
  gender: string,
  ageInMonths: number,
  height: number,
) => {
  const heightInCm = height * CM_PER_INCH;
  const childHeightLMSDataTable =
    gender === 'Female' ? femaleChildHeightLMSParams : maleChildHeightLMSParams;

  return zScoreFromMeasurement(
    heightInCm,
    ageInMonths,
    childHeightLMSDataTable,
  );
};
